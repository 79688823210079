@import "../../../styles/_themes.scss";

.guest-item-contact {
  display: flex;
  padding: 6px 10px;
  .Avatar {
    width: 36px;
    height: 36px;
    margin-right: 5px;
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .stronger {
      font-weight: 600;
    }
    .email {
      @include themed() {
        font-size: t(secondary-font-size);
        color: t(color-font-inactive);
      }
    }
  }
}
.selector-options {
  .guest-item-contact {
    &.selected,
    &:hover {
      @include themed() {
        background-color: t(color-outgoing-message-background);
      }
      cursor: pointer;
    }
  }
}
