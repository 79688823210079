@import "../../../styles/themes";
@import "../../../styles/screens";
$min-view : 1024px;
.section.company-premium-user{
  .MuiDialogTitle-root.title-with-close:not(.loader),
  .preview-explanation{
    display: none;
  }
  .proposal-page{
    pointer-events: none!important;
    .proposal-info-panel .inner{
      @include themed(){
        margin-right: 0;
      }
    }
    #slots-container{
      .header{
        position: initial;
        padding: 0;
        flex-direction: column;
      }
    }
  }
  .section-company-content{
    display: flex;
    @media (max-width: $min-view) {
      flex-direction:column-reverse;
    }
    @include smScreenAndSmaller {

    }
  }
  .carroussel{
    padding: 28px;
    border: 1px solid #DADADA;
    border-radius: 4px;
    margin-right: 22px;
    width: 60%;
    box-sizing: border-box;
    *{
      box-sizing: initial;
    }
    @media (max-width: $min-view) {
        max-width: unset;
        width: 100%;
      }
    @include smScreenAndSmaller(){
      width: 100%;
      min-width: unset;
    }
    .carroussel-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 28px;
      .carroussel-title{
          font-weight: 600;
          font-size: 18px;
      }
    }
    .carroussel-content{
      display: flex;
      // max-height: 600px;
      //width: calc(100% - 56px);
      // width: 60%;
      overflow-y: auto;
      @media (max-width: $min-view) {
        max-width: unset;
        width: 100%;
      }
      // scale down components for proposal page?
      .proposal-page{
        //display: inline;
        //min-width: 0;
        font-size: 10px;
        min-height: 0;
        height: min-content;
        .proposal-info-panel {
          height: unset;
          padding-top: 10px;
          .company_logo{
            width: 100px;
          }
          .inner{
            align-self: auto!important;
            padding:0;
            .ProposalAttendees .attendee .org{
              align-self: inherit;
            }
          }
          
        }
        .proposal-action-panel{
          width: 0;
          padding: 30px 20px 20px 30px;
          .header{  
            h1{
              @include themed(){font-size: 18px;}
            }
            p {
              @include themed(){font-size: 10px;}}
          }
          label.slot{
            min-width: 100px;
            .label{
              font-size: 10px!important;
            }
            
          }
          .done-button.MuiButton-root{
            font-size:10px;
          }
        }
            
      }
      .lm-main{
        pointer-events: none!important;
        //width: 700px!important;
        @media (max-width: $min-view) {
          max-width: unset!important;
        }
      }
    }
  }
  .dropper{
   
    box-sizing: initial;
    *{
      box-sizing: initial;
    }
  }
  .drop-zone{
    width: 250px;
    @media (max-width: $min-view) {
        width: 100%;
        box-sizing: border-box;
      }
    min-height: 250px;
    border: 1px solid #DADADA;
    border-radius: 4px;
    padding: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    .annuler{
      display: block;
      width: 100%;
      text-align: right;
      padding: 5px 0;
      cursor: pointer;
      @include themed(){
        color: t("lm-color-pop");
        text-decoration: underline;
      }
    }
    .drop-content{
      background: #F5F5F5;
      border: 1px dashed #DADADA;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      min-width: 250px;
      min-height: 250px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      input{
        display: none;
      }
      .drop-img{
        background-color: #7060DF;
        border-radius: 50%;
        padding: 5px;
        font-size: 2.5rem;
        color: white;
        margin: 0 auto 10px auto;
        width: 48px;
        height: 48px;
      }
      .upload-btn{
        color: #3D2DAA;
        text-decoration: underline;
        cursor: pointer;
      }
      #cnv{
        max-width: 150px;
        max-height: 150px;
        margin: 14px 0;

      }
      .done-button.MuiButton-root.form{
        min-width: unset;
        padding: 2px 12px;
        .MuiCircularProgress-root{
          width: 14px!important;
          height: 14px!important;
          margin-right: 5px;
          svg{
          color:white;
        }
        }
        
      }
    }
    .logo-url{
      max-width: 150px;
      max-height: 150px;
      margin: 14px 0;
    }
  }
  .actions{
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    button{
      @include themed(){
        margin: 22px 0 0 0;
        padding-top: 15px;
        padding-bottom: 15px;
        &.grey.MuiButton-text,
        &.grey.MuiButton-text:hover{
          border: none;
          border-top: 1px solid #DADADA;
          border-bottom: 1px solid #DADADA;
          border-radius: 0;
          
          color: t("lm-color-pop");
        }
        &.grey.MuiButton-text:hover{
          text-decoration: underline;
        }
      }
      label{
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-size: 14px;
      }
    }
    #file-select{
      display: none;
    }
  }
}