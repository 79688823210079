@import "./styles/_themes.scss";

.SimpleSwitch {
  @include themed() {
    display: flex;
    align-items: center;
    border: 1px solid #7360df;
    border-radius: 50px;
    padding: 4px 12px 4px 8px;
    font-size: t(lm-font-size-small);
    color: t(lm-color-pop);
    margin-left: 8px;
    white-space: nowrap;
    cursor: pointer;
    span.label {
      text-decoration: none;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
