@import "../../styles/_themes.scss";
@import "../../styles/_screens.scss";

.dashboard-v3-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  margin-bottom: 7px;
  .dashboard-v3-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .header-title {
      display: flex;
      align-items: center;
      @include smScreenAndSmaller() {
       margin-right: 12px;
      }
      .header-title-label {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #3d2daa;
        // text-transform: capitalize;
        text-align: left;
      }
      .LMTooltip-children {
        margin-left: 12px;
        svg {
          @include themed() {
            fill: t(lm-color-pop);
            width: 20px;
            height: 20px;
          }
        }
        @include smScreenAndSmaller() {
         display: none;
        }
      }
      @include smScreenAndSmaller() {
        .header-title-label {
          font-size: 24px;
        }
      }
      .header-icon {
        background-color: #7060df;
        border-radius: 50%;
        padding: 3px;
        width: 34px;
        height: 34px;
        box-sizing: border-box;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: white;
          width: 24px;
          height: 24px;
        }
      }
      @include smScreenAndSmaller() {
        .header-icon {
          display: none;
        }
      }
    }
    .done-button {
      margin: 0;
      padding: 0;
      .header-title-cta {
        display: flex;
        align-items: center;
        margin: 12px 24px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
      @include smScreenAndSmaller() {
        min-width: unset;
        .header-title-cta {
          margin: 5px 14px;
          svg {
            display: none;
          }
        }
      }
    }
  }
  .dashboard-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-bottom: 1px solid #dadada;
    .empty-msg {
      margin-bottom: 40px;
      font-style: normal;
      font-size: 16px;
      line-height: 1.4em;
      color: #454545;
      text-align: left;
      ul {
        padding-inline-start: 20px;
      }
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
  }
  &.no-border {
    .dashboard-content {
      border: none;
    }
  }
}
