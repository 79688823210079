@import "../../../../styles/themes";

@mixin timelineitem {
  @include themed() {
    padding-left: t("lm-space-medium");
    padding-bottom: t("lm-space-medium");
  }
}
@mixin with-icon($icon) {
  padding-left: 20px;
  position: relative;
  left: 10px;
  &::before {
    font-family: "Material Icons";
    position: absolute;
    left: -14px;
    font-size: 15px;
    line-height: 15px;
    top: -2px;
    content: " ";
    background-image: url($icon);
    // border-radius: 50%;
    @include themed() {
      background-color: t("app-background-color");
    }
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 100;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.active {
    &::before {
      left: -15px;
      top: -3px;
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
    }
  }
}
@mixin with-material-icon($icon) {
  padding-left: 20px;
  position: relative;
  left: 10px;
  &::before {
    font-family: "Material Icons";
    position: absolute;
    left: 0px;
    font-size: 15px;
    line-height: 15px;
    content: $icon;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@mixin with-colored-icon($color) {
  &::before {
    @include themed() {
      color: t($color);
    }
  }
}
@mixin with-line($style) {
  &::after {
    content: "";
    position: absolute;
    left: -3px;
    top: 24px;
    // height: 100%;
    bottom: 2px;
    width: 1px;
    border-left: $style;
    z-index: 10;
  }
}
.shrink-invitees .invitee{
  margin:0 !important;
  display: inline-flex !important;
  padding-right: 5px;
}
