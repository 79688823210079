@import "../../../styles/themes";
@import "../../../styles/constants";
@import "../../../styles/_screens.scss";

$funnel-hover-color: #79d3fb;
$poll-hover-color: #b6f8dc;

.create-event-form > .form-content > .period-v2 {
  @include themed() {
    //background-color: t("lm-period-background-color");
    color: t("lm-period-color");
    padding: t(lm-space-medium-plus) t(lm-newform-padding-left-right);
    padding-top: 0;
    margin-right: t(lm-newform-padding-left-right-negative);
    margin-left: t(lm-newform-padding-left-right-negative);
    margin-top: 0 !important; //t(lm-space-medium-plus) !important;
    margin-bottom: 0 !important;
    border-radius: 0 0 t(lm-border-radius-normal) t(lm-border-radius-normal);
    box-sizing: border-box;
    .period-title {
      font-size: t("lm-period-title-size");
      font-weight: t("lm-period-title-weight");
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;
      margin-bottom: t("lm-space-normal");
      &.inside {
        margin-top: t("lm-space-medium");
        margin-bottom: 0;
      }
      // &::before {
      //   content: "🚀";
      //   padding-bottom: t("lm-space-xs");
      // }
    }
    .period-message {
      max-width: 550px;
      margin: 0 auto;
      font-weight: t("lm-font-weight-semibold");
      .invisible {
        display: none;
      }
      // b,
      // .open-heatmap {
      //   color: t("color-light-pop");
      //   font-weight: t(lm-font-weight-bold);
      // }
      a {
        margin-left: 4px;
        text-decoration: underline;
        cursor: pointer;
      }
      // .open-heatmap {
      //   background-color: #f5f8ff;
      //   cursor: pointer;
      //   &:hover {
      //     text-decoration: underline;
      //   }
      // }
    }
    .period-cta {
      // margin-right: t(lm-newform-padding-left-right-negative);
      // margin-left: t(lm-newform-padding-left-right-negative);
      padding: t("lm-space-medium");
      padding-bottom: 0 !important;
      & > .period-cta {
        padding-bottom: 0 !important;
      }
      button {
        // box-shadow: t("lm-button-bow-shadow");
        // &:hover {
        //   box-shadow: unset; //t("lm-button-bow-shadow-hover") !important;
        // }
        &.disabled {
          box-shadow: none;
        }

        .MuiCircularProgress-root {
          width: 24px !important;
          height: 24px !important;
          margin-left: -10px;
          margin-right: 5px;
        }
      }
      // funnel
      button:not(.premium):not(.disabled) {
        // border-color: t("lm-funnel-bck-color");
        // background-color: t("lm-funnel-bck-color");
        // color: t("lm-period-color");
        // &:hover {
        //   background-color: $funnel-hover-color;
        //   border-color: $funnel-hover-color;
        // }
      }
      // poll
      &.poll {
        button:not(.premium):not(.disabled) {
          border-color: t("lm-poll-bck-color");
          background-color: t("lm-poll-bck-color");
          color: t("lm-period-color");
          &:hover {
            background-color: $poll-hover-color;
            border-color: $poll-hover-color;
          }
        }
      }
      &.poll-or-funnel {
        width: 100%;
        flex-flow: row wrap;
        display: flex;
        // padding-top: 3em;
        align-items: center;
        padding: 20px 0 0 0;
        @include smScreenAndSmaller() {
          flex-direction: column;
        }
        & > div {
          flex-grow: 1;
          width: 25%;
          // height: 200px;
          padding: 1em t("lm-space-small");
          @include smScreenAndSmaller() {
            width: 80%;
            padding: 0 1em 0 1em;
            // just a test
            border: none;
            border-radius: 0;
          }
        }
        .cta-poll {
          // border: t("lm-period-poll-border");
          margin: 0 0 0 0;
          display: flex;
          // min-height: 500px;
          flex-direction: column;
          align-items: center;
          position: relative;
          // padding: 1.2em 1em;
          padding: 2em 1em;
          transition: all 0.2s ease-out;
          --box-shadow-color: #b3e3fa1c;
          z-index: 0;
          &.poller {
            --box-shadow-color: #c0e7d71c;
          }
          & > * {
            z-index: 1;
          }
          &:first-child {
            border-radius: t("lm-period-poll-border-radius") t("lm-period-poll-border-radius");
            margin-right: 5px;
          }
          &:last-child {
            border-radius: t("lm-period-poll-border-radius") t("lm-period-poll-border-radius");
            margin-left: 5px;
          }
          @include smScreenAndSmaller() {
            &:first-child {
              border-radius: t("lm-period-poll-border-radius") t("lm-period-poll-border-radius") 0 0;
              margin-bottom: 5px;
              margin-right: -40px;
            }
            &:last-child {
              border-radius: 0 0 t("lm-period-poll-border-radius") t("lm-period-poll-border-radius");
              margin-top: 5px;
              margin-left: -40px;
            }
          }
          @include smScreenAndSmaller() {
            border: none !important;
            border-radius: 0;
          }
          .ribbon {
            position: absolute;
            top: 1em;
            color: t("lm-color-pop");
            font-weight: t("lm-font-weight-semibold");
            font-size: t("lm-font-size-normal");
            // text-transform: uppercase;
          }
          &:not(.recommended) {
            // hide one of the border of the non-recommended box
            &:first-child {
              border-right-width: 0;
            }
            &:nth-child(2) {
              border-left-width: 0;
            }
            background-color: white;
          }
          &.recommended {
            // border: t("lm-period-poll-border");
            border-radius: t("lm-period-poll-border-radius");
            // margin: 0 0 0 0;
            // background-color: #f4f7ff;
            //box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);

            padding: 2em 1em;
            .poll-tips {
              // position: absolute;
              // top: 12px;
              // left: 12px;
              // right: 12px;
              margin-bottom: 10px;
              // background-color: #36279d;
              font-size: 12px;
              font-weight: 700;
              text-decoration: underline;
              padding: 6px;
              border-radius: 4px;
              color: #36279d;
              cursor: pointer;
              position: absolute;
              top: 0.2em;
              line-height: 1.1em;
            }
          }
          @include smScreenAndSmaller() {
            width: unset;
            border: none;
            margin: 0;
            padding: 1em;
            border-radius: 0;
            align-self: stretch;
            margin-left: t(lm-newform-padding-left-right-negative);
            margin-right: t(lm-newform-padding-left-right-negative);
          }
          .cta-title {
            font-size: t("lm-period-title-size");
            font-weight: t(lm-font-weight-bold);
            text-transform: uppercase;
            margin: t(lm-space-xs) 0;
          }
          .cta-subtitle {
            font-size: t("lm-font-size-small");
            margin: 0 0 5px 0;
          }
          .cta-desc {
            margin-top: 1em;
            font-size: t(lm-font-size-normal);
            line-height: 1.4em;
          }
          .cta-illus {
            border-radius: t(lm-border-radius-normal);
            margin-top: t(lm-space-small);
            padding: t(lm-space-medium);
            width: 100%;
            box-sizing: border-box;
            svg {
              border-radius: t(lm-border-radius-normal);
              width: 100%;
              height: 100%;
            }
            &.funnel-block {
              background-color: t(lm-funnel-bck-color);
            }
            &.poll {
              background-color: t(lm-poll-bck-color);
            }
          }
          &.inert {
            pointer-events: none;
            opacity: 0.5;
          }
          &:hover {
            transform: translateY(-5px) /*scale(1.005)*/ translateZ(0);
            box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.11), 0 24px 46px var(--box-shadow-color);
            z-index: 1;
          }
        }
      }
      .form-footer.free-user-expire {
        background-color: transparent;
      }
    }
    &.no-period {
      padding-top: 30px!important;
      &.not-auth {
        padding: 0 0 t(lm-space-medium-plus) 0;
        margin: 0 !important;
      }
      &:not(.not-auth) .period-title {
        font-weight: t(lm-font-weight-semibold);
        font-size: t(lm-font-size-medium);
        text-transform: none;
      }
      & .no-period-msg {
        opacity: 0.5;
        margin-bottom: 15px;
        .bold {
          color: red;
        }
      }
      .form-footer.free-user-expire {
        background-color: transparent;
        opacity: 1;
      }
    }
    @include smScreenAndSmaller() {
      margin-right: t(lm-newform-padding-left-right-negative-small);
      margin-left: t(lm-newform-padding-left-right-negative-small);
    }
  }
}
.create-event-form > .form-content > .floating-message {
  @include themed() {
    position: sticky;
    bottom: -1px;
    padding: 16px;
    padding-bottom: 0;
    background-color: white;
    margin-top: t(lm-space-medium-plus) !important;
    border-top: 1px solid transparent;
    svg {
      opacity: 0;
    }
    .period-message-loader.progress-label {
      display: none;
      font-weight: 500;
      position: relative;
      > div:not(.MuiLinearProgress-root) {
        margin-bottom: 10px;
      }
    }
    &.isLoading {
      .period-message {
        display: none;
      }
      .period-message-loader {
        display: block;
      }
    }
    &.isSticky {
      border-top: 1px solid #dadada;
      // box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.2);
      z-index: 1000;
      > svg {
        opacity: 1;
        margin-top: 6px;
      }
      background-color: #f5f8ff;
      transition: all 0.5s;
    }
    .period-message {
      max-width: 80%;
      margin: 0 auto;
      // font-weight: t("lm-font-weight-bold");
      .invisible {
        display: none;
      }
      b,
      .open-heatmap {
        color: t("color-light-pop");
        font-weight: t(lm-font-weight-bold);
      }
      a {
        margin-left: 4px;
        text-decoration: underline;
        cursor: pointer;
      }
      .open-heatmap {
        background-color: white;
        padding: 1px 6px;
        border: 1px solid #7060dfcc;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background-color: #7160df0b;
        }
      }
    }
  }
}
