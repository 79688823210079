@import "../../styles/themes";
@import "../../styles/screens";

$panel-inner-width: 480px;
$default-top-padding: 50px;

.booking-page {
    .mobile-only {
        display: none;
    }
    display: flex;
    overflow-x: hidden;
    @include mdScreenAndSmaller {
        // flex-direction: column;
        display: block;
        .mobile-only {
            display: inherit;
        }
        .back {
            display: flex;
            align-items: center;
            align-self: flex-start;
            margin: 20px 0 0 20px;
            button.grey {
                border: 1.5px solid #dadada;
                border-radius: 4px;
                color: #7060df;
                margin-right: 16px;
            }
            .button-back-label {
                font-size: 20px;
                line-height: 20px;
                color: #523fda;
            }
        }
    }
    min-width: 100%;
    font-size: 16px;
    // letter-spacing: 0.4px;
    @include mdScreenAndSmaller {
        font-size: 14px;
    }
    text-align: left;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    @include themed() {
        font-weight: t(lm-font-weight-normal);
        // background-color: t(lm-background-proposal-side-panel);
    }
    .booking-info-panel {
        // transition: width 500ms cubic-bezier(0.65, 0.05, 0.36, 1);
        overflow-x: hidden;
        height: 100vh;
        overflow-y: auto;
        border-right: 1px solid #efefef;
        @include mdScreenAndSmaller {
            border-right: 0px;
            border-bottom: 1px solid #efefef;
        }
        .inner {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            > div {
                @include mdScreenAndSmaller {
                    width: 100%;
                }
            }
            // // width: $panel-inner-width;
            @media (min-width: 1700px) {
                align-self: flex-end;
                margin-right: 120px;
            }
            @include themed() {
                padding: t(lm-space-medium);
                @include lgScreenAndSmaller {
                    margin-right: 0;
                    width: 100%;
                    padding: 0;
                }
            }
        }
        width: 45%;
        &.no-display {
            display: none;
        }
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include themed() {
            padding-top: t(lm-space-medium-plus);
            @include lgScreenAndSmaller {
                padding-top: t(lm-space-medium-plus);
            }
            @include mdScreenAndSmaller {
                padding-top: t(lm-space-small);
            }
            @include mdScreenAndSmaller {
                width: 100%;
                height: unset;
                padding-top: 0;
            }

            background-color: t(lm-background-proposal-side-panel-light);
        }
    }

    .signature {
        align-self: flex-start;
        &.mobile-only {
            display: none;
        }
        display: flex;
        align-items: flex-end;
        @include themed() {
            margin: 0 0 t(lm-space-large) t(lm-space-large);
            @include mdScreenAndSmaller {
                display: inherit;
                svg {
                    width: 68px;
                }
                margin: 0 0 t(lm-space-medium) t(lm-space-medium);
            }
        }
    }

    .booking-action-panel {
        .relative {
            position: relative;
        }
        overflow-x: hidden;
        @include themed() {
            background-color: t(lm-background-proposal-action-panel);
            width: 100%;
            max-height: 100%;

            align-self: center;
            justify-self: center;
            padding: $default-top-padding t(lm-space-large) t(lm-space-large) $default-top-padding;
            @include lgScreenAndSmaller {
                padding: $default-top-padding t(lm-space-medium-plus) t(lm-space-medium-plus) t(lm-space-medium-plus);
            }
            @include mdScreenAndSmaller {
                padding: t(lm-space-medium);
            }
            @include smScreenAndSmaller {
                overflow-y: inherit;
            }
            .header {
                max-width: 600px;
                & > * {
                    margin: 0;
                    padding: 0;
                    font-weight: t(lm-font-weight-normal);
                }
                h1 {
                    font-size: t(lm-font-size-xlarge);
                    font-weight: t(lm-font-weight-bold);
                }
                p {
                    font-size: t(lm-font-size-medium);
                    margin-top: t(lm-space-small);
                    margin-bottom: t(lm-space-medium-plus);
                }
            }
        }
    }
    #booking-container {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .relative {
            margin: auto 0;
        }
        @include mdScreenAndSmaller {
            display: block;
        }
    }
    .confirmation {
        margin: auto;
        text-align: center;
        & > * {
            margin-bottom: 24px;
        }
        .confirm-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            color: #7060df;
        }
        .confirm-desc {
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: #454545;
        }
        .confirmed-slot {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            font-size: 14px;
            color: #5b5b5b;
            text-align: left;
            strong {
                font-weight: 600;
                font-size: 16px;
                @include themed() {
                    color: t(lm-font-color-primary);
                }
            }
            line-height: 17px;
            padding: 0px;
            .selected-date {
                margin-top: 2px;
                padding-left: 8px;
            }
        }
        .connect-your-calendar {
            margin-top: 60px;
            padding-top: 50px;
            border-top: 1px solid #dadada;
            text-align: left;
            @include mdScreenAndSmaller {
                margin-top: 30px;
                padding-top: 20px;
            }
            .connect-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 120%;
                color: #7060df;
                .light-title {
                    color: #a69de7;
                }
            }
            .connect-message {
                margin: 22px 0;
            }
        }
        @include mdScreenAndSmaller() {
            padding: 32px;
        }
    }
}
