@import "../../../styles/themes";

.settings-calendars {
    .account {
        display: flex;
        align-items: center;
        padding-bottom: 10px !important;
        svg {
            width: 38px;
            height: 38px;
            margin-right: 16px;
        }
        .title {
            font-weight: 500;
            text-transform: capitalize;
        }
        .email,
        .infos {
            @include themed() {
                color: t(color-font-inactive);
            }
            font-size: 14px;
        }
    }
}
