@import "../../styles/_themes.scss";
@import "../../styles/screens";

.landing-permissions-panel{
  @include themed(){
    max-width: 33%;
    position: relative;

    transform: translateX(100%);
    opacity: 0;
    animation: landing-permissions-slide-in 0.5s ease-out forwards;
    &:before{
          content:' ';
          position: absolute;
          background-color: t('lm-border-main-color');
          width: 1px;
          height: 70%;
          left: -50px;
          top: 15%;
        }
    .footer,
    span.txt{
      font-size: t('lm-font-size-normal');
      color: t('lm-font-color-secondary');
      // text-align: justify;
      line-height: t('lm-space-paragraph');
    }
    span.txt{
      display: block;
      position: relative;
      
      &.strong{
        font-weight: t('lm-font-weight-bold');
      }
      &.margin-bottom{
        margin-bottom: t('lm-space-medium')
      }
      &.margin-top{
        margin-top: t('lm-space-medium')
      }
      &.with-icon{
        padding-left: t('lm-space-medium');
        &:before{
          position: absolute;
          color: t('lm-permissions-icon-color');
          border: 1px solid t('lm-permissions-icon-color');
          background: white;
          border-radius: 50%;
          width: t('lm-space-medium');
          height: t('lm-space-medium');
          text-align: center;
          left: 0;
          top: 2px;
          font-weight: t('lm-font-weight-bold');
          line-height: t('lm-space-medium');
        }
        &.icon-1:before{
          content: '1';
        }
        &.icon-2:before{
          content: '2';
        }
        &.icon-a:before{
          content: 'a';
        }
        &.icon-b:before{
          content: 'b';
        }
      }
      &.separator{
        padding-top: t('lm-space-medium');
        &:before{
          content:' ';
          position: absolute;
          background-color: t('lm-border-main-color');
          width: 70%;
          height: 1px;
          left: 15%;
          top: 0;
        }
      }
    }
    .footer{
      margin-right: t('lm-space-xs');
    }
    a.link{
      font-size: t('lm-font-size-normal');
      font-weight: 500;
    }
    @include smScreenAndSmaller(){
      max-width: 80%;
      margin: auto;
      &:before{
        width: 70%;
          height: 1px;
          left: 15%;
          top: -50px;
      }
    }
  }
  
}
@keyframes landing-permissions-slide-in {
    100% { transform: translateX(0%);opacity: 1; }
}