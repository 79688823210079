@import "../../../../styles/_themes.scss";

.eventTooltippopup {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  z-index: 10000;
  & > div {
    display: flex;
    flex-direction: column;
  }
  .tooltip-title {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    @include themed() {
      font-size: t(lm-font-size-small);
      font-weight: t(lm-font-weight-semibold);
      // margin-bottom: t(lm-space-xs);
    }
  }
  .tooltip-location {
    @include themed() {
      font-size: t(lm-font-size-small);
      margin-top: t(lm-space-xs);
      .text-block,
      .text-block a {
        color: t(lm-font-color-secondary) !important;
      }
    }
  }

  .tooltip-view {
    overflow: hidden;
    overflow-y: auto;
    @include themed() {
      margin-top: t(lm-space-xs);
      font-size: t(lm-font-size-small);
    }

    .busy-invitees {
      display: flex;
      align-items: center;
    }
  }
  .DoneChip {
    .container {
      background-color: transparent;
      padding: 2px 0;
    }
  }
}
