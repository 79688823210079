@import "./components/styles/_themes.scss";
@import "./components/styles/_constants.scss";

html {
  height: 100%;
  @include mobileScreenOnly {
    min-height: 100vh;
    // overflow-y: auto !important;
  }
  @include smScreenAndSmaller {
    height: unset;
  }
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: none;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  
}
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  
}
a {
  text-decoration: none;
}
.capitalize {
  text-transform: capitalize !important;
}

.MuiSnackbar-root {
  &.snack-error {
    .MuiSnackbarContent-root {
      background-color: $color-deepchesnut !important;
      button {
        color: white !important;
        &:hover {
          color: black !important;
        }
      }
    }
  }
  &.snack-success {
    .MuiSnackbarContent-root {
      background-color: $color-robineggblue !important;
      button {
        color: white !important;
        &:hover {
          color: black !important;
        }
      }
    }
  }
  &.snack-warning {
    .MuiSnackbarContent-root {
      background-color: $color-bananayellow !important;
      color: black !important;
      button {
        color: black !important;
        &:hover {
          color: black !important;
        }
      }
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.fullwidth{
  width: 100%;
}

.italic{
  font-style: italic;
}
