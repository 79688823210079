@import "../../../styles/themes";
@import "../../../styles/screens";

.slots {
  @include themed() {
    padding-bottom: t(lm-space-medium);
    box-sizing: border-box;
    .done-button {
      display: block;
      &.margin-top {
        margin-top: t(lm-space-medium);
      }
    }
  }
  form {
    @include themed() {
      .slots-for-day {
        margin: t(lm-space-medium) 0;
        .date {
          margin-bottom: t(lm-space-small);
          font-weight: t(lm-font-weight-semibold);
          display: flex;
          align-items: center;
          .timezone {
            color: t(lm-font-color-secondary);
            font-size: t(lm-font-size-small);
            margin-left: 8px;
          }
          .link {
            margin-left: t(lm-space-small);
            text-decoration: underline;
            font-size: t(lm-font-size-small);
            color: t(lm-color-pop);
            cursor: pointer;
          }
        }
        .row {
          display: flex;
          flex-wrap: wrap;
          .slot {
            $stroke: 2px;
            margin: 0;
            position: relative;
            .MuiCheckbox-root,
            .MuiRadio-root {
              padding: 0;
              svg {
                fill: t(lm-font-color-secondary);
              }
            }
            .MuiFormControlLabel-root {
              margin: 0;
            }
            display: flex;
            min-width: 160px;
            @include mobileScreenOnly {
              width: 100%;
            }
            align-items: center;
            // justify-content: center;
            border-radius: t(lm-border-radius-small);
            box-sizing: border-box;
            border: $stroke solid #0000000c;
            margin-bottom: t(lm-space-small);
            &.poll > .MuiFormControlLabel-label {
              flex: 1;
            }
            &:not(.poll) {
              justify-content: center;
            }
            padding: t(lm-space-small);
            .label {
              margin-left: t(lm-space-xs);
              display: flex;
              align-items: center;
            }
            user-select: none;
            font-size: t(lm-font-size-medium);
            @include mobileScreenOnly {
              font-size: t(lm-font-size-normal);
            }
            display: flex;
            align-items: center;
            // }

            &.radio {
              transition: transform 100ms cubic-bezier(0.65, 0.05, 0.36, 1);
              &.selected {
                // transform: translateY(-20px);
                background: white;
                z-index: 2;
              }
              .label {
                margin: 0;
                > .confirmable {
                  position: absolute;
                  // border-radius: 0px 0px 3px 3px;
                  // border: 2px solid #7060df;
                  left: 0px;
                  right: 0px;
                  top: 0;
                  bottom: 0;
                  background: t(lm-color-pop);
                  color: white;
                  padding: t(lm-space-xs);
                  text-align: center;
                  // opacity: 0;
                  display: none;
                  // transition: opacity 200ms cubic-bezier(0.65, 0.05, 0.36, 1) 100ms;
                  &.can-confirm {
                    display: flex;
                    align-items: center;
                    line-height: 1.1em;
                    // opacity: 1;
                    &:hover {
                      background: white;
                      color: t(lm-font-color-primary);
                      cursor: pointer;
                    }
                  }
                }
              }
              .MuiRadio-root {
                display: none;
              }
            }

            &:not(:last-child) {
              margin-right: t(lm-space-small);
              @include mobileScreenOnly {
                margin-right: 0;
              }
            }
            &:hover {
              border: $stroke solid t(lm-font-color-slot);
              cursor: pointer;
            }
            &:not(.disabled) {
              background: none;
            }
            &.disabled {
              background: t(lm-busy-background-color);
              border: $stroke solid transparent;
              cursor: default;
              > .MuiCheckbox-root {
                display: none;
              }
              > * {
                color: transparent;
              }
            }
            &.selected {
              border: $stroke solid t(lm-font-color-slot);
              color: t(lm-font-color-slot);
              .label {
                font-weight: t(lm-font-weight-semibold);
              }
              .MuiCheckbox-root,
              .MuiRadio-root {
                svg {
                  fill: t(lm-color-pop);
                }
              }
            }
          }
        }
      }
    }
    .slots-actions {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      align-content: flex-start;
      .done-button.no-border {
        border: 2px solid white;
        text-decoration: underline;
      }
    }

    .unknwon-email {
      h1 {
        display: flex;
        flex-direction: column;
      }
      .as-email {
        cursor: pointer;
        @include themed() {
          color: t("lm-font-pop-color");
        }
      }
      .done-input {
        margin-top: 18px !important;
        fieldset legend > span {
          font-size: 0.75rem;
        }
      }
    }
    &.as-heatmap {
      display: flex;
      flex-direction: column;
      max-height: 90vh;
      .add-guests-agenda {
        max-height: 66vh;
        .heatmap {
          .agenda {
            .done-picker {
              .dates {
                .week {
                  .donepicker-date {
                    svg {
                      display: none;
                    }
                  }
                }
              }
            }
            .shadowing {
              .eventTooltippopup {
                //display: none;
              }
              .shadow-text {
                display: none;
              }
            }
          }
        }
      }
      .agenda-actions {
        position: initial;
      }
    }
  }
}
