@import "../styles/_themes.scss";
@import "../styles/_screens.scss";

.MuiDialog-root {
    @include themed() {
        button.confirm-destructive-action {
            color: white;
            background-color: t(lm-background-destructive-action);
            &:hover {
                background-color: t(lm-background-destructive-action-hover);
            }
        }
        button.confirm-cancel-destructive-action {
            color: t(lm-font-color-primary);
        }
        button.confirm-positive-action {
            color: white;
            background-color: t(lm-color-pop);
        }
        .MuiDialogActions-root {
            button:first-child:hover {
                background-color: #eaeaea;
            }
        }
    }
}
