.heatmap-time-container{
  bottom: 0;
  opacity: 0.5;
  /* width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: 45px;
  /* height: 100%; */
  pointer-events: none;
  .heatmap-time{
    position: absolute;
    height: 1px;
    background-color: red;
    width: calc(100% / 7);

    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      left: -4px;
      top: -3px;
      border-radius: 50%;
      background-color: red;
    }
  }
}