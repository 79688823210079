@import "../../../styles/_themes.scss";
@import "../../../styles/_screens.scss";
.confirm-one-slot-dlt {
  @include themed() {
    padding: t(lm-space-medium-plus);
  }
  @include themed() {
    .title-container {
      display: flex;
      align-items: center;
      svg {
        margin-right: t(lm-space-medium);
        $h: 50px;
        width: $h;
        height: $h;
      }
      padding-bottom: t(lm-space-medium);
      border-bottom: 1px solid t(color-border-unactive);
      margin-bottom: t(lm-space-medium-plus);
    }
    &.one { 
      .message {
        strong {
          // display: block;
          // text-align: center;
          margin-top: t(lm-space-small);
          font-size: t(lm-font-size-medium);
        }
      }
    }
    .title {
      font-size: t(lm-space-medium);
      font-weight: t(lm-font-weight-bold);
    }
    strong {
      font-weight: t(lm-font-weight-bold);
    }
    .message {
      line-height: 1.7em;
    }
  }
  .actions {
    @include themed() {
      margin-top: t(lm-space-medium-plus);
      display: flex;
      justify-content: flex-end;
      @include mobileScreenOnly() {
        flex-direction: column;
        button {
          margin: 0;
          margin-bottom: t(lm-space-small);
          margin-left: 0 !important;
        }
      }
      .done-button.MuiButton-root {
        margin-left: 20px;
      }
    }
  }
}
