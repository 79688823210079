@import "../../../../styles/_themes.scss";
.heatmap-grid {
  width: 100%;
  display: flex;
  pointer-events: none;
  .days {
    width: calc(100% / 7);
    .hour {
      width: 100%;
      height: 51px;
      // border-left: 1px solid rgba(0, 0, 0, 0.23);
      // border-bottom: 1px solid rgba(0, 0, 0, 0.23);
      @include themed() {
        border-left: t(lm-border-heatmap-grid-hour);
        border-bottom: t(lm-border-heatmap-grid-hour);
      }
      box-sizing: border-box;

      display: flex;
      flex-direction: column;

      .quarters {
        @include themed() {
          border-bottom: t(lm-border-heatmap-grid-quarter);
        }
        flex: 1;
        min-width: 1;
        width: 100%;
        min-height: 1;
        height: 25%;
        opacity: 0.25;

        &:last-child {
          border: none;
        }
      }
    }

    &:last-child {
      .hour {
        // border-left: 1px solid rgba(0, 0, 0, 0.23);
        // border-right: 1px solid rgba(0, 0, 0, 0.23);
        // border-bottom: 1px solid rgba(0, 0, 0, 0.23);
        @include themed() {
          border-left: t(lm-border-heatmap-grid-hour);
          border-bottom: t(lm-border-heatmap-grid-hour);
        }
      }
    }
  }
  .timezone {
    .tz-hour {
      font-size: 10px;
      height: 51px;
      width: 45px;
      position: relative;
      overflow: hidden;
      top: -9px;
      box-sizing: border-box;
      &::after {
        content: "";
        width: 6px;
        height: 1px;
        box-sizing: border-box;
        @include themed() {
          border-top: t(lm-border-heatmap-grid-hour);
        }
        position: absolute;
        right: 0;
        top: 8px;
      }
      &:first-child {
        &::after {
          top: 3px;
        }
        top: -3px;
      }
      text-align: right;
      text-transform: uppercase;
      padding-right: 10px;
      user-select: none;
      @include themed() {
        height: t(heatmap-grid-height);
      }
    }
  }
}
