@import "../../styles/themes";
.profile-network {
  text-align: left;
  @include themed() {
    .title {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: t(lm-font-weight-semibold);
      color: t(lm-font-color-secondary);
      letter-spacing: 1px;
      margin-bottom: t("lm-space-medium");
      svg {
        margin-right: t(lm-space-xs);
      }
    }
    .subtitle {
      margin-bottom: t("lm-space-medium");
    }
    .network-content {
      .network-promote {
        margin-bottom: t("lm-space-large");
      }
      .network-big-number {
        display: flex;
        align-items: center;
        margin-bottom: t("lm-space-large");
        .big-number {
          width: t("lm-space-xxl");
          height: t("lm-space-xxl");
          text-align: center;
          font-size: t("lm-font-size-xlarge");
          font-weight: t("lm-font-weight-bold");
          background-color: t("lm-color-pop");
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: t("lm-space-xs");
          border: 1px solid white;
          border-radius: 3px;
          &:hover {
            cursor: pointer;
            border: 1px solid t(lm-color-pop);
            background-color: white;
            color: t(lm-color-pop);
          }
        }
        .big-number-desc {
          max-width: 300px;
        }
      }
      .last-contacts {
        ul {
          & > li {
            // margin-bottom: t('lm-space-small');
            padding: 0;
            .MuiListItemText-root {
              margin: 3px 0;
            }
            .MuiTypography-body1 {
              font-size: t(lm-font-size-normal);
            }
          }
        }
        display: flex;
        flex-direction: column;
        margin-bottom: t("lm-space-large");
        .last-contacts-title {
          font-weight: t(lm-font-weight-bold);
          color: t(lm-font-color-primary);
        }
        // Button {
        //   align-self: flex-start;
        //   padding: 0;
        // }
        .button-like-a-link {
          width: fit-content;
          margin: 0;
          padding: 0;
          text-decoration: underline;
          color: t(lm-font-color-primary);
          display: inline-block;
          &:hover {
              text-decoration: none;
              cursor: pointer;
          }
      }
      }
      .grow-network {
        & > * {
          margin-bottom: t("lm-space-small");
        }
        .grow-title {
          font-size: t(lm-font-size-large);
          font-weight: t(lm-font-weight-bold);
          color: t(lm-font-color-primary);
        }
      }
    }
  }
}
.contacts-list.loading {
  @include themed() {
    position: relative;
    display: flex;

    padding: t("lm-space-medium");
  }
}
.network-contact-dialog {
  @include themed(){
    padding: t(lm-space-small);
    .MuiListItem-root {
      padding: 2px t(lm-space-normal);
    }
  }
}