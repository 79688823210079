@import "../../../styles/themes";

.timeframe-selector {
  .done-input > button {
    &.MuiToggleButton-sizeLarge {
      padding: 14px;
    }
  }
  @include mobileScreenOnly {
    .done-input {
      display: flex;
      flex-direction: column;
    }
    .done-input > button[value="2"] {
      display: none;
    }
    .done-input > button[value="-1"] > span > * {
      font-size: 12px !important;
    }
    .done-input > button {
      &.MuiToggleButton-sizeLarge {
        padding: 10px;
        // height: 60px;
      }
    }
    @include themed() {
      .done-input > button:first-child {
        border-top-left-radius: t(lm-border-radius-input);
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: t(lm-border-radius-input);
      }
      .done-input > button:last-child {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: t(lm-border-radius-input);
        border-bottom-right-radius: t(lm-border-radius-input) !important;
        border-top-right-radius: 0px !important;
      }
    }
  }
}
