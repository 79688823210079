@import "../../styles/_themes.scss";
@import "../../styles/constants";

.landing {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 66px;
  @include lgScreenAndSmaller {
    padding: 40px;
  }
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  header {
    position: relative !important;
    display: flex;
    justify-content: space-between;
    @include themed {
      background-color: transparent;
    }
    svg {
      // logo
      @include lgScreenAndSmaller {
        width: 80%;
        min-width: 200px;
      }
    }
  }
  section {
    display: flex;
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap: wrap;
    text-align: left;
    // padding: 20px 66px;
    max-width: $app-max-width;
    box-sizing: border-box;
    margin-top: 0 !important;
    @include smScreenAndSmaller() {
      flex-direction: column;
    }
    .text {
      @include lgScreenAndSmaller {
        display: none;
      }
      margin-right: 80px;
      max-width: 60%;
      // flex-grow: 1;
      .title {
        font-size: 58px;
        @include lgScreenAndSmaller {
          font-size: 56px;
        }
        line-height: 70px;
        font-weight: 700;
        .pop {
          @include themed() {
            color: t(pop-color);
          }
        }
      }
      .more {
        font-size: 32px;
        @include lgScreenAndSmaller {
          font-size: 24px;
        }
        margin-top: 24px;
        @include themed() {
          color: t(fade-color);
        }
      }
    }
    .full-width {
      width: 100%;
      margin-bottom: 100px;
    }
    .logo {
      min-height: 50vh;
      flex-basis: 1;
      flex-grow: 1;

      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      .MuiCircularProgress-root {
        margin: 0 100px;
      }
      & > svg {
        position: absolute;
        z-index: 0;
        height: 400px;
        width: 400px;
      }
      & > div {
        z-index: 102;
        .slogan {
          z-index: 64444;
          margin-bottom: 30px;
          max-width: 330px;
          text-align: center;
          line-height: 1.4em;
          strong {
            @include themed() {
              font-weight: t(lm-font-weight-bold);
            }
          }
        }
        .providers {
          display: flex;
          flex-direction: column;
          width: 270px;
          margin: auto;
          // min-width: 300px;
          // padding: 0 100px 0 0;
          button {
            text-transform: initial;
            background-color: white;
            margin-bottom: 20px;
            padding: 12px 16px;
            svg {
              width: 24px;
              height: 24px;
            }
          }
          button:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .firstPotatoe {
    fill: transparentize($color-robineggblue, 0.5);
    -webkit-animation: spin 18s linear infinite;
    -moz-animation: spin 18s linear infinite;
    animation: spin 18s linear infinite;
  }
  .secondPotatoe {
    fill: transparentize(#d1d8e0, 0.75);
    -webkit-animation: spin 28s linear infinite;
    -moz-animation: spin 28s linear infinite;
    animation: spin 28s linear infinite;
    animation-direction: reverse;
  }
  .thirdPotatoe {
    -webkit-animation: spin 38s linear infinite;
    -moz-animation: spin 38s linear infinite;
    animation: spin 38s linear infinite;
    svg {
      path:first-child {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: transparentize($color-robineggblue, 0.55);
      }
    }
  }
}
/* rotate 360 key for refresh btn */
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
