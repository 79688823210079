@import "../../../styles/_themes.scss";
.recap-details-invitees {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: hidden;
  @include themed() {
    border-radius: t(common-box-border-radius);
  }
  // padding-left: 18px !important;
  // padding-right: 18px !important;
  a.edit-btn {
    align-self: flex-end;
    button {
      padding: 0;
    }
  }
  .header {
    position: relative;
    display: flex;
    align-items: center;
    > .picto {
      // position: absolute;
      // left: 0;
      margin-right: 12px;
    }
    strong {
      margin-right: 4px;
    }
    > div {
      flex: 1;
    }
    // padding-left: 36px;
    // padding-bottom: 10px;
    .arrow-toggle {
      display: none;
    }
    @include mobileScreenOnly {
      .arrow-toggle {
        display: block;
      }
    }
  }

  .content {
    // margin-top: 10px;
  }
  > .list-content {
  }
  @include mobileScreenOnly {
    > .list-content {
      display: none;
      &.show {
        display: block;
      }
    }
  }

  span.tip {
    margin-top: 10px;
    @include themed() {
      font-size: t(mini-font-size);
    }
    font-weight: 500;
    &.space {
      padding-top: 12px;
    }
  }
}
