@import "../styles/constants.scss";

$active_color: #1aa3f5;
$color: $color-licorice;
$unactive_color: #e8e8e8;

.months {
    color: $color;
    border-bottom: 1px solid $unactive_color;
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
   
    .month {
        margin: 0 1px;
        font-weight: 500;
        cursor: pointer;
        width: 28px;
        height: 28px;
        padding-bottom: 1px;
        text-align: center;
        line-height: 28px;
        box-sizing: border-box;
        &.selected{
            //color: $active_color;
            font-weight: 800;
        }
        &.disabled {
            pointer-events: none;
            color: $unactive_color;
        }
        &:last-child {
            margin-right: 0;
        }
        &.selected,
        &:hover {
            border-bottom: 2px solid $color-robineggblue;
        }
    }
    &.mini{
        .month{
            width: 20px;
            height: 20px;
            padding-bottom: 1px;
            text-align: center;
            line-height: 20px;
            
        }
    }
}
