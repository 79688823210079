@import "../../../styles/constants";
@import "../../../styles/themes";

.fast-selector {
  padding-left: 14px;
  padding-right: 0px;
  @include themed() {
    border-radius: t(lm-border-radius-input);
  }
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include themed() {
    border: t(form-input-border-default);
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 11px;
  }
  legend.done-input-label {
    transform: translate(8px, -6px) scale(0.75);
    color: $color-robineggblue;
  }
  &.Mui-focused:not(.Mui-error) {
    @include themed() {
      border-color: t(color-robineggblue) !important;
    }
    legend {
      @include themed() {
        color: t(color-robineggblue) !important;
      }
    }
  }
  & + p.Mui-error {
    margin-top: -11px !important;
    padding-top: 3px !important;
    color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    line-height: 1.66;
    margin-left: 14px;
    margin-right: 14px;
    text-align: left;
    @include themed() {
      font-weight: t(lm-font-weight-bold) !important;
    }
    animation: shake 0.5s;
  }
  .guest-selector .text-search button {
    position: absolute;
    right: 0px;
    top: 9px;
    animation: pop 0.3s linear 1;
  }
  .guest-selector .text-search {
    .MuiOutlinedInput-input {
      padding: 8.5px 20px 8.5px 0px;
    }
  }
  fieldset {
    border: none !important;
  }
  .invitees-list {
    flex-direction: row;
    flex-wrap: wrap;

    .tip {
      min-width: 100%;
      flex: 1;
      margin-bottom: 3px;
      font-size: 13px;
      font-weight: 600;
      text-align: left;
    }
    .tip:not(:first-child) {
      margin-top: 4px;
    }
    .guest-list-item {
      margin: 3px;
      margin-left: 0px;
      .MuiChip-root {
        background-color: #f6f6f6;
        font-size: 13px;
        max-width: 300px;
        min-width: 50px;
        color: $color-licorice;
      }
    }
  }

  &.Mui-error {
    border: 1px solid #f44336 !important;
    legend {
      color: #f44336;
      @include themed() {
        font-weight: t(lm-font-weight-bold);
      }
    }
  }
  // .not-all-doners:nth-child(3) {
  //   background-color: red!important;
  //   border: 2px solid green!important;
  //   color: blue!important;
  // }
  .legend {
    .not-all-doners {
      &:first-child {
        margin-top: 10px;
      }
      display: flex;
      flex-grow: 1;
      text-align: left;
      margin-right: 10px;
      margin-bottom: 2px;
      background-color: $color-fgbg;
      padding: 4px 7px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 5px;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .preview-link {
        @include themed() {
          margin-left: t(lm-space-xs);
          text-decoration: underline;
          &:hover {
            color: t(lm-color-pop);
            cursor: pointer;
          }
        }
      }
    }
  }
  .MuiChip-root {
    .chip-actions {
      @include themed() {
        width: unset;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
      button {
        @include themed() {
          position: relative;
          top: 0;
          width: 22px;
          height: 22px;
          background-color: transparent;
        }
        &:hover {
          @include themed() {
            background-color: t(lm-background-item-hover);
          }
        }
        @include themed() {
          color: t(color-robineggblue);
        }
      }
    }
  }
  &.disabled {
    pointer-events: none;
    .legend, .chip-actions, #search-contact {
      display: none;
    }
  }
}
