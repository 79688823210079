.conference {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    margin-right: 12px;
    flex-shrink: 0;
    display: block;
  }
  span {
    white-space: normal;
    // word-break: break-all;
  }
  a {
    text-decoration: underline;
    color: inherit !important;
  }
}
.conference-error {
  display: flex;
  flex-direction: column;
  a.link {
    text-decoration: underline;
    align-self: flex-start;
  }
}
