@import "../../styles/_themes.scss";
.genlinkbtn{
  margin-top: 24px!important;
  
    .MuiCircularProgress-root{
      width: 18px!important;
      height: 18px!important;
      margin-right: 5px;
      color: white;
    }
    
  
  &:hover{
    
      .MuiCircularProgress-root{
        color: inherit;
      }
    
    
  }
}
.lnk-error{
  @include themed(){
    color: t(color-bittersweet)
  }
}
.link-copy{
  margin-top: 24px;
  .link-value{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}