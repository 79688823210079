@import "../../../styles/themes";
@import "../../../styles/_constants";
@import '../../../styles/screens';
.settings-accounts {
  .section {
    .title {
      @include mobileScreenOnly() {
      }
    }
  }
  .add-account {
    display: block !important;
    box-sizing: border-box;
    @include mobileScreenOnly() {
      button {
        width: 100%;
      }
    }
  }
  .accounts {
    .account:not(:last-child) {
      border-bottom: 1px solid;
      @include themed() {
        border-color: t(border-light-color);
      }
    }
    .account {
      display: flex;
      flex-direction: column;
      &.item {
        padding: 10px 10px 10px 0;
        box-sizing: border-box;
      }
      &.removing {
        opacity: 0.5;
      }
      > .remove-account {
        .MuiButton-label {
          > .removing {
            margin-left: 10px;
          }
          .MuiCircularProgress-root {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
      display: flex;
      align-items: flex-start;
      @include mobileScreenOnly() {
        flex-direction: column;
      }
      .container {
        display: flex;
        // flex-direction: column;
        flex: 1;
        width: 100%;
        > svg {
          width: 36px;
          height: 36px;
          margin-right: 16px;
          @include mobileScreenOnly() {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
        .provider {
          // margin-bottom: 10px;
          flex: 1;
          padding-bottom: 5px;
          .title {
            font-weight: 500;
            padding-bottom: 0;
            // text-transform: capitalize;
          }
          .email,
          .infos {
            @include themed() {
              color: t(color-font-inactive);
            }
            font-size: 14px;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .calendar-item {
          padding: 6px;
          padding-left: 0;
          margin-left: 0;
          display: flex;
          align-items: center;
          svg {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            margin-bottom: 2px;
          }
          & > div {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }
        }
      }
      .add-contacts-permission {
        .permission-title {
          display: flex;
          align-items: center;
          @include smScreenAndSmaller(){
            flex-direction: column;
          }
          @include themed() {
            margin-top: t("lm-space-small");
            margin-bottom: t("lm-space-small");
            font-size: t(lm-font-size-normal);
          }
          svg {
            @include themed() {
              margin-right: t("lm-space-small");
            }
          }
          button {
            margin-right: 0 !important;
            flex-shrink: 0;
          }
        }
      }
      &.invalid {
        // @include themed() {
        //   color: t(color-bittersweet);
        // }
        .email,
        .infos {
          @include themed() {
            color: t(color-bittersweet) !important;
            font-weight: t(lm-font-weight-bold);
          }
        }
      }
    }

    .actions {
      @include mobileScreenOnly() {
        align-self: flex-end;
      }
      button {
        .MuiCircularProgress-root {
          width: 18px !important;
          height: 18px !important;
          margin-right: 5px;
        }
      }
      button:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      button:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
      button:only-child {
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
    }
  }
}
