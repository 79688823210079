@import "./styles/_constants.scss";

.Avatar {
    flex: 0 0 auto;
    width: $avatar-dimension;
    height: $avatar-dimension;
    border-radius: 50%;
    background-color: $color-robineggblue;
    //background-image: url("../assets/shadow_avatar.svg");
    color: $color-licorice;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $avatar-font-size;
    font-weight: 500;

    &.avatar-img {
        background-color: unset;
        //background-image: url("../assets/shadow_avatar.svg");
    }
    .avatar-pill {
        position: relative;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: $color-robineggblue;
        img {
            width: 100%;
            // position: absolute;
            // top: 0;
            // left: 0;
            // right: 100%;
            // bottom: 100%;
            object-fit: cover;
        }
    }

    svg {
        width: 20px;
        height: 20px;
    }

    &.square{
      border-radius: 0;
    }
    &.profile-pic{
      width: 72px;
      height: 72px;
      font-size: 36px;
      @include mobileScreenOnly() {
        width: 48px;
        height: 48px;
        font-size: 24px;
      }
    }
}

.Avatar.pills-mode {
    width: $avatar-pills-mode-dimension;
    height: $avatar-pills-mode-dimension;
    font-size: $avatar-pills-font-size;
    svg {
        width: 16px;
        height: 16px;
    }
}
.Avatar.profile-mode {
    width: 256px !important;
    height: 256px !important;
    font-size: $avatar-pills-font-size-big;
    svg {
        width: 128px;
        height: 128px;
    }
}

.ConversationItem-selected {
    > .Avatar {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }
}
