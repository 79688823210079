@import "../components/styles/_themes.scss";

.rewards {
  @include themed() {
    position: absolute;
    left: t("lm-space-normal");
    bottom: -0.5em;
    background-color: t("lm-color-background");
    font-size: t("lm-font-size-small");
    padding: 0 t("lm-space-xs");
    color: #898989;
    animation: rewardsAnim .2s;
    animation-timing-function: ease-in;
    display: block;
    line-height: 1;
    cursor: pointer;
    @include mobileScreenOnly {
      display: none;
    }
  }
}
@keyframes rewardsAnim {
  0% {
    opacity: 0;
    transform: translateX(-4px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
