@import "../styles/_themes.scss";
@import "../styles/_screens.scss";

.GuestConsent-container {
  @include themed() {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: t(lm-font-color-secondary);
    font-size: t(lm-font-size-small);
    .MuiCheckbox-root {
      margin-right: -4px;
    }
  }
}