@import "../../../styles/themes";
@import "../../../styles/screens";

@import "./free.user.scss";

.billing-welcome {
  @include themed() {
    box-sizing: border-box;
    padding: t(lm-space-medium);
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: t(lm-space-medium);
      @include mobileScreenOnly() {
        flex-direction: column;
        text-align: center;
        width: 100%;
        margin-right: 0;
      }
      & > svg {
        width: 128px;
        height: 128px;
        @include smScreenAndSmaller() {
          display: none;
        }
      }
      > div {
        margin-right: t(lm-space-medium);
        .title {
          margin-bottom: t(lm-space-xs);
          font-weight: t(lm-font-weight-bold);
        }
      }
      @include smScreenAndSmaller() {
        margin-bottom: t(lm-space-medium);
      }
    }
  }
}
