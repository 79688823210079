@import "../../../../styles/_themes.scss";
@import "../../../../styles/_screens.scss";
.heatmap-container {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  & > div {
    position: relative;
    overflow: hidden;
  }
  .autoscroll {
    width: 100%;
    height: 10px;
    background-color: transparent;
    position: sticky;
    z-index: 1000;
    user-select: none;
    &.top {
      top: 0em;
    }
    &.bottom {
      bottom: 0em;
    }
  }
  .shadowing {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    margin-left: 45px;
    z-index: 100;
    @include lgScreenAndSmaller {
      margin-left: 0;
    }
  }
  .current.shadow {
    position: absolute;
    box-sizing: border-box;
    z-index: 5;
    pointer-events: none;
    overflow: visible;
    &.shadow-center {
      text-align: center;
      line-height: 10px;
      .container {
        margin-left: 12px;
        pointer-events: none; // Disable hover on FB Components
        user-select: none;
      }
    }
    .container {
      width: calc(100% - 12px);
      box-sizing: border-box;
      height: 100%;
      @include themed() {
        font-size: t(secondary-font-size);
        background-color: t(lm-slot-background-color);
        border-radius: t(lm-border-radius-small);
        box-shadow: t(lm-shadow-slot1), t(lm-shadow-slot2);
        border: 1px solid t(color-robineggblue);
      }
      > span.start,
      > span.end {
        position: absolute;
        left: 10px;
        // background: #ffffffcc;
        @include themed() {
          border-radius: 2px;
          color: t(lm-font-color-secondary);
          font-size: t(lm-font-size-xsmall);
        }
        padding: 4px 4px;
        margin: 0px 0;
        &.start {
          bottom: 100%;
        }
        &.end {
          top: 100%;
        }
      }
    }
    &.invalid-shadow {
      .container {
        @include themed() {
          color: t(lm-font-color-primary);
          font-weight: t(lm-font-weight-semibold);
        }
        .start,
        .end {
          display: none;
          @include themed() {
            color: t(color-bittersweet);
          }
        }
      }
    }
    &.invalid-shadow-in-past {
      .container {
        @include themed() {
          background-color: t(color-bittersweet-alpha);
          border: t(lm-border-slot-busy);
          color: t(color-bittersweet);
        }
      }
    }
    .shadow-text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: transparent;
      @include themed() {
        font-size: t(lm-font-size-xsmall);
      }
    }
  }
}
