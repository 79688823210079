.section.advanced-item {
  &.inactive{
    .title{
      position: relative;
      &::after{
        font-family: "Material Icons";
        content: "\e627";
        position: absolute;
        right: 0;
        top: 0;
        animation: rotate 1.5s linear infinite;
    }
    }
  }
  .more{
    padding-left: 46px;
  }
}
@keyframes rotate{
  to{ transform: rotate(-360deg); }
}