@import "../../../styles/themes";
@import "../../../styles/screens";

@import "./free.user.scss";

.billing-premium-user {
  & > .footer {
    @include themed() {
      margin-top: t(lm-space-normal);
      margin-bottom: t(lm-space-normal);
    }
  }
}
.premium-seats {
  @include themed() {
    .seats {
      display: flex;
      flex-direction: column;
      .manage-seats {
        display: flex;
        align-items: flex-start;
        @include lgScreenAndSmaller {
          flex-direction: column;
        }
        @include lgScreenAndLarger {
          // flex-direction: column;
          .title-container {
            flex: 1;
          }
          .add-seat {
            width: 55%;
          }
        }
      }
      .seats-list {
        display: flex;
        align-items: flex-start;
        @include lgScreenAndSmaller {
          flex-direction: column;
        }
        @include lgScreenAndLarger {
          .title {
            flex: 1;
          }
          .LMTable {
            width: 55%;
          }
        }
      }
      .seat {
        display: flex;
        align-items: center;
        min-height: 2.8em;
        .status-icon {
          margin-right: t(lm-space-small);
        }
        &.account-linked .status-icon {
          color: green;
        }
        &.unused {
          color: t(lm-font-color-secondary);
        }
      }
    }
    .add-seat {
      .form {
        margin: 20px 0;
        display: flex;
        align-items: center;
        .assign {
          border-radius: 3px;
          margin: 0;
          min-height: 56px;
          &.done-button {
            min-width: unset;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
          fieldset {
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        }
      }
    }
    .add-licenses {
      margin: 10px 0;
    }
    .strong,
    strong {
      font-weight: t(lm-font-weight-bold);
    }
    .gotoportal {
      text-decoration: none;
      cursor: pointer;
    }
  }
  .LMTable {
    .MuiTableRow-head .MuiTableCell-root:nth-child(1) {
      width: 100%;
    }
    @include themed() {
      .MuiTableCell-root {
        font-size: t(lm-font-size-small);
      }
    }
  }
}

.LMTable {
  @include themed() {
    border-radius: 4px;
    border: 1px solid #dadada;

    .MuiTableCell-root {
      padding: 14px 10px;
      font-size: 0.9em;
      border: none;
      // vertical separator
      &:not(:last-child) {
        border-right: 1px solid #eaeef5;
      }
      &.date-day {
        white-space: nowrap;
      }
    }
    .MuiTableRow-root {
      border: none;
    }
    // header
    .MuiTableRow-head {
      .MuiTableCell-root {
        white-space: nowrap;
        color: #3d2daa;
        background-color: #f5f8ff;
      }
    }
    .MuiTableBody-root > .MuiTableRow-root:nth-child(even) {
      background-color: #f5f8ff;
    }
    // body cell
    .MuiTableCell-body {
      padding: 5px 10px;
      font-weight: t(lm-font-weight-normal) !important;
    }
    // any cell
    .MuiTableCell-root {
      span {
        display: inline-flex;
        align-items: center;
        > svg.help {
          margin-left: 0.2em;
          width: 0.75em;
          height: 0.75em;
        }
      }
      .MuiButtonBase-root {
        margin-left: 0.2em;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
