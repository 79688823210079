@import "../../../styles/_themes.scss";
.done-input-toggle{
  .MuiToggleButton-root{
    margin: 0 3px;
    margin-left: 3px!important;
    min-width: 48px;
    min-height: 48px;
  }
  .MuiToggleButtonGroup-groupedHorizontal{
    border: 1px solid rgba(0, 0, 0, 0.12)!important;
    border-radius: 4px!important;
  }
  .MuiToggleButton-root.Mui-selected{
    @include themed() {
      background-color: t(pop-color);
      color: t(app-background-color)!important;
    }
  }
}