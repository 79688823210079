@import "../../../styles/_themes.scss";
@import "../../../styles/_screens.scss";
.recap-details-infos {
  display: flex;
  flex-direction: column;
  @include themed() {
    border-radius: t(common-box-border-radius);
  }
  a.edit-btn {
    align-self: flex-end;
    button {
      padding: 0;
    }
  }
  .recap-item:last-child {
    margin-bottom: 0;
  }
  .recap-item {
    flex-shrink: 0;
    .arrow-toggle {
      display: none;
    }
    display: flex;
    align-items: center;

    &.no-center{
      align-items: unset;
    }
    .recap-item-content {
      width: 100%;

      .text-block {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80%;
        @include mobileScreenOnly(){
          max-width: 200px;
        }
      }
    }

    // margin-bottom: 18px;
    // & > svg {
    //   padding-right: 10px;
    // }
    // &.strong {
    //   font-weight: 700;

    margin-bottom: 10px;
    & > svg {
      padding-right: 12px;
    }
    &.strong {
      font-weight: 600;
      font-size: 20px;

      @include themed() {
      }
    }
    .notes-txt-container{
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      justify-content: start;
      width: 100%;
      
      button{
        align-self: flex-start;
      }
    }
    .notes-txt {
      width: 100%;
      max-height: 183px;

      &.preview {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 65px);
      }
      &.fullsize{
        overflow: auto;
        white-space: pre-line;
        max-height: 200px;
        // width: 100%;
      }
    }
    .recap-calendar {
      .title {
        position: relative;
        font-weight: normal;
        @include themed() {
          font-size: t(main-font-size);
        }
      }
      svg {
        align-self: auto;
      }
    }
  }
}
.popup-no-pointer {
  pointer-events: none;
  .notes-txt {
    width: 200px;
    padding: 5px;
    box-sizing: border-box;
  }
}
