@import "../../styles/constants";
@import "../../styles/_common.scss";
@import "../../styles/_themes.scss";

.eventTooltippopup {
    display: block;
    min-width: 33px;
    position: absolute;
    top: -6px;
    @include themed() {
        padding: 15px t(lm-space-medium);
        font-size: t(lm-font-size-normal) !important;
    }
    border-radius: 1em;
    background-color: white !important;
    color: $color-licorice !important;
    text-align: center;
    pointer-events: none;
    -moz-user-select: none;
    user-select: none;
    border-radius: 5px;
    color: $color-licorice;
    min-width: 240px;
    display: inline-block;
    position: relative;

    .tooltip-view .item {
        font-size: $secondary-font-size;
        padding: 12px 12px;
        // title
        .title {
            font-weight: 500;
            display: flex;
            align-items: center;
            .TodoCheckBox {
                display: inline-block;
                margin-right: 5px;
                font-size: 10px;
            }
        }
        // location
        .location {
            font-size: $secondary-font-size;
            color: $color-taupegrey;
            font-weight: 300;
            margin-top: 2px;
        }
        // attendees
        &.attendees {
            &.many {
                display: flex;
                > div {
                    margin-right: 5px !important;
                }
            }
            &.notSoMany > * {
                margin: 3px 0;
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
        // separator
        &:not(:last-child) {
            border-bottom: 1px solid $color-isabelline;
        }
        &.overdue {
            color: $color-deepchesnut;
        }
    }
    &.test,
    &.top,
    &.right,
    &.bottom,
    &.left {
        min-width: 200px;
        width: max-content;
        top: -20px;
        left: 50%;
        transform: translate(-50%, -100%);
        position: absolute;
        z-index: 100;
        box-sizing: border-box;
        // box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
        @include themed() {
            box-shadow: t(lm-shadow-normal);
            border: t(lm-border-tooltip);
        }
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.8s;
        background: white;
        i {
            &::after {
                background: white;
                @include themed() {
                    border: t(lm-border-tooltip);
                }
            }
        }
    }
    &.right {
        i {
            &::after {
                @include themed() {
                    border-width: 0;
                    border-left: t(lm-border-tooltip);
                    border-top: t(lm-border-tooltip);
                }
            }
        }
    }
    &.test {
        position: relative;
        transform: initial;
        top: 0;
        left: 0;
        visibility: visible;
        opacity: 1;
    }

    &.top {
        visibility: visible;
        opacity: 1;
        i {
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -12px;
            width: 24px;
            height: 12px;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                // box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
                @include themed() {
                    box-shadow: t(lm-shadow-normal);
                }
            }
        }
    }

    &.bottom {
        visibility: visible;
        opacity: 1;

        top: calc(100% + 20px);
        transform: translate(-50%, 0);
        i {
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -12px;
            width: 24px;
            height: 14px;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                width: 12px;
                height: 14px;
                left: 50%;
                transform: translate(-50%, 50%) rotate(45deg);
                // box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
                @include themed() {
                    box-shadow: t(lm-shadow-normal);
                }
            }
        }
    }
    &.left {
        visibility: visible;
        opacity: 1;
        top: 50%;
        left: -20px;
        -webkit-transform: translate(-100, -50%);
        transform: translate(-100%, -50%);
        i {
            position: absolute;
            bottom: calc(50% - 6px);
            left: calc(100% + 9px);
            margin-left: -12px;
            width: 24px;
            height: 12px;
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                left: 0;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                border: 1px solid #dca;
            }
        }
    }

    &.right {
        visibility: visible;
        opacity: 1;
        top: 50%;
        left: 100%;
        -webkit-transform: translate(20px, -100%);
        transform: translate(20px, -50%);
        z-index: 9999;
        i {
            position: absolute;
            bottom: calc(50% - 6px);
            left: 0;
            margin-left: -12px;
            width: 24px;
            height: 12px;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                left: 1px;
                top: 50%;
                -webkit-transform: translate(50%, -50%) rotate(-45deg);
                transform: translate(50%, -50%) rotate(-45deg);
                border-top: 1px solid #dca;
                border-left: 1px solid #dca;
            }
        }
    }
    &.test {
        visibility: visible;
        opacity: 1;

        top: calc(100% + 20px);
        transform: inherit;
        i {
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -12px;
            width: 24px;
            height: 12px;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                left: 50%;
                transform: translate(-50%, 50%) rotate(45deg);
                box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
            }
        }
    }
}
