.guest {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  .guest-item-contact {
    max-width: calc(100% - 35px);
  }
  svg {
    width: 11px;
    height: 11px;
  }
}
