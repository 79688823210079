.advanced-item {
  .slug-link {
    .MuiInputAdornment-positionStart {
      margin-right: 0 !important;
      cursor: default;
      p {
        line-height: 1em !important;
      }
    }
    .MuiInputBase-input {
      height: 1em;
    }
    .MuiCircularProgress-root {
      height: auto !important;
    }
  }
}
