.PromoRegister {
    margin-top: 20px;
    //background-color: rgba(0, 206, 191, 0.17);
    border-radius: 5px;
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: fit-content;
    h4 {
        font-weight: 500;
        font-size: 22px;
        padding: 0;
        margin: 14px 30px 30px 30px;
    }
    p {
        font-size: 18px;
        padding: 0;
        margin: 0;
        margin-top: 30px;
    }
    .signin-dialog {
        box-shadow: none;
        background-color: unset;
        padding: 0px;
        flex: 1;
        .header {
            display: none;
        }
    }
}
