@import "../../../styles/_themes.scss";

.event-container {
  .MuiLinearProgress-root {
    height: 2px;
    flex: 0 0 auto !important;
  }
  .event-announce {
    .announce-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
      max-width: 900px;
      padding: 5px 10px;
      margin: 0 auto;
      @include mobileScreenOnly {
        font-size: 16px;
      }
    }
    flex: 0 !important;
    // margin-bottom: 40px;
    //padding: 10px;

    margin: 0 $mobile-mini-frame-padding;
  }
  .announce-connect {
    @include mobileScreenOnly {
      display: none;
    }
    font-size: 16px;
    text-align: left;
    font-weight: normal;
    max-width: 600px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 51px;

    position: relative;
    border-radius: 5px;
    @include themed() {
      background-color: t(color-light-pop-opacity);
    }
    .announce-hint {
      max-width: 250px;
      margin: 0 25px 0 0;
    }
    button {
      //min-width: 50%;
    }
    &:before {
      content: "✨";
      position: absolute;
      left: 15px;
      top: 18px;
      font-size: 22px;
    }
  }
}
