@import "../../styles/themes";
@import "../../styles/screens";

.ProposalStatus {
    display: flex;
    align-items: center;
    font-size: 12px;
    .StatusIcon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        svg {
            fill: white;
            width: 24px;
            height: 24px;
            padding: 0 !important;
        }
        &.WAITING_FOR_YOU,
        &.POLL_FINISHED {
            position: relative;
            &::before {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                background-color: red;
                border-radius: 50%;
                top: 3px;
                right: 2px;
            }
            &::after {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                background-color: red;
                border-radius: 50%;
                top: 3px;
                right: 2px;
                animation: sonarWave 0.9s ease-out infinite;
                animation-iteration-count: 3;
                animation-delay: 800ms;
            }
            @keyframes sonarWave {
                from {
                    opacity: 0.5;
                }
                to {
                    transform: scale(4);
                    opacity: 0;
                }
            }
        }
    }
    .text-container {
        display: flex;
        flex-direction: column;
        margin-left: 6px;
        @include themed() {
            color: t(lm-font-color-primary);
        }
        @include mobileScreenOnly() {
            display: none;
        }
        .primary {
            @include themed() {
                font-weight: t(lm-font-weight-semibold);
            }
            @include mobileScreenOnly {
                line-height: 1em;
                padding-bottom: 10px;
            }
        }
        .secondary {
            @include themed() {
                color: t(lm-font-color-secondary);
            }
            // line-height: 0.7rem;
            max-width: 180px;
            text-align: right;
            @include mobileScreenOnly {
                line-height: 1em;
            }
            // margin-top: -6px;
        }
    }
    &.righttoleft {
        flex-direction: row-reverse;
        .text-container {
            margin-right: 0px;
            margin-left: 0;
            align-items: flex-end;
        }
    }
}
