@import "../../../../styles/themes";

.busy-times {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;
    margin-left: 45px;
    @include mobileScreenOnly {
        margin-left: 10px;
    }
    .busy-slots {
        pointer-events: all;
        position: absolute;
        .hash {
            width: 100%;
            height: 100%;
            opacity: 0.8;
            background-image: linear-gradient(
                135deg,
                rgba(0, 0, 0, 0) 48.08%,
                rgba(0, 0, 0, 0.05) 48.08%,
                rgba(0, 0, 0, 0.05) 50%,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0) 98.08%,
                rgba(0, 0, 0, 0.05) 98.08%,
                rgba(0, 0, 0, 0.05) 100%
            );
            background-size: 73.54px 73.54px;
            pointer-events: all;
            @include themed() {
                background-color: t(lm-busy-background-color);
            }
            // &.away {
            //     // background-image: none;
            //     @include themed() {
            //         //background-color: t(lm-busy-background-color);
            //     }
            // }
        }
        .animated {
            position: relative;
            animation: appearin 0.3s;
            animation-iteration-count: 4;
            animation-fill-mode: backwards;
        }
        .eventTooltippopup {
            display: none;
            .top {
                visibility: visible;
                opacity: 1;
                i {
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -12px;
                    width: 24px;
                    height: 12px;
                    overflow: hidden;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(45deg);
                        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
                    }
                }
            }
            .bottom {
                visibility: visible;
                opacity: 1;

                top: calc(100% + 20px);
                transform: translate(-50%, 0);
                i {
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    margin-left: -12px;
                    width: 24px;
                    height: 12px;
                    overflow: hidden;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 50%;
                        transform: translate(-50%, 50%) rotate(45deg);
                        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
                    }
                }
            }
            .left {
                visibility: visible;
                opacity: 1;
                top: 50%;
                left: -20px;
                -webkit-transform: translate(-100, -50%);
                transform: translate(-100%, -50%);
                i {
                    position: absolute;
                    bottom: calc(50% - 6px);
                    left: calc(100% + 9px);
                    margin-left: -12px;
                    width: 24px;
                    height: 12px;
                    overflow: hidden;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 0;
                        top: 50%;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        border: 1px solid #dca;
                    }
                }
            }
            .right {
                visibility: visible;
                opacity: 1;
                top: 50%;
                left: 100%;
                -webkit-transform: translate(20px, -100%);
                transform: translate(20px, -50%);
                z-index: 9999;
                i {
                    position: absolute;
                    bottom: calc(50% - 6px);
                    left: 0;
                    margin-left: -12px;
                    width: 24px;
                    height: 12px;
                    overflow: hidden;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 1px;
                        top: 50%;
                        -webkit-transform: translate(50%, -50%) rotate(-45deg);
                        transform: translate(50%, -50%) rotate(-45deg);
                        border-top: 1px solid #dca;
                        border-left: 1px solid #dca;
                    }
                }
            }
        }
    }
    .evttooltip {
        // cursor: pointer;
        &:hover {
            @include themed() {
                background-color: t(lm-busy-background-color-hover);
            }
            .eventTooltippopup {
                display: flex;
            }
        }
    }
}

@keyframes appearin {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
