@import "../../styles/constants";

.freebusy-hint {
    user-select: none;
    .mini {
        display: none;
    }
    .normal {
        display: block;
    }
    @include mobileScreenOnly {
        .mini {
            display: flex;
            align-items: center;
        }
        .normal {
            display: none;
        }
    }
}
