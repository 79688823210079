@import "../../styles/_themes.scss";
@import "../../styles/_screens.scss";

.success-toast-2023-05 {
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  .invitees-list-groups {
    .invitees-list {
      text-align: left;
      border-bottom: 1px solid #dadada;
      padding-bottom: 16px;
      padding-top: 28px;
      &:last-of-type {
        .copy-link-component {
          display: none;
        }
      }
      &:first-child {
        .copy-link-component {
          display: flex;
          align-items: center;
          // justify-content: space-between;
        }
      }
      & > div {
        margin-bottom: 8px;
      }
      .invitees-list-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.8);
        text-transform: uppercase;
      }
      .invitees-list-subtitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #523fda;
        
        margin-top: 8px;
      }
      .copy-link-component {
        margin-bottom: 0;
        .link {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #454545;
          margin-right: 16px;
          a {
            @include themed() {
              color: t(lm-font-color-primary) !important;
            }
            // &:hover {
              text-decoration: underline!important;
            // }
          }
        }
        .done-button {
          margin: 0;
          min-width: unset;
          padding: 2px 24px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        .done-button[data-testid=copylink] {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-right-color: transparent;
        }
        .done-button[data-testid=visitlink] {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      .invitees-list-details {
            margin-top: 13px;
        .invitees-list-details-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 8px;
        }
        .guest-list-container {
          display: flex;
          flex-wrap: wrap;
          .email-avatar {
            display: flex;
            align-items: center;
            padding: 8px 16px 8px 8px;
            border: 1px solid #dadada;
            border-radius: 68px;
            margin-right: 10px;
            margin-bottom: 12px;
            max-width: 490px;
            .Avatar {
              width: 24px;
              height: 24px;
            }
            .info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              span {
                font-size: 14px;
                line-height: 17px;
                margin-left: 6px;
                display: none;
                word-break: break-all;
              }
              span:first-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #242222;
                display: flex;

                svg {
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
  .success-proposal-details {
    display: grid;
    grid-template-columns: auto 3fr;
    column-gap: 1em;
    text-align: left;
    justify-items: start;
    margin: 28px 0;
    align-items: center;
    .invitees-list-details-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.5);
    }
    .value {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }
  }
  a.link-to-dashboard {
    margin-top: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    svg {
      color: white;
      background-color: #523fda;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 8px;
    }
  }
}
