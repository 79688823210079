@import "../../../styles/screens";
@import "../../../styles/themes";

.keep-space {
  margin-left: 48px;
}
.tz-autocomplete {
  width: 100%;

  .tz-label {
    font-weight: 900;
  }
  .tz-autocomplete-input {
    width: 100%;
    max-width: 256px;
  }
}
ul.lm-tz-picker-listbox {
  li {
    font-size: 14px;
    &[data-focus="true"] {
      font-weight: 600;
    }
  }
  .MuiListSubheader-root {
    @include themed() {
      color: t(lm-font-color-primary);
    }
    line-height: 36px;
    font-weight: 700;
    background-color: #F5F5F5;
    top: -9px;
    border: 0 solid #dadada;
    border-bottom-width: 1px;
    border-top-width: 1px;
  }
}
