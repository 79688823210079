@import "../../styles/constants";
@import "../../styles/themes";

.asDialog {
  z-index: 1600 !important;
  .title-with-close > h2 {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .title-with-close {
    @include themed() {
      .MuiCircularProgress-root {
        color: t(lm-font-color-primary);
        margin-right: t(lm-space-normal);
      }
      button {
        margin-left: t(lm-space-large);
      }
    }
  }

  .preview-explanation {
    @include themed() {
      .sentence {
        padding-bottom: t(lm-space-large);
      }
      .to,
      .from {
        padding: 8px 0;
        border-top: 1px solid t(lm-border-color-button-secondary);
        font-size: 14px;
        display: flex;
        .label {
          padding-right: t(lm-space-xs);
          color: t(lm-font-color-secondary);
          display: block;
        }
        .value {
          display: block;
          font-weight: t(lm-font-weight-semibold);
        }
      }
      .to {
        border-bottom: 1px solid t(lm-border-color-button-secondary);
      }
    }
  }

  .no-fullscreen {
    position: relative;
    /* min-height: 400px; */
    /* top: 50px; */
    transform: translateY(50%);
    min-height: 200px;
    min-width: 200px;
  }

  .error-preview {
    @include themed() {
      margin-bottom: t(lm-space-medium);
    }
  }
  .preview-email-dlg {
    a,
    button {
      pointer-events: none !important;
    }
  }
}
.thumbnail {
  .title-with-close > h2 {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .title-with-close {
    @include themed() {
      h2 {
        .MuiCircularProgress-root {
          color: t(lm-font-pop-color);
          margin: auto;
        }
        button {
          display: none;
        }
      }
    }
  }

  .preview-explanation {
    @include themed() {
      .sentence {
        padding-bottom: 20px;
        a {
          pointer-events: all !important;
          font-weight: normal;
          margin-left: 0.5em;
        }
      }
      .from {
        border-radius: 3px 3px 0 0;
      }
      .to,
      .from {
        padding: 8px 8px;
        border-top: 1px solid t(lm-border-color-button-secondary);
        font-size: 10px;
        display: flex;
        background-color: white;
        color: black;
        .label {
          padding-right: t(lm-space-xs);
          color: t(lm-font-color-secondary);
          display: block;
        }
        .value {
          display: block;
          font-weight: t(lm-font-weight-semibold);
        }
        .invitee {
          word-break: break-all;
        }
      }
      .to {
        border-bottom: 1px solid t(lm-border-color-button-secondary);
      }
    }
  }
  .thumb-preview {
    max-height: 40vh;
    overflow: auto;
    border-radius: 0 0 3px 3px;
  }

  .no-fullscreen {
    position: relative;
    /* min-height: 400px; */
    /* top: 50px; */
    transform: translateY(50%);
    min-height: 200px;
    min-width: 200px;
  }

  .error-preview {
    @include themed() {
      margin-bottom: t(lm-space-medium);
    }
  }
  .preview-email-dlg {
    padding: 8px 2px;
    a,
    button {
      pointer-events: none !important;
    }
  }
  .MuiDialogTitle-root.title-with-close:not(.loader) {
    display: none;
  }
  .lm-main {
    padding: 12px !important;
    span {
      word-break: break-all;
    }
    a {
      @include themed() {
        color: t(lm-color-pop);
      }
    }
  }
}

.MuiTooltip-popper#thumbnail {
  .MuiTooltip-tooltip {
    max-width: 500px;
  }
}
