@import "../styles/constants.scss";

$active_color: $color-licorice;
$unactive_color: #b9b8b8;
$unactive_color2: #e8e8e8;

.calendar-base {
    -moz-user-select: none;
    user-select: none;
    position: relative;
    color: black;
    padding: 12px;
    display: flex;
    flex-direction: column;
    font-size: 0.9em;

    .year-ctrl {
        display: flex;
        justify-content: space-between;
    }
    .days {
        color: $unactive_color;
        font-size: 18px;
        /* left: 355px; */
        /* top: 80px; */
        word-spacing: 35px;
        font-weight: 600;
        margin-top: 6px;
        text-transform: uppercase;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        .dayOfWeek {
            min-width: 50px;
            text-align: center;
        }
    }

    .dates {
        display: flex;
        flex-direction: column;

        .week {
            display: flex;
            justify-content: space-between;
            margin-bottom: 14px;
            padding: 0 5px;
            .date {
                padding: 8px;
                cursor: pointer;
                font-weight: 400;
                max-width: 18px;
                width: 18px;
                min-width: 18px;
                text-align: center;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.unselectable {
                    color: $unactive_color;
                    pointer-events: none;
                    font-weight: 100;
                }
                &.selected {
                    background-color: $active_color;
                    border-radius: 50%;
                    color: white;
                }
                &:hover {
                    border-radius: 50%;
                    background-color: $color-isabelline;
                }
                &.today {
                    color: $color-robineggblue;
                    &.selected {
                        background-color: $color-robineggblue;
                        color: white;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    &.condensed {
        font-size: 0.8em;
        .days {
            font-size: 12px;
            margin-top: 4px;
            margin-bottom: 8px;
            .dayOfWeek {
                min-width: 38px;
            }
        }
        .dates {
            .week {
                // margin-bottom: 8px;
                span {
                    //padding: 10px;
                }
            }
        }
    }
}
