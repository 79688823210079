@import "../../styles/themes";
@import "../../styles/screens";

.profile-page {
  @include themed() {
    font-size: t(lm-font-size-normal);
    .MuiFormLabel-root {
      font-size: t(lm-font-size-normal);
    }
    text-align: left;
    display: flex;
    width: 100%;
    @include lgScreenAndSmaller() {
      width: 100vw;
    }
    box-sizing: border-box;
    max-width: $app-max-width;
    padding: t("lm-space-large") 0;
    margin: 0 auto;
    // flex: 1 1;
    flex-direction: row;
    align-content: flex-start;
    // @include mobileScreenOnly(){
    @include lgScreenAndSmaller() {
      flex-direction: column;
      padding: t("lm-space-small");
      margin: 0 auto;
    }
    .profile-page-content {
      margin: 0 t("lm-space-medium");
      flex: 1 1 0;
      // @include mobileScreenOnly(){
      padding-right: 40px;
      @include lgScreenAndSmaller() {
        margin: 0 t("lm-space-small");
        padding-right: 0;
        @include mobileScreenOnly() {
          margin: 0 t("lm-space-xs");
          // margin: t("lm-space-small");
        }
        padding-top: t("lm-space-medium");
      }
      box-sizing: border-box;
      // width: 100%;
      &.left {
        width: 50%;
        border-right: 1px solid t(lm-border-main-color);
        @include lgScreenAndSmaller() {
          margin: 0;
          padding: t("lm-space-medium");
          @include mobileScreenOnly() {
            padding: t("lm-space-small");
          }
          width: 100%;
          border: 0;
        }
      }
      &.right {
        @include lgScreenAndSmaller() {
          margin: 0;
          padding: t("lm-space-medium");
          width: 100%;
        }
      }
    }
    .profile-separator {
      display: none;
      width: 1px;
      height: 50vh;
      margin: 2% t("lm-space-xxl");
      background-color: t("lm-background-color-secondary-info");
      // @include mobileScreenOnly(){
      @include lgScreenAndSmaller() {
        display: none;
        // width: 50vh;
        // height: 1px;
        // margin: t("lm-space-small") 2%;
      }
    }
  }
}
