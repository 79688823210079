@import "../../../styles/themes";
@import "../../../styles/screens";
.page-content.page-proposal {
  flex: 1 1;
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  @include themed() {
    color: t("lm-font-color-primary");
    // background-color: t('lm-busy-background-color');
  }
  @include mobileScreenOnly() {
    // flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
  }
  .send-vote-progress {
    width: 100%;
    align-self: flex-start;
  }
}
