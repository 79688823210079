@import "../styles/_themes.scss";
@import "../styles/_screens.scss";
.funnel-promote {
  min-height: 400px;
  @include themed() {
    .funnel-promote-title {
      padding: t(lm-space-normal);
      font-size: t("lm-font-size-large");
      font-weight: t("lm-font-weight-semibold");
      text-align: center;
      padding: t(lm-space-normal) 0 t(lm-space-medium) 0;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        list-style: none;
        padding: 0;
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        @include mobileScreenOnly() {
          flex-direction: column;
          text-align: center;
        }
        img {
          padding-right: t(lm-space-normal);
          @include mobileScreenOnly() {
            width: 80%;
            margin-bottom: 5px;
            padding: 0;
          }
        }

        .funnel-view {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          min-width: 350px;
          padding-right: 10px;
          .slots {
            display: flex;
            align-items: center;
            padding-top: 5px;
            .slot {
              width: 30px;
              height: 30px;
              display: flex;
              flex-direction: column;
              align-items: center;
              border: 1px solid black;
              border-radius: 5px;
              margin-right: 5px;
              line-height: normal;
              .hour {
                font-size: 10px;
                font-weight: 600;
              }
              .date {
                font-size: 6px;
              }
            }
          }
        }
      }
    }
  }
}
