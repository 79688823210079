.no-event{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg{
    width: 256px;
    height: 256px;
  }
  .error-message{
    font-size: 22px;
    font-weight: 500;
  }
}