@import "../../../../styles/themes";
@import "./common";
.timeline-item.refused-answer {
  padding-top: 2px;
  @include timelineitem();
  @include with-icon("../../../../../assets/someone_noavail.svg");
  @include themed() {
    @include with-line(1px solid t(lm-timeline-line-color));
  }
  @include themed() {
    font-size: t("lm-font-size-normal");
  }
  .invitee.answered {
    @include themed() {
      margin: t("lm-space-xs") 0;
    }
    // text-indent: 1em;
  }
}
