@import "../styles/constants";
@import "../styles/themes";

.LMTooltip {
    // @include themed() {
    //     background-color: t(lm-busy-background-tooltip);
    //     color: t(lm-font-color-tooltip);
    // }
    font-family: Montserrat;
    @include themed() {
        padding: 5px 15px !important;
        font-size: t(lm-font-size-normal);
        box-sizing: content-box;
    }
    p {
        line-height: 1.5em;
        margin: 8px 0;
    }
    .strong {
        @include themed() {
            font-weight: t(lm-font-weight-semibold);
        }
    }
    .underline {
        text-decoration: underline;
    }
    .showHand {
        cursor: pointer;
    }
    a {
        @include themed() {
            color: t(lm-font-color-tooltip);
            font-weight: t(lm-font-weight-semibold);
        }
        text-decoration: underline;
    }
}
.LMTooltip-children {
    margin: 0;
    padding: 0;
    line-height: 0;
    &>* {
        line-height: normal;
    }
    &.showHand,
    &.showHand > * {
        cursor: pointer;
    }
    &.underline {
        text-decoration: underline;
    }
    &.strong {
        @include themed() {
            font-weight: t(lm-font-weight-semibold);
        }
    }
}

.MuiTooltip-arrow {
    color: #1b1b1b;
    &::before {
        background-color: #1b1b1b !important;
    }
}
