@import "../SettingsPage.scss";
@import "../../../styles/_constants";
@import "../../../styles/screens";

.asDialog {
  padding: 20px;
  min-width: 80%;
  @include mobileScreenOnly {
    padding: 12px;
    min-width: unset;
  }

  .settings-availability {
    @include section-content;
    @include mobileScreenOnly {
      padding: 2px;
      min-width: unset;
    }
    .section .item .done-input-toggle button {
      @include mobileScreenOnly {
        min-width: calc(87% / 7);
      }
    }
    .item {
      &.save {
        display: flex !important;
      }
    }
  }
}
.settings-availability {
  .section {
    .item {
      padding: 10px !important;
      padding-left: 0 !important;
      flex-wrap: wrap;
      @include mobileScreenOnly {
        .done-input-toggle {
          width: 100%;
          button {
            margin: 0 3px;
            margin-left: 0px !important;
            min-width: calc((100% / 7) - 3px);
            min-height: 40px;
          }
        }
      }
      .tz-autocomplete {
        .MuiInputBase-root {
          border-radius: 4px;
          border: 1px solid #dadada;
          padding-left: 8px;
        }
      }
    }
  }
  .timeframe {
    @include mobileScreenOnly() {
      justify-content: space-evenly;
    }
    > span {
      margin: 0 10px;
    }
    > span:first-child {
      margin-left: 0;
    }
  }
  .item {
    &.save {
      display: block !important;
      button {
        @include mobileScreenOnly() {
          width: 100%;
          padding: 1px;
        }
      }
    }
  }
  .no-padding {
    padding: 0;
    margin: 0;
  }
}
