@import "../styles/constants.scss";

$active_color: $color-licorice;
$unactive_color: $color-font-inactive;

.year {
    // color: $unactive_color;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex: 1;
    .year-value {
        pointer-events: none;
        min-width: 90px;
        text-align: left;
        flex: 1;
    }
    .MuiIconButton-colorPrimary {
        color: $active_color;
        &.disabled {
            color: $unactive_color;
        }
    }
    &.condensed {
        .MuiButtonBase-root {
            padding: 4px;
        }
        .year-value {
            font-size: 20px;
        }
    }
}
