.done-input.foldable{
  box-shadow: none;
  margin: 0 0px;
  margin-top: 0 !important;
  margin-bottom: 20px!important;
  &::before{
    border: none;
    box-shadow: none;
    height: 0;
  }
  .MuiAccordionSummary-root{
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    z-index: 3;
    top: 4px;
    background-color: white;
    padding: 0 4px 0 6px;
    .MuiAccordionSummary-content{
      margin: 4px 0;
      font-size: 0.75rem !important;
      flex-grow:0;
    }
    .MuiAccordionSummary-expandIcon{
      padding: 0;
      margin-right: -2px;
    }
    min-height:  unset;
    &.Mui-expanded{
      min-height:  unset;
    }
  }
  .MuiAccordionDetails-root{
    padding: 0 0px;
  }
  .fast-selector{
    width: 100%;
    margin-top: 8px;
  }
}