@import "../../../styles/themes";
@import "../../../styles/screens";
.proposal-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-around;
  @include themed() {
    padding-top: t("lm-space-medium");
  }
  .done-button {
    min-width: unset;
    
    @include themed() {
      font-size: t("lm-font-size-normal");
      padding: 2px t("lm-space-xs");
      margin: t("lm-space-xs");
    }
    &.MuiButton-root .MuiButton-label {
      margin: 0 5px;
      svg {
        margin-right: 0;
        width: 18px;
        height: 18px;
      }
    } 
    &:first-child {
      margin-left: 0;
    }
  }

  @include mobileScreenOnly() {
    display: flex;
    flex-direction: column;
    width: auto;
    @include themed() {
      border-top: none;
    }
    display: flex;
    flex-direction: column;
    & > div {
      @include themed() {
        margin: 2px t("lm-space-small");
      }
      display: flex;
      button {
        flex: 1;
      }
    }
  }
}
