@import "../../styles/themes";
@import "../../styles/screens";
@import "./inputs/periods/period.selector.scss";

// form {
//   flex: 1;
// }

$newDarkerPurple: #36279d;

// Version/Variant introduced in December 2022
form.create-event-form-container.Version202212 {
  @include themed() {
    > .title {
      margin: 26px 10px 18px 10px;
      font-size: 28px;
      color: $newDarkerPurple;
      font-weight: 700;
    }
    .create-event-form {
      position: relative;
      box-sizing: border-box;
      @include themed() {
        width: 90%;
        min-width: 200px;
        max-width: 900px;
        margin: auto;
        margin-bottom: 2em;
        border-radius: 8px;
        background-color: #f5f8ff;
        border: 2px solid #f5f8ff;
        padding: 0;
        .form-header {
          position: inherit;
          margin: 0 !important;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: t(lm-newform-container-padding);
        }
        .form-content {
          position: relative;
          z-index: 0;
          background-color: white;
          border-radius: t(lm-border-radius-normal);
          margin: 0 t(lm-newform-container-padding) t(lm-newform-container-padding) t(lm-newform-container-padding) !important;
          padding: t(lm-newform-padding-top-bottom) t(lm-newform-padding-left-right) 0 t(lm-newform-padding-left-right) !important;
          box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.1);

          .add-guests-panel.in-form {
            // position: relative;
            height: calc(100vh - 240px);
            margin: -30px;
            max-height: 680px;
            margin-top: 0px!important;
            .add-guests-agenda {
              padding: 0;
              .agenda-head {
                justify-content: flex-end;
                .title {
                  font-size: 14px;
                  margin-left: 10px;
                }
              }
            }
            @include with-heatmap-disabled();
            // &.disabled {
            //   height: calc(50vh - 240px);
              
            // }
            // .disabled {
            //   position: absolute;
            //   z-index: 99999;
            //   background-color: rgba(255, 255, 255, 0.85);
            //   left: 0;
            //   right: 0;
            //   top: 0;
            //   bottom: 0;
            //   display: flex;
            //   flex-direction: column;
            //   align-items: center;
            //   justify-content: center;
            //   gap: 20px;
            //   font-size: 16px;
            //   font-weight: 500;
            //   overflow: hidden;
            //   &.hand {
            //     cursor: pointer;
            //   }
            //   > div {
            //     max-width: 390px;
            //     padding: 10px;
            //     text-align: center;
            //     &.link {
            //       text-decoration: underline;
            //       cursor: pointer;
            //     }
            //   }
            //   svg {
            //     width: 90px;
            //   }
            //   img {
            //     width: 80%;
            //     border-radius: 6px;
            //     box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.20);
            //     margin: -10px 0;
            //   }
            // }
          }
        }
        @include smScreenAndSmaller() {
          width: 100%;
          border-radius: 0;
          // border: none;
          &::before {
            border-radius: 0;
          }
          .form-header {
            padding: t(lm-newform-container-padding-small);
          }
          .form-content {
            padding: t(lm-newform-padding-top-bottom-small) t(lm-newform-padding-left-right-small) 0
              t(lm-newform-padding-left-right-small) !important;
            margin: 0 t(lm-newform-container-padding-small) t(lm-newform-container-padding-small)
              t(lm-newform-container-padding-small) !important;
          }
        }
        &.hold-slots{
          .add-guests-panel.in-form{
            .heatmap{
                .simple-hand-drawing {
                  .proposal {
                    .proposal-title {
                      &::before{
                        content: '🔒'
                      }
                    }
                  }
                }
              
            }
          }
        }
      }
      & > .form-content {
        .done-input {
          &.no-bottom {
            margin-bottom: 0 !important;
          }
        }
        fieldset {
          margin: 0;
        }
        > * {
          &.timeframe-selector {
            margin-bottom: 0 !important; //12px !important;
            margin-top: 6px !important;
          }
        }
        .form-item-titles {
          text-align: left;
          font-weight: 700;
          margin-top: 20px !important;
          margin-bottom: 0px !important;
          @include themed() {
            font-size: t(lm-font-size-medium);
            letter-spacing: 0.5px;
          }
          position: relative;
          .tiny-holdslot.v202212 {
            @include themed() {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              font-size: t(lm-font-size-small);
              letter-spacing: 0px;
              font-weight: t(lm-font-weight-semibold);
              color: t(lm-color-pop);
              cursor: pointer;
              .LMTooltip-children {
                display: inline-flex;
                align-items: center;
              }
              button {
                background-color: t(lm-color-pop);
                border-radius: 50px;
                padding: 2px;
                margin-left: 4px;
                svg {
                  width: 20px;
                  height: 20px;
                  fill: white;
                }
              }
              &:not(.checked) {
                button {
                  margin-left: 0;
                  background-color: white;
                  svg {
                    fill: t(lm-color-pop);
                  }
                }
              }
              &:hover {
                text-decoration: underline;
              }
              &.disabled {
                padding: 0px;
                // pointer-events: none;
                color: rgba(0, 0, 0, 0.36);
                cursor: not-allowed;
                background-color: white;
                svg {
                  fill: rgba(0, 0, 0, 0.36) !important;
                }
              }
            }
          }
        }
        .MuiTextField-root + .foldable {
          margin-top: 0 !important;
          margin-bottom: 0px !important;
        }
      }
    }
    .free-user-tip {
      @include themed() {
        color: #5b5b5b;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 6px;
      }
    }
    .free-user-expire {
      margin-top: -15px;
      margin-bottom: 16px;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-weight: 600;
          margin-bottom: 7px;
        }
        .message {
          max-width: 500px;
          margin-bottom: 22px;
        }
        .done-button.MuiButton-root.premium {
          @include themed() {
            color: t("premium-done-button-background-color");
            background-color: t("color-main-font-color");
            border: 2px solid t("color-main-font-color");
          }
        }
      }
    }
    @include themed() {
      .FormNotice {
        background-color: t(lm-background-item-hover);
        border-radius: t(lm-border-radius-normal);
        font-size: t(lm-font-size-normal);
        padding: 14px;
        margin: 0 auto 20px auto;
        max-width: 900px;
        width: 90%;
        box-sizing: border-box;
        p {
          margin: 0;
          margin-top: 10px;
          padding: 0;
          a {
            color: t(lm-color-pop);
            text-decoration: underline;
          }
          a.goto {
            color: t(lm-font-color-primary);
            font-weight: t(lm-font-weight-bold);
            text-decoration: none;
          }
          &:first-child {
            margin-top: 0;
          }
          b,
          strong {
            font-weight: t(lm-font-weight-bold);
          }
        }
      }
    }
  }
}

.bounce-in {
  animation: bounce-in 0.5s ease;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

