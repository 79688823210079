@import "../../../../styles/_themes.scss";
@import "../../../../styles/_screens.scss";
.selected-calendar {
  display: flex;
  overflow: hidden;
  max-width: 100%;
  & > svg {
    width: 27px;
    height: 27px;
    padding-right: 11px;
    align-self: center;
  }
  .content {
    text-align: left;
    .title {
      display: flex;
      flex-direction: column;
      align-content: center;
      @include mobileScreenOnly() {
        flex-direction: column;
      }
      > span {
        white-space: nowrap;
      }
      @include themed() {
        font-size: t(calendar-title-font-size);
        color: t(calendar-title-font-color);
      }
      font-size: 16px !important;
      font-weight: 500;
      // text-transform: capitalize;

      svg {
        margin: auto 0;
        @include mobileScreenOnly() {
          display: none;
        }
      }
      .accountname {
        @include themed() {
          font-size: t(lm-font-size-small);
          color: t(lm-font-color-secondary);
          font-weight: t(lm-font-weight-normal);
        }
      }
      .calname {
        display: flex;
          align-items: flex-start;
          flex-direction: column;
        @include mobileScreenOnly() {
          flex-direction: column;
          align-items: flex-start;
          @include themed() {
            color: t(calendar-title-font-color);
          }
          font-size: 13px !important;
        }
        .tz {
          @include themed() {
            font-size: t(lm-font-size-small);
            color: t(lm-font-color-secondary);
            font-weight: t(lm-font-weight-normal);
          }
          // margin-left: 5px;
          @include mobileScreenOnly() {
            margin-left: 0px;
          }
        }
      }
    }
    .email {
      @include themed() {
        font-size: t(calendar-email-font-size);
        color: t(calendar-email-font-color);
      }
    }
    
  }
}
