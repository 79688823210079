@import "../../../styles/themes";
@import "../../../styles/screens";

@mixin with-icon {
  padding-left: 10px;
  position: relative;
  left: 10px;
  &::before {
    font-family: "Material Icons";
    position: absolute;
    left: -10px;
    font-size: 15px;
    line-height: 15px;
    top: 2px;
  }
}
.proposal-details-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  &:not(.timeline-details) {
    @include mobileScreenOnly() {
      display: none;
    }
  }

  @include themed() {
    color: t("lm-font-color-primary");
    font-size: t("lm-font-size-normal");

    .important {
      color: t(lm-color-pop);
      margin-right: t(lm-space-small);
    }
  }

  .proposal-header {
    width: 100%;
    @include themed() {
      margin-bottom: t(lm-space-xs);
      text-transform: uppercase;
      color: t("lm-font-color-secondary");
      font-size: t("lm-font-size-xsmall");
      letter-spacing: 2px;
      @include mobileScreenOnly() {
        display: none;
      }
      .type {
        padding: 2px 6px;
        border-radius: 1px;
        &.type-poll {
          color: white;
          background-color: t(lm-color-pop);
        }
        &.type-funnel {
          color: white;
          background-color: t(lm-color-pop);
        }
      }
    }
  }
  .proposal-title {
    @include themed() {
      color: t("lm-font-color-primary");
      font-size: t("lm-font-size-xlarge");
      font-weight: t("lm-font-weight-bold");
      padding: 0 0 t("lm-space-small") 0;
      display: flex;
      align-items: center;
    }
    @include mobileScreenOnly() {
      display: none;
    }
  }
  .proposal-time-and-location {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include themed() {
      margin: t("lm-space-xs") 0;
    }
    @include mobileScreenOnly() {
      flex-direction: column;
    }
    & > span {
      @include with-icon();
      padding-left: 20px;
      left: 0;
    }
    .proposal-duration {
      @include themed() {
        padding-right: t("lm-space-medium");
      }
      &::before {
        content: "\e8b5";
        left: 0;
      }
    }
    .proposal-location {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex: 1;
      overflow-y: hidden;
      text-overflow: ellipsis;
      @include smScreenAndSmaller() {
        max-width: 250px; // because ellipsis need px dimensions...
      }
      &,
      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .text-block {
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        // display: inline-flex;
        overflow: hidden;
      }
      &::before {
        content: "\e0c8";
        left: 0;
      }
      .conference {
        display: inline-flex !important;
        svg {
          display: none;
        }
      }
    }
    .proposal-location-text{
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      .text-block {
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        // display: inline-flex;
        overflow: hidden;
      }
       &::before {
        content: "\e0c8";
        left: 0;
      }
    }
  }
  .proposal-invitees {
    @include with-icon();
    @include themed() {
      margin: t("lm-space-xs") 0;
    }
    &::before {
      content: "\e7fb";
    }
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      line-height: 1.5em;
    }
    .strong {
      @include themed() {
        font-weight: t("lm-font-weight-semibold");
      }
    }
    .optional {
      @include themed() {
        color: t("lm-font-color-secondary");
      }
      //  font-style: italic;
    }
    a {
      @include themed() {
        color: t("lm-font-color-primary");
        text-decoration: underline;
      }
    }
  }
  .proposal-notes {
    @include with-icon();
    @include themed() {
      margin: t("lm-space-xs") 0;
    }
    &::before {
      content: "\f1c6";
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;
    a {
      @include themed() {
        text-decoration: underline;
        color: t(lm-font-color-primary);
        &.hide {
          margin-top: t(lm-space-small);
        }
      }
    }
  }
  .proposal-hold-slots {
    @include with-icon();
    @include themed() {
      margin: t("lm-space-xs") 0;
      font-weight: t(lm-font-weight-semibold);
    }
    &::before {
      font-family: Material Icons Outlined;
      content: "\e897";
    }
  }
  .proposal-important {
    @include with-icon();
    @include themed() {
      margin: t("lm-space-xs") 0;
      font-weight: t(lm-font-weight-semibold);
    }
    &::before {
      // font-family: Material Icons Outlined;
      content: "\e645";
    }
  }
}
.lm-guests-popup {
  @include themed() {
    div.section {
      margin-bottom: 10px;
      > div:not(:last-child) {
        font-weight: t(lm-font-weight-semibold);
      }
    }
  }
}
.lm-guests-popup-title {
  min-width: 350px;
  @include mobileScreenOnly() {
    min-width: 200px;
  }
  @include themed() {
    .close-button {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}
