.no-event{
  width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* align-content: space-around; */
    flex: 1 1;
    padding: 10px;
  .error-message{
    padding: 50px;
    font-size: 22px;
    font-weight: 500;
  }
}