@import "../../styles/themes";
@import "../../styles/screens";
$transition-duration: 0.4s;
$transition-easing: cubic-bezier(0.23, 1, 0.32, 1);
// $bounce-easing: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$bounce-easing: cubic-bezier(0.175, 0.885, 0.32, 1.175);
$closed-width: 200px;
$closed-height: 80px;
$opened-width: 300px;
$opened-height: 150px;
.simple-flip-btn-container,
.simple-flip-btn {
	display: block;
	//position: absolute;
	//z-index: 9999;

	transition: width $transition-duration $transition-easing, height $transition-duration $transition-easing,
		transform $transition-duration $bounce-easing;
	@include mobileScreenOnly {
		transition: unset;
	}
	transform-style: preserve-3d;
	transform-origin: 50% 50%;
	text-align: center;
	// width: $closed-width;
	// height: $closed-height;
}
.simple-flip-btn-container{
  position: fixed;
    top: 0;
    left: 0;
    // bottom: 0;
    // right: 0;
    z-index: 9000;
    overflow:visible;
}
.simple-flip-btn-front {
	position: absolute;
	opacity: 1;
	display: block;
	width: 100%;
	height: 100%;
	line-height: $closed-height;
	background-color: #f44336;
	color: #fff;
	// cursor: pointer;
	backface-visibility: hidden;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	transition: background-color 0.15s ease, line-height $transition-duration $transition-easing;
}

.simple-flip-btn-back {
	backface-visibility: hidden;
	box-sizing: border-box;
	cursor: initial;
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 0px;
	//height: 0px;
	background-color: #efefef;
	@include themed() {
		background-color: t(lm-background-proposal-side-panel);
		border-radius: t(lm-border-radius-normal);
	}
	color: #fff;
	transform: translateZ(-2px) rotateX(180deg);
	overflow: hidden;
	transition: all $transition-duration ease;
	align-items: center;
	justify-content: space-around;
	p {
		margin-top: 14px;
		// margin-bottom: 25px;
		padding: 0 20px;
	}
	.actions {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		align-content: space-around;
		button {
			margin: 0 5px;
			flex-basis: 1;
			border-radius: 40px;
		}
		padding-bottom: 12px;
	}
	button {
		padding: 8px 20px;
		// width: 30%;
		margin: 0 5px;
		background-color: transparent;
		border: 0;
		// border-radius: 20px;
		border: 2px solid white;
		font-size: 16px;
		// cursor: pointer;
		font-family: inherit;
		-webkit-appearance: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		transition: background-color 0.15s ease;
	}

	button:focus {
		outline: 0;
	}

	button.yes {
		// padding: 12px 20px;
		@include themed() {
			background-color: t(color-robineggblue);
			border: 2px solid t(color-robineggblue);
		}

		color: #fff;

		&:hover {
			cursor: pointer;
			color: black;
			@include themed() {
				background-color: white;
				border: 2px solid white;
			}
		}
	}

	button.no {
		@include themed() {
			color: white; //t(color-robineggblue);
		}
		&:hover {
			cursor: pointer;
			color: black;
			@include themed() {
				background-color: white;
				border: 2px solid white;
			}
		}
	}
}

.simple-flip-btn[data-direction="left"] .simple-flip-btn-back,
.simple-flip-btn[data-direction="right"] .simple-flip-btn-back {
	transform: translateZ(-10px) rotateY(180deg);
}

.simple-flip-btn.is-open {
	width: $opened-width;
	height: $opened-height;

	.simple-flip-btn-front {
		pointer-events: none;
		opacity: 0;
		width: 0;
		//height: 0;
		line-height: $opened-height;
	}

	.simple-flip-btn-back {
		box-shadow: 0 8px 25px rgba(0, 0, 0, 0.4);
		width: $opened-width;
		min-height: $opened-height;
	}
}
$perspective: 2000px;
.simple-flip-btn[data-direction="top"].is-open {
	transform: perspective($perspective) rotateX(180deg);
}

.simple-flip-btn[data-direction="right"].is-open {
	transform: perspective($perspective) rotateY(180deg);
}

.simple-flip-btn[data-direction="bottom"].is-open {
	transform: perspective($perspective) rotateX(-180deg);
}

.simple-flip-btn[data-direction="left"].is-open {
	transform: perspective($perspective) rotateY(-180deg);
}
