@import "../../../styles/themes";
@import "../../../styles/_constants";

.settings-tools {
  .tool-connector {
    @include themed() {
      padding: t(space-medium);
      margin-top: t(space-medium);
    }
    .tool-connector-details {
      // @include themed() {
      //   margin-bottom: t(space-medium);
      // }
      .tool-connector-details-title {
        display: flex;
        align-items: center;
        @include themed() {
          font-size: t(header-font-size);
          font-weight: t(font-bold);
          margin-bottom: t(space-small);
          font-weight: t(lm-font-weight-bold);
        }
        svg {
          @include themed() {
            width: t(avatar-pills-font-size-big);
            height: t(avatar-pills-font-size-big);
            margin-right: t(space-small);
          }
        }
      }
      .tool-connector-details-desc {
        line-height: 24px;
        @include themed() {
          font-size: t(lm-font-size-medium);
        }
      }
    }
    .done-button {
      @include themed() {
        margin-top: t(space-medium);
      }
      .MuiButton-label > svg {
        margin-right: -6px;
        padding: 0;
      }
    }
    &.connected {
      display: flex;
      align-items: center;
      @include mobileScreenOnly() {
        flex-direction: column;
      }
      @include themed() {
        box-shadow: t(lm-shadow-normal);
        border: 1px solid t(lm-border-main-color);
        border-radius: t(lm-border-radius-normal);
      }
      .done-button {
        @include themed() {
          margin-top: 0;
        }
      }
      .tool-connector-details {
        flex-grow: 1;
      }
    }

    &.withoptions{
      flex-direction: column;
      &>div{
        display:flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
