@import "../../../styles/themes";
@import "../../../styles/constants";
@import "../../../styles/_screens.scss";
.create-event-form > .form-content > .period-v2.period-202307 {
  padding-top: 30px;
  @include themed() {
    .progress-label {
      margin-bottom: 10px;
    }
    .period-cta {
      padding-top: 0;
    }
    .period-cta.poll-or-funnel {
      flex-direction: column;
      .MuiFormGroup-root {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: unset;
        .cta-poll {
          width: 48%;
          @include smScreenAndSmaller {
            width: 100%;
            .cta-illus {
              display: none;
            }
          }
          align-items: flex-start;
          padding: 20px 0 0 0;
          .cta-subtitle {
            display: none;
          }
          .cta-title label {
            @include themed {
              color: t(lm-font-color-primary);
              span {
                font-weight: t(lm-font-weight-bold) !important;
              }
            }
          }
          .cta-desc {
            text-align: left;
            margin-top: 0;
          }
          &:hover {
            box-shadow: unset;
            transform: unset;
          }
        }
        @include smScreenAndSmaller() {
          flex-direction: column;
          .cta-poll {
            width: unset;
          }
        }
      }
    }
  }
  .period-title {
    text-transform: none;
  }
}
