@import "../styles/constants.scss";
@import "../styles/_themes.scss";
.donepicker-date {
  padding: 4px 12px 6px;
  @include mobileScreenOnly() {
    padding: 8px 6px;
  }
  min-height: 36px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  @include lgScreenAndSmaller {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  line-height: 1em;
  position: relative;
  transition: all 0.5s ease;
  width: calc(100% / 7);
  box-sizing: border-box;
  @include themed() {
    border-bottom: t(lm-border-heatmap-grid-hour);
  }
  $height-separator: 12px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: $height-separator;
    width: 1px;
    @include themed() {
      border-left: t(lm-border-heatmap-grid-hour);
    }
  }
  &:last-child {
    &::after {
      content: "";
      position: absolute;
      right: 0px;
      bottom: 0;
      height: $height-separator;
      width: 1px;
      @include themed() {
        border-right: t(lm-border-heatmap-grid-hour);
      }
    }
  }
  @include themed() {
    font-size: t(lm-font-size-medium);
    @include mobileScreenOnly {
      font-size: t(lm-font-size-medium);
    }
    .day {
      flex: 1;
      font-size: t(lm-font-size-small);
      letter-spacing: 2px;
      @include mobileScreenOnly {
        font-size: t(lm-font-size-xsmall);
      }
      line-height: 1em;
      margin-left: 5px;
      @include mobileScreenOnly {
        margin-left: 0px;
      }
      margin-bottom: 2px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }

  .hasEvents {
    // width: 6px;
    // height: 6px;
    position: absolute;
    bottom: 3px;
    left: 10px;
    background-color: $color-robineggblue;
    border-radius: 50%;
  }
  .hasPendingEvents {
    position: absolute;
    bottom: 3px;
    right: 10px;
    background-color: $color-bittersweet;
    border-radius: 50%;
  }

  &.unselectable {
    color: $dp-unactive_color;
    pointer-events: none;
    font-weight: 100;
  }
  border-radius: 0;
  // transition: all .5s;
  &.hovered {
    @include themed() {
      background-color: t(lm-slot-background-color);
      border-radius: 12px 12px 0 0;
      .action {
        width: 20px;
        height: 20px;
        color: t(lm-font-color-secondary);
        color: t(lm-color-pop);
        @include lgScreenAndSmaller {
          display: none;
        }
      }
    }
    &.unselectable {
      background-color: unset!important;
    }
  }
  &.today {
    color: $color-robineggblue;
    font-weight: 600;
    .hasEvents {
      background-color: white;
    }
  }
  & > .date {
    color: green;
  }
}
