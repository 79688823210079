@import "../../../../styles/themes";
@import "../../../../styles/screens";

$newDarkerPurple: #36279d;

.v202212.done-calendar-selector {
  @include themed() {
    width: 80%;
    margin: 0 auto;
    margin: t(lm-space-medium) 0;
    @include mdScreenAndSmaller {
      width: 100%;
    }
    .container {
      width: 100%;
      border: none;
      padding: 0;
      display: flex;
      align-items: stretch;
      .providers {
        width: 100%;
        margin-top: 0;
        @include smScreenAndSmaller {
          flex-direction: column;
          align-items: stretch;
          button:first-child {
            margin-right: 0;
            margin-bottom: 1em;
          }
        }
        button {
          flex: 1;
          box-shadow: none;
          background-color: $newDarkerPurple;
          &:hover {
            background-color: t(lm-color-pop);
          }
          color: white;
          display: flex;
          justify-content: flex-start;
          padding: 6px !important;
          .MuiButton-label {
            box-sizing: border-box;
          }
          .MuiButton-startIcon {
            padding: 4px;
            background-color: white;
            border-radius: 2px;
            box-sizing: border-box;
            margin-left: 0!important;
            svg {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}
