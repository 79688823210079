// Mobile phones
// Small tablets and large smartphones (landscape view)
$screen-sm-upto: 575px;
$screen-sm: 576px;
// Small tablets (portrait view)
$screen-md-upto: 768px;
// Tablets and small desktops
$screen-lg-upto: 992px;
// Large tablets and desktops
$screen-xl-from: 1200px;

$app-max-width: 1076px;

// Mobile devices
@mixin mobileScreenOnly {
    @media (max-width: 575px) {
        @content;
    }
}

// Small devices
@mixin smScreenOnly {
    //@media (min-width: calc(#{$screen-sm-upto})) and (max-width: #{$screen-md-upto} ) {
    @media (min-width: 576px) and (max-width: 767px) {
        @content;
    }
}

@mixin smScreenAndSmaller {
    @media (max-width: 767px) {
        @content;
    }
}

// Medium devices
@mixin mdScreenOnly {
    @media (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}
@mixin mdScreenAndSmaller {
    @media (max-width: 991px) {
        @content;
    }
}
@mixin mdScreenAndLarger {
    @media (min-width: 991px) {
        @content;
    }
}

// Large devices
@mixin lgScreenOnly {
    @media (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}

@mixin lgScreenAndSmaller {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin lgScreenAndLarger {
    @media (min-width: 1200px) {
        @content;
    }
}

// Extra large devices
@mixin xlScreenOnly {
    @media (min-width: #{$screen-xl-from}) {
        @content;
    }
}
