@import "../../styles/_themes.scss";
@import "../../styles/_screens.scss";

.event-proposal-slots-resume {
  // flex: 1;
  display: flex;
  flex-direction: column;
  // overflow: auto !important;
  padding-left: 36px;
  @include mobileScreenOnly(){
    padding-left: 0;
  }
}
.proposal-resume-list {
  // flex: 1;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  &.no-vote {
    padding: 30px;
    font-size: 20px;
    font-weight: 500;
  }
}
.proposal-items {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  .resume-slots {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    margin-right: 14px;
    @include mobileScreenOnly(){
      margin-right: 0;
    }
  }
  .resume-slots > .slot {
    box-sizing: border-box;
    margin-bottom: 4px;
    border-radius: 3px;
    border: 1px solid #bababa;
    display: flex;
    width: 100%;
    padding: 8px 20px;
    margin-right: 0;
    .answers {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      .avatar-answer {
        margin-right: 5px;
        box-sizing: border-box;
      }
      .more-answers {
        min-width: 26px;
        height: 26px;
        border-radius: 26px;
        line-height: 26px;
        text-align: center;
        @include themed() {
          background-color: t(color-light-pop-opacity);
        }
      }
    }

    .date {
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
.proposal-day-date.proposal-resume {
  align-self: flex-start;
}
