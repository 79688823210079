@import "../../../styles/themes";
@import "../../../styles/screens";

$transition-duration: 0.4s;
$transition-easing: cubic-bezier(0.23, 1, 0.32, 1);
// $bounce-easing: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$bounce-easing: cubic-bezier(0.175, 0.885, 0.32, 1.175);
$closed-width: 200px;
$closed-height: 80px;
$opened-width: 300px;
$opened-height: 150px;

.proposal-page {
	perspective: 2000px;
	.slots {
		@include themed() {
			.unknwon-email {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-content: center;
				align-items: center;
				justify-content: start;
				.MuiFormControl-root.done-input {
					max-width: 320px;
					flex-grow: 0;
					margin-right: 20px;
					margin-bottom: 0px;
					margin-top: 0px;
					.MuiInputBase-input {
						scroll-margin: 20px;
					}
				}
				&.padding-bottom {
					padding-bottom: 20px;
				}
				.connection {
					margin-top: 0;
					//   display: flex;
					//   flex-direction: column;
					flex-wrap: nowrap;
					padding: 5px;
					border: none;
					align-items: center;
					justify-content: center;
					.title {
						padding: 0 10px;
						max-width: 150px;
						font-size: 14px;
						cursor: pointer;
						&:hover {
						}
					}
					.link {
						font-size: 14px;
						color: black;
						text-decoration: underline;
						font-weight: 600;
						white-space: nowrap;
					}
				}
				// on small screens
				@include smScreenAndSmaller {
					flex-direction: column;
					.MuiFormControl-root.done-input {
						max-width: unset;
						margin: 0 20px;
					}
					.connection {
						margin-top: 5px;
						.title {
							max-width: 100%;
						}
					}
				}
			}
		}
		.LMTooltip-children {
			flex: 1;
			justify-content: flex-end;
			&:hover {
				.inner-mark {
					// background-color: #0000000a;
					@include themed() {
						// border: 1px solid t(lm-color-pop);
						// background-color: #0000000a;
						// color: white;
					}
				}
			}
			.mark {
				//   position: absolute;
				//   top: -10px;
				//   right: -10px;
				margin-left: 5px;
				text-align: center;
				display: flex;
				@include themed() {
					color: t(lm-color-pop);
					font-weight: t(lm-font-weight-semibold);
				}
				font-size: 14px;
				.inner-mark {
					// width: 30px;
					// height: 30px;
					// @include themed(){
					//   background-color: t('color-robineggblue');
					// }
					// border-radius: 50%;
					// font-family: Arial, Helvetica, sans-serif;
					border: 1px solid transparent;
					padding: 1px 3px 1px 0;
					border-radius: 0px;
					display: flex;
					justify-content: center;
					align-items: center;
					svg {
						width: 18px;
						height: 18px;
					}
				}
			}
		}
	}
}
.slot {
	position: relative;
	.showHand {
		display: flex;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: center;
	}
	@include themed() {
		.optionals {
			position: absolute;
			top: -6px;
			right: -6px;
			background-color: t("pop-color");
			color: white;
			padding: 2px 5px;
			min-width: 25px;
			.MuiSvgIcon-root {
				width: 15px;
				height: 15px;
			}
			.badge {
				font-size: 15px;
				font-weight: 700;
				margin-left: t("lm-space-xs");
			}
			// .MuiBadge-anchorOriginTopRightRectangle{
			//   transform: scale(0.7) translate(50%, -50%);
			// }
		}
	}
  &.best-choice .mark{
    position: relative;
    // &::before {
    //     content: "";
    //     width: 10px;
    //     height: 10px;
    //     position: absolute;
    //     background-color: green;
    //     border-radius: 50%;
    //     top: 3px;
    //     right: 2px;
    // }
    &::after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        @include themed() {
		    	background-color: t(color-robineggblue);
        }
        border-radius: 50%;
        top: 3px;
        right: 2px;
        opacity: 0;
        animation: sonarWave 0.9s ease-out infinite;
        animation-iteration-count: 3;
        animation-delay: 800ms;
        animation-fill-mode: forwards;
    }
    @keyframes sonarWave {
        from {
            opacity: 0.5;
        }
        to {
            transform: scale(4);
            opacity: 0;
        }
    }
  }
}
.flip-btn {
	display: block;
	position: absolute;
	z-index: 9999;

	transition: width $transition-duration $transition-easing, height $transition-duration $transition-easing,
		transform $transition-duration $bounce-easing;
	@include mobileScreenOnly {
		transition: unset;
	}
	transform-style: preserve-3d;
	transform-origin: 50% 50%;
	text-align: center;
	// width: $closed-width;
	// height: $closed-height;
}

.flip-btn-front {
	position: absolute;
	opacity: 1;
	display: block;
	width: 100%;
	height: 100%;
	line-height: $closed-height;
	background-color: #f44336;
	color: #fff;
	// cursor: pointer;
	backface-visibility: hidden;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	transition: background-color 0.15s ease, line-height $transition-duration $transition-easing;
}

.flip-btn-back {
	backface-visibility: hidden;
	box-sizing: border-box;
	cursor: initial;
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 0px;
	height: 0px;
	background-color: #efefef;
	@include themed() {
		background-color: t(lm-background-proposal-side-panel);
		border-radius: t(lm-border-radius-normal);
	}
	color: #fff;
	transform: translateZ(-2px) rotateX(180deg);
	overflow: hidden;
	transition: all $transition-duration ease;
	align-items: center;
	justify-content: space-around;
	p {
		margin-top: 14px;
		// margin-bottom: 25px;
		padding: 0 20px;
	}
	.actions {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		align-content: space-around;
		button {
			margin: 0 5px;
			flex-basis: 1;
			border-radius: 40px;
		}
		padding-bottom: 12px;
	}
	button {
		padding: 8px 20px;
		// width: 30%;
		margin: 0 5px;
		background-color: transparent;
		border: 0;
		// border-radius: 20px;
		border: 2px solid white;
		font-size: 16px;
		// cursor: pointer;
		font-family: inherit;
		-webkit-appearance: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		transition: background-color 0.15s ease;
	}

	button:focus {
		outline: 0;
	}

	button.yes {
		// padding: 12px 20px;
		@include themed() {
			background-color: t(color-robineggblue);
			border: 2px solid t(color-robineggblue);
		}

		color: #fff;

		&:hover {
			cursor: pointer;
			color: black;
			@include themed() {
				background-color: white;
				border: 2px solid white;
			}
		}
	}

	button.no {
		@include themed() {
			color: white; //t(color-robineggblue);
		}
		&:hover {
			cursor: pointer;
			color: black;
			@include themed() {
				background-color: white;
				border: 2px solid white;
			}
		}
	}
}

.flip-btn[data-direction="left"] .flip-btn-back,
.flip-btn[data-direction="right"] .flip-btn-back {
	transform: translateZ(-10px) rotateY(180deg);
}

.flip-btn.is-open {
	width: $opened-width;
	height: $opened-height;

	.flip-btn-front {
		pointer-events: none;
		opacity: 0;
		width: 0;
		height: 0;
		line-height: $opened-height;
	}

	.flip-btn-back {
		box-shadow: 0 8px 25px rgba(0, 0, 0, 0.4);
		width: $opened-width;
		height: $opened-height;
	}
}
$perspective: 2000px;
.flip-btn[data-direction="top"].is-open {
	transform: perspective($perspective) rotateX(180deg);
}

.flip-btn[data-direction="right"].is-open {
	transform: perspective($perspective) rotateY(180deg);
}

.flip-btn[data-direction="bottom"].is-open {
	transform: perspective($perspective) rotateX(-180deg);
}

.flip-btn[data-direction="left"].is-open {
	transform: perspective($perspective) rotateY(-180deg);
}
