@import "../../../../styles/themes";
@import "./common";

.timeline-item.uservote {
  @include timelineitem();
  @include with-icon("../../../../../assets/arrow-right.svg");
  @include themed() {
    @include with-line(1px solid t(lm-timeline-line-color));
  }
  @include themed() {
    font-size: t("lm-font-size-normal");
  }
  span {
    display: block;
    &.title {
      @include themed() {
        margin-bottom: t("lm-space-xs");
      }
    }
    &.title-more {
      @include themed() {
        font-size: t("lm-font-size-normal");
        color: t("lm-font-color-secondary");
      }
    }
  }
  .done-button {
    @include themed() {
      font-size: t("lm-font-size-small");
      margin: t("lm-space-xs") 0;
      padding: t("lm-space-xs") t("lm-space-small");
    }
  }
  &.active {
    
    @include with-icon("../../../../../assets/arrow-right.svg");
    @include themed() {
      @include with-line(1px solid t(lm-timeline-line-color));
    }
    .title {
      @include themed() {
        font-size: t("lm-font-size-medium");
        font-weight: t("lm-font-weight-bold");
      }
    }
  }
}
