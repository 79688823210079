@import "../../../../styles/themes";
.event-times {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    margin-left: 45px;
    // @include mobileScreenOnly {
    //     margin-left: 10px;
    // }
    @include lgScreenAndSmaller {
        margin-left: 0;
    }
    .dot {
        height: 100%;
    }
    .event-slots {
        pointer-events: all;
        &.non-interactive{
          pointer-events: none;
        }
        position: absolute;
        @include themed() {
            // background-color: t(lm-background-color-event);
            background-color: white;
        }
        &:hover {
            background-color: white;
        }
        .animated {
            position: relative;
            animation: appearin 0.3s;
            animation-iteration-count: 4;
            animation-fill-mode: backwards;
        }
        .eventTooltippopup {
            display: flex;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            overflow: hidden;
            max-width: 300px;
            @include mobileScreenOnly() {
                max-width: 200px;
            }
            .tooltip-location,
            .tooltip-title {
                display: block !important;
                text-overflow: ellipsis;
                overflow: hidden;
                max-height: 5.2em;
                flex-shrink: 0;
            }

            .tooltip-location {
                white-space: nowrap;
                > span > span {
                    display: block !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
            &.top,
            &.bottom,
            &.right,
            &.left {
                i {
                    display: none !important;
                }
            }
            // @Steph, I discovered that this is useless, it should have been '&.top', '&.bottom' etc.
            // .top {
            //     visibility: visible;
            //     opacity: 1;
            //     i {
            //         position: absolute;
            //         top: 100%;
            //         left: 50%;
            //         margin-left: -12px;
            //         width: 24px;
            //         height: 12px;
            //         overflow: hidden;
            //         &::after {
            //             content: "";
            //             position: absolute;
            //             width: 12px;
            //             height: 12px;
            //             left: 50%;
            //             transform: translate(-50%, -50%) rotate(45deg);
            //             box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
            //         }
            //     }
            // }
            // .bottom {
            //     visibility: visible;
            //     opacity: 1;
            //     top: calc(100% + 20px);
            //     transform: translate(-50%, 0);
            //     i {
            //         position: absolute;
            //         bottom: 100%;
            //         left: 50%;
            //         margin-left: -12px;
            //         width: 24px;
            //         height: 12px;
            //         overflow: hidden;
            //         &::after {
            //             content: "";
            //             position: absolute;
            //             width: 12px;
            //             height: 12px;
            //             left: 50%;
            //             transform: translate(-50%, 50%) rotate(45deg);
            //             box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
            //         }
            //     }
            // }
            // .left {
            //     visibility: visible;
            //     opacity: 1;
            //     top: 50%;
            //     left: -20px;
            //     -webkit-transform: translate(-100, -50%);
            //     transform: translate(-100%, -50%);
            //     i {
            //         position: absolute;
            //         bottom: calc(50% - 6px);
            //         left: calc(100% + 9px);
            //         margin-left: -12px;
            //         width: 24px;
            //         height: 12px;
            //         overflow: hidden;

            //         &::after {
            //             content: "";
            //             position: absolute;
            //             width: 12px;
            //             height: 12px;
            //             left: 0;
            //             top: 50%;
            //             transform: translate(-50%, -50%) rotate(-45deg);
            //             border: 1px solid #dca;
            //         }
            //     }
            // }
            // .right {
            //     visibility: visible;
            //     opacity: 1;
            //     top: 50%;
            //     left: 100%;
            //     -webkit-transform: translate(20px, -100%);
            //     transform: translate(20px, -50%);
            //     z-index: 9999;
            //     i {
            //         position: absolute;
            //         bottom: calc(50% - 6px);
            //         left: 0;
            //         margin-left: -12px;
            //         width: 24px;
            //         height: 12px;
            //         overflow: hidden;
            //         &::after {
            //             content: "";
            //             position: absolute;
            //             width: 12px;
            //             height: 12px;
            //             left: 1px;
            //             top: 50%;
            //             -webkit-transform: translate(50%, -50%) rotate(-45deg);
            //             transform: translate(50%, -50%) rotate(-45deg);
            //             border-top: 1px solid #dca;
            //             border-left: 1px solid #dca;
            //         }
            //     }
            // }
        }
        .event-heatmap-item {
            @include themed() {
                overflow: hidden;
                padding: 1px;
                height: 100%;
                box-sizing: border-box;
                border-radius: t(lm-border-radius-small);
                padding: t(lm-space-xs);
                color: t(lm-font-color-secondary);

                &.refused {
                    text-decoration: line-through;
                }
                cursor: default;
                .event-title {
                    white-space: unset;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    font-weight: 500;
                    overflow: hidden;
                    font-size: t(lm-font-size-small);
                    line-height: 1em;
                    @include mobileScreenOnly {
                        display: none;
                    }
                }
                .event-location {
                    display: none;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 10px;
                }
                &.duration-xs {
                    padding: 1px 2px 0 2px;
                    .event-title {
                        font-size: 8px;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }
                &.duration-s {
                    padding: 3px 2px;
                    .event-title {
                        font-size: 10px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                &.duration-m {
                    padding: 5px;
                    .event-title {
                        font-size: 11px;
                    }
                }
                &.past {
                    opacity: 0.25;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .evttooltip:hover {
        .eventTooltippopup {
            // display: flex;
            visibility: visible;
            opacity: 1;
            &.bottom {
                margin-top: 0;
            }
        }
    }
}
