@import "../../../../styles/themes";
@import "./common";

.event-status {
  &:not(.lm-guests-popup) {
    @include timelineitem();
    @include with-icon("../../../../../assets/waiting.svg");
  }
  @include themed() {
    font-size: t("lm-font-size-normal");
  }
  &.active {
    @include themed() {
      font-size: t("lm-font-size-medium");
      font-weight: t("lm-font-weight-bold");
    }
  }
  // &:not(.active) {
    @include themed() {
      @include with-line(1px solid t(lm-timeline-line-color));
    }
  // }
  &.sent_with_no {
    &::before {
      content: " ";
      background-image: url("../../../../../assets/waiting-with-no.svg");
      opacity: 1;
      @include themed() {
        background-color: t("lm-color-background");
      }
    }
  }
  &.complete {
    &::before {
      content: " ";
      background-image: url("../../../../../assets/success.svg");
      opacity: 1;
      @include themed() {
        background-color: t("lm-color-background");
      }
    }
  }
  &.complete_with_no {
    &::before {
      content: " ";
      background-image: url("../../../../../assets/success-with-no.svg");
      opacity: 1;
      @include themed() {
        background-color: t("lm-color-background");
      }
    }
  }
  &.canceled {
    &::before {
      content: " ";
      background-image: url("../../../../../assets/success-no.svg");
      opacity: 1;
      @include themed() {
        background-color: t("lm-color-background");
      }
    }
  }
  &.unknwon {
    &::before {
      content: " ";
      opacity: 1;
      background-image: url("../../../../../assets/not_available.svg");
      @include themed() {
        background-color: t("lm-color-background");
      }
    }
  }
  .infos {
    @include themed() {
      font-weight: normal;
      font-size: t("lm-font-size-small");
      margin-top: t("lm-space-small");
    }
  }
  .invitee-response {
    display: block;
    // @include with-icon("../../../../../assets/question-mark.svg");
    @include themed() {
      font-size: t("lm-font-size-small");
      font-weight: normal;
      margin: t("lm-space-xs") 0;
    }
    &.yes {
      @include with-material-icon("\e876");
      @include with-colored-icon("lm-background-has-voted-light");
    }
    &.no {
      @include with-material-icon("\e14c");
      @include with-colored-icon("color-deepchesnut");
    }
    &.maybe {
      @include with-material-icon("\e876");
      @include with-colored-icon("color-peach");
    }
    &.unknown {
      @include with-material-icon("\e8b5");
      @include with-colored-icon("lm-font-color-secondary");
    }
    &.no-mail {
      @include with-material-icon("\e8fd");
      @include with-colored-icon("lm-font-color-secondary");
      &::before {
        top: 1px;
      }
    }
  }

  &.lm-guests-popup {
    .invitee-response {
      left: 0;
      @include themed() {
        font-size: t(lm-font-size-normal);
      }
      &::before {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
  .unknowns {
    margin-top: 10px;
    & > div {
      @include themed() {
        font-size: t("lm-font-size-normal");
      }
    }
  }
}
