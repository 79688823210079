@import "./styles/constants";
.avatar-state {
    position: relative;
    .Avatar {
        //   border: 2px solid $color-whitesmoke;
        padding: 2px;
        svg {
            width: 20px;
            height: 20px;
        }
    }
    .decorator {
        width: 56px !important;
        height: 56px !important;
        position: absolute;
        top: 0;
        left: 0;
        color: $color-robineggblue;
        svg {
            circle {
                stroke-width: 2;
            }
        }
        &.small {
            top: -1px !important;
            left: -1px !important;
            width: 34px !important;
            height: 34px !important;
        }
        &.big {
            width: 264px !important;
            height: 264px !important;
        }
    }
    &.yes {
        .Avatar {
            border: 2px solid $color-robineggblue;
            padding: 2px;
        }
    }
    &.maybe {
        .Avatar {
            border: 2px solid $color-peach;
            padding: 2px;
        }
    }
    &.no {
        .Avatar {
            border: 2px solid $color-carminepink;
            padding: 2px;
        }
    }
}
