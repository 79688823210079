@import "../../../../styles/themes";
@import "./common";

.created {
  @include timelineitem();
  @include with-icon("../../../../../assets/created.svg");
  @include themed() {
    @include with-line(1px solid t(lm-timeline-line-color));
  }
  @include themed() {
    font-size: t("lm-font-size-normal");
  }
  &.owner {
    .as-owner {
      @include themed() {
        margin: t("lm-space-xs") 0;
      }
    }
  }
  &.invitee {
    .as-invitee {
      @include themed() {
        margin: t("lm-space-xs") 0;
      }
    }
  }
  .as-invitee,
  .as-owner {
    @include themed() {
      font-weight: t(lm-font-weight-semibold);
    }
  }
  .created-at {
    @include themed() {
      font-size: t("lm-font-size-small");
      text-transform: uppercase;
    }
  }
  .show-details {
    display: none;
    .done-button {
      min-width: unset;
    }
    @include mobileScreenOnly() {
      margin-top: 10px;
      display: block;
    }
  }
}
