@import "../../../styles/themes";
@import "../../../styles/screens";

.billing-promo-card {
  display: flex;
  flex-direction: column;
  @include themed() {
    padding: t(lm-space-medium);
  }
  @include smScreenAndSmaller {
    @include themed() {
      padding: t(lm-space-medium);
    }
  }
  @include themed() {
    border: t(form-input-border-default);
    -webkit-box-shadow: t(lm-shadow-normal);
    box-shadow: t(lm-shadow-normal);
    border-radius: t(common-box-border-radius);
  }
  text-align: center;
  & > .title {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobileScreenOnly {
      flex-direction: column;
    }
    @include themed() {
      color: t(lm-font-color-secondary);
      font-size: t(lm-font-size-small);
      font-weight: t(lm-font-weight-semibold);
      margin: t(lm-space-small) t(lm-space-medium);
      @include smScreenAndSmaller {
        margin: t(lm-space-xs) t(lm-space-small);
      }
    }
    text-transform: uppercase;
    letter-spacing: 0.19em;
    &.offer {
      display: flex;
      flex-direction: column;
      @include themed() {
        font-weight: t(lm-font-weight-normal);
      }

      text-transform: unset;
      letter-spacing: unset;
      @include themed() {
        color: t(lm-font-color-primary);
        font-size: t(lm-font-size-normal);
        margin: t(lm-space-medium) 0;
        @include smScreenAndSmaller {
          margin: t(lm-space-small) 0;
        }
      }
      .bold {
        @include themed() {
          font-weight: t(lm-font-weight-semibold);
        }
      }
      .barred {
        text-decoration: line-through;
        @include themed() {
          color: t(lm-font-color-secondary);
          font-weight: t(lm-font-weight-light);
          font-size: t(lm-font-size-normal);
          margin-right: t(lm-space-small);
        }
      }
    }
  }
  hr {
    @include themed() {
      border: 0;
      border-top: 1px solid t(lm-border-main-color);
      margin-top: t(lm-space-normal);
      padding-bottom: t(lm-space-medium);
      @include smScreenAndSmaller {
        padding-bottom: t(lm-space-xs);
      }
    }
    width: 50%;
  }
  ul {
    // width: 60%;
    align-self: flex-start;
    text-align: left;
    margin: auto;
    @include themed() {
      padding: t(lm-space-xs);
      font-size: t(lm-font-size-normal);
    }
    list-style: none;
    @include mobileScreenOnly() {
      @include themed() {
        padding: 0;
      }
    }
    li {
      display: flex;
      align-items: center;
      @include themed() {
        margin: t(lm-space-xs) 0px;
      }
      svg {
        @include themed() {
          margin-right: t(lm-space-xs);
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .premium {
    @include themed() {
      margin-top: t(lm-space-small);
      margin-bottom: t(lm-space-small);
    }
  }
  .more {
    @include themed() {
      color: t(lm-font-color-secondary);
      margin: t(lm-space-small) 0;
    }
    font-style: italic;
  }
}
