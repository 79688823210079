@import "../../../../styles/themes";
.timeline {
    text-align: left;

    @include themed() {
        color: t("lm-font-color-primary");
        padding-left: t("space-small");
        margin: 15px;

        .timeline-item {
            margin-bottom: 4px;
            padding-right: t(lm-space-medium);
            .title {
                font-weight: t(lm-font-weight-semibold);
            }
            .invitee {
                font-size: 13px;
                color: t(lm-font-color-secondary);
            }
            a {
                font-size: t(lm-font-size-normal);
                font-weight: t(lm-font-weight-normal);
                color: t(lm-font-color-primary);
                text-decoration: underline;
            }
        }

        & >.timeline-item:last-child {
            &::after {
              display: none;
            }
          }
    }
}
