.overlapp {
  .overlapp-item {
    select {
      padding: 18.5px 14px;
      border: 1px solid #dadada;
      border-radius: 4px;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    .units {
      padding-left: 6px;
    }
  }
}
