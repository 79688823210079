@import "../../../../styles/_themes.scss";
@import "../../../../styles/_screens.scss";

.heatmap-drawing {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  margin-left: 45px;
  // margin-top: 8px;

  &.dragging {
    cursor: grabbing !important;
  }
  &.resizing {
    cursor: nwse-resize !important;
  }
  &.resizing-ctrl {
    cursor: row-resize !important;
  }
  &.resizing-shift {
    cursor: col-resize !important;
  }
  .container {
    cursor: crosshair;
    box-sizing: border-box;
    position: relative;
    left: 12px;
    width: calc(100% - 12px);
    height: 100%;
    @include themed() {
      font-size: t(secondary-font-size);
      background-color: t(lm-slot-background-color);
      border-radius: t(lm-border-radius-small);
      box-shadow: t(lm-shadow-slot1), t(lm-shadow-slot2);
      border: 1px solid t(color-robineggblue);
    }
  }
  .simple-hand-drawing {
    pointer-events: all;
    position: absolute;
    &.tiny {
      .resizers {
        display: none;
      }
    }
    .container {
      .resizers {
        // pointer-events: all;
        position: absolute;
        width: 100%;
        height: 100%;
        & > div {
          position: absolute;
          background-color: transparent;
          z-index: 10;
        }
        .resize-top {
          cursor: row-resize;
          top: 0;
          left: 0;
          width: 100%;
          height: 5px;
        }
        .resize-right {
          cursor: col-resize;
          top: 0;
          right: 0;
          width: 5px;
          height: 100%;
        }
        .resize-bottom {
          cursor: row-resize;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
        }
        .resize-left {
          cursor: col-resize;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
        }
      }
    }

    &.inactive {
      pointer-events: none;
      &:hover {
        z-index: 0;
      }
      button {
        pointer-events: none;
      }
    }

    // @include mobileScreenOnly(){
    //   pointer-events: none;
    // }

    @include themed() {
      &.is-busy {
        background-color: t(lm-slot-background-color-busy);
        box-shadow: t(lm-shadow-slot1-busy), t(lm-shadow-slot2-busy);
      }
      // position: relative;
      .findFreeSlots {
        background-color: #ffffffcc;
        position: absolute;
        margin: 6px;
        // left: 0;
        right: 0px;
        bottom: 0px;
        font-weight: t(lm-font-weight-normal);
        padding: t(lm-space-xs) t(lm-space-small);
        font-size: t(lm-font-size-xsmall);
      }
    }
    &.shadow {
      // opacity: 0.6;
      .container {
        box-sizing: border-box;
        @include themed() {
          font-size: t(secondary-font-size);
          // background-color: t(lm-slot-background-color);
          // background-color: #f1f1f188; //rgba(101, 153, 255, 0.1); //#70cbff33;
          border-radius: t(lm-border-radius-small);
          box-shadow: none;
          // border: 1px solid #7060df; //t(lm-border-color-slot-shadow); //t(color-robineggblue);
        }
        position: relative;

        > span {
          position: absolute;

          // z-index: 1;
          left: 0px;
          background: #ffffffcc; //cc;
          @include themed() {
            // box-shadow: 0px 0px 15px 0px t(lm-border-main-color);
            // border: 1px solid t(lm-border-main-color);
            border-radius: 2px; //10px; // 10px 0 0;
            color: t(lm-font-color-secondary);
            font-size: t(lm-font-size-xsmall);
            //font-weight: t(lm-font-weight-semibold);
          }
          padding: 4px 6px;
          margin: 8px 0;
          &.start {
            bottom: 100%;
          }
          &.end {
            top: 100%;
          }
        }
        &.invalid-shadow {
          span {
            @include themed() {
              color: t(color-bittersweet);
            }
          }
        }
        .proposal-title{
          padding: 0px 2px;
          font-size: 10px!important;
        }
      }
    }
    font-size: 13px;
    &.grab {
      .container {
        @include themed() {
          cursor: grabbing !important;
          box-shadow: t(lm-shadow-medium);
          -webkit-box-shadow: t(lm-shadow-medium);
          -moz-box-shadow: t(lm-shadow-medium);
          // background-color: t(lm-slot-background-color) !important;
          background-color: t(lm-selection-background-color) !important;

          @include mobileScreenOnly() {
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            background-color: t($color-robineggblue) !important;
          }
        }
        button {
          pointer-events: none;
        }
      }
    }
    &.current {
      pointer-events: none;
      .container {
        @include themed() {
          color: t("lm-font-color-slot");
          font-weight: t("lm-font-weight-semibold");
          // padding: t(lm-space-xs);
          // padding-top: 4px;
          font-size: 12px !important;
          user-select: none;
        }
        &.selector {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          @include themed() {
            font-size: 15px;
            font-weight: bold;
            background-color: t(lm-selection-background-color);
            border-radius: 0;
            border: 1px dashed black;
          }
        }
      }
    }
    // &.is-busy{
    //   @include themed() {
    //     background-color: t(color-bittersweet);
    //   }
    // }
    .proposal {
      width: 100%;
      height: 100%;
      background-color: transparent;
      align-items: baseline;
      border: none;
      @include themed() {
        padding: 2px;
        padding-top: 0px;
      }
      font-size: 9px !important;
      user-select: none;
      .proposal-title {
        pointer-events: none;
        height: 100%;
        padding-right: 22px;
        overflow: hidden;
        &.is-busy {
          @include themed() {
            font-style: italic;
            color: t(lm-font-color-slot-busy);
          }
        }
      }
      button {
        &:hover {
          background-color: white;
        }
        top: -3px;
        right: -1px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
      &.small {
        button {
          top: -5px;
        }
      }
    }
    &.deletable {
      .container {
        @include themed() {
          transition: bacground-color ease 0.5s;
          box-shadow: 1px -1px 17px -1px transparentize($color-deepchesnut, 0.25);
          -webkit-box-shadow: 1px -1px 17px -1px transparentize($color-deepchesnut, 0.25);
          -moz-box-shadow: 1px -1px 17px -1px transparentize($color-deepchesnut, 0.25);
          background-color: transparentize($color-deepchesnut, 0.55) !important;
          .proposal {
            border-color: $color-deepchesnut !important;
            @include mobileScreenOnly {
              button {
                opacity: 1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                svg {
                  width: 32px;
                  height: 32px;
                  fill: $color-deepchesnut;
                }
              }
            }
          }
        }
      }
    }

    .handlers {
      position: absolute;
      display: none;
      animation: pop 0.1s linear 1;

      // opacity: 0;
      // transform: translateY(10px);
      // transition: opacity 200ms linear;

      &.visible {
        display: block;
      }
      &.bottom {
        height: 5px;
        width: 100%;
        bottom: 0;
        cursor: n-resize;
      }
      &.right {
        height: 100%;
        width: 5px;
        right: 0;
        top: 0;
        cursor: e-resize;
      }
      &.angle {
        // height: 24px;
        // width: 24px;
        height: 8px;
        width: 8px;
        // border-radius: 24px;
        border-radius: 1px;

        // right: -12px;
        // bottom: -12px;
        right: -4px;
        bottom: -4px;
        cursor: nwse-resize;
        @include themed() {
          background-color: t(color-robineggblue);
          background-color: white;
          border: 1px solid t(color-robineggblue);
        }
        // background-color: #00cfc0;
        // &::before {
        //   content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9IndoaXRlIiB3aWR0aD0iMThweCIgaGVpZ2h0PSIxOHB4Ij48cmVjdCBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiLz48cG9seWdvbiBwb2ludHM9IjIxLDExIDIxLDMgMTMsMyAxNi4yOSw2LjI5IDYuMjksMTYuMjkgMywxMyAzLDIxIDExLDIxIDcuNzEsMTcuNzEgMTcuNzEsNy43MSIvPjwvc3ZnPg==");
        //   margin: auto;
        //   display: block;
        //   position: absolute;
        //   top: 0px;
        //   left: 3px;
        //   transform: scaleY(-1);
        // }
      }
      &.angle-top {
        right: unset;
        bottom: unset;
        top: -4px;
        left: -4px;
      }
    }
    z-index: 10;
    &:hover {
      &:not(.inactive) {
        z-index: 1000;
      }
      .proposal {
        button {
          display: block;
        }
      }
      .handlers {
        display: block;
        opacity: 1;
        transform: translateY(0px);
        // @include mobileScreenOnly(){
        //   display: none;
        // }
      }
      &.tiny {
        .handlers {
          display: none;
        }
      }
    }
    &.grab.touch {
      .proposal {
        button {
          // display: block;
        }
      }
    }
  }

  &.inactive-slots {
    *,
    .simple-hand-drawing {
      pointer-events: none !important;
    }
  }
}
