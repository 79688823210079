@import "../../../styles/themes";
@import "../../../styles/constants";
@import "../../../styles/_screens.scss";
@mixin with-icon {
  padding-left: 10px;
  position: relative;
  left: 10px;
  &::before {
    font-family: "Material Icons";
    position: absolute;
    left: -10px;
    font-size: 15px;
    line-height: 15px;
    top: 2px;
  }
}
.periods-details-slots {
  display: flex;
  flex-direction: column;
  padding: 8px 30px 16px !important;

  @include lgScreenAndSmaller {
    padding: 8px 10px 16px !important;
    max-height: 100%;
    .table-head span {
      font-size: 18px;
      font-weight: 700;
      padding: 5px;
    }
  }
  &.outlook {
    padding: 8px 10px 16px !important;
    max-height: 100%;
    .table-head span {
      font-size: 18px;
      font-weight: 700;
      padding: 5px;
    }
  }
  .edit-btn {
    position: sticky;
    top: 0;
    width: 100%;
    align-self: flex-end;
    background-color: white;
    z-index: 1000;
    span.MuiButton-label {
      width: unset;
      margin-left: auto;
    }
  }
  .periods-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 800px;
    // @include mobileScreenOnly(){
    //   min-width: 150px;
    // }

    // max-height: 470px;
    &.fit {
      min-width: unset;
      width: 100%;
    }
    table {
      border-collapse: unset;
      th,
      td {
        min-width: 120px;
        box-sizing: border-box;
        text-align: center;
      }
    }
  }
}

.progress,
.NoSlotFound,
.OneSlotFound {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  // margin-bottom: 16px; // @steph: unsure side effect...
  box-sizing: border-box;
  .margin-top {
    @include themed() {
      margin-top: t(lm-space-normal);
    }
  }
  .preview-link {
    @include themed() {
      // margin-left: t(lm-space-xs);
      text-decoration: underline;
      &:hover {
        color: t(lm-color-pop);
        cursor: pointer;
      }
    }
  }
}
.progress {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 12px;
  width: 100%;
  .MuiLinearProgress-colorPrimary {
    background-color: transparentize($color-robineggblue, 0.8);
    .MuiLinearProgress-barColorPrimary {
      background-color: $color-robineggblue;
    }
  }
  .progress-label {
    box-sizing: border-box;
    font-size: 16px;
    margin-bottom: 5px;
  }
}
.NoSlotFound,
.OneSlotFound {
  &.stiky {
    // position: sticky;
    bottom: 0;
  }
  .slot-content {
    padding: 24px;
    text-align: center;
    font-size: 16px;
    @include themed() {
      background-color: t(lm-background-color-secondary-info);
      margin-bottom: t(lm-space-medium);
    }
    border-radius: 5px;
    &.noguest {
      background-color: white;
      padding-top: 0;
      padding-bottom: 0;
    }
    .tiny-holdslot {
      @include themed() {
        .MuiIconButton-root {
          color: t(lm-color-pop);
          &:hover {
            background-color: white;
          }
        }
        &.disabled .MuiIconButton-root {
          color: t(lm-font-color-secondary);
        }
      }
    }
    &.promote-multiple {
      @include themed() {
        background-color: t(lm-color-background);
        margin-bottom: t(lm-space-medium);
        padding: 0;
        padding-top: t(lm-space-small);
        .multiopt-tip-title {
          margin-bottom: t(lm-space-xs);
        }
        .multiopt-tip-desc {
          color: t(lm-font-color-secondary);
        }
        a {
          color: t(lm-font-color-primary);
        }
      }
    }
    .strong {
      font-weight: 500;
    }
    strong {
      font-weight: 500;
    }

    a {
      margin-left: 4px;
      text-decoration: underline;
      cursor: pointer;
    }
    strong {
      display: block;
      @include themed() {
        margin-bottom: t(lm-space-normal);
      }
    }
    @include themed() {
      .action-link,
      .action-link &:active {
        margin-top: t(lm-space-small);
        display: flex;
        align-items: center;
        padding: 0px 14px;
        border-width: 1px !important;
        svg {
          margin-right: 0;
        }
        &:hover {
          color: t(lm-color-pop);
          cursor: pointer;
        }
      }
    }

    &.details-list > div {
      @include themed() {
        display: flex;
        padding-bottom: t("lm-space-small");
        padding-top: t("lm-space-small");
        border-bottom: 1px solid #dadada;
      }
      &.inner-infos {
        margin: 0 20px;
        background-color: white;
        width: calc(100% - 80px) !important;
        padding: 20px;
        border-bottom: 1px solid #dadada;
      }
      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
      &:nth-last-child(2) {
        border-bottom: none;
      }
      &.ask-doners {
        margin-top: 0;
      }
      &.doners {
        display: flex;
        flex-direction: column;
        .done-input {
          width: 100%;
          box-sizing: border-box;
          &.MuiFormControl-root {
            @include themed() {
              margin-top: -2px;
              //width: 100%;
            }
          }
          .MuiRadio-root {
            padding: 0 9px;
          }
          @include themed() {
            .MuiFormControlLabel-root {
              margin-left: 0;
              .MuiFormControlLabel-label {
                color: t("lm-font-color-primary");
                flex-grow: 1;
              }
            }
            .Mui-checked + span {
              font-weight: t(lm-font-weight-semibold);
            }
          }
        }
      }
    }
    &.details-list {
      & > button.done-button.form {
        width: unset;
        margin-top: 10px;
        .MuiButton-label {
          .MuiCircularProgress-root {
            width: 28px !important;
            height: 28px !important;
            color: white;
            margin-right: 10px;
          }
        }
        &:hover {
          .MuiButton-label {
            .MuiCircularProgress-root {
              color: black;
            }
          }
        }
      }
    }
    &.oneslot {
      .OneSlotFound {
        display: flex;
        flex-direction: row;

        align-items: center;
        // padding: 5px;
        .done-button {
          min-width: unset;
          padding: 5px 16px;
          margin: 0;
        }
        .infos {
          display: flex;
          flex-direction: row;
          flex: 1;
          .meeting-details {
            display: flex;
            flex-direction: row;
            .meeting-date {
              color: black;
              @include themed() {
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-width: 70px;
                height: 70px;
                text-align: center;
                background-color: white;
                padding: 5px;
                border-radius: 14px;
                border: 2px solid t(lm-color-pop);
                font-size: t(lm-font-size-normal);
                letter-spacing: 2px;
                .month {
                  text-transform: uppercase;
                  font-weight: t(lm-font-weight-semibold);
                }
                .date {
                  font-size: 40px;
                  font-weight: t(lm-font-weight-semibold);
                  line-height: 0.85em;
                }
                .hour {
                  text-transform: uppercase;
                  font-weight: t(lm-font-weight-normal);
                }
              }
            }
            .meeting-details-infos {
              @include themed() {
                margin: 0 10px;
                .meeting-title {
                  font-size: t(lm-font-size-large);
                  font-weight: t(lm-font-weight-semibold);
                  margin-bottom: t(lm-space-xs);
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                a {
                  margin: 0;
                }
                .meeting-slot {
                  margin-bottom: 10px;
                  span.start,
                  span.end {
                    font-weight: t(lm-font-weight-semibold);
                  }
                }
                .forced-users {
                  display: flex;
                  margin: 5px 0 0 0;
                  flex-direction: row;
                  align-content: center;
                  align-items: flex-start;
                  font-size: t(lm-font-size-normal);
                  font-style: italic;
                  margin-top: t(lm-space-small);
                  .tooltipable {
                    font-weight: t(lm-font-weight-bold);
                    text-decoration: underline;
                    cursor: help;
                  }
                  a {
                    display: block;
                    margin-top: t(lm-space-medium);
                  }
                }
              }
              .meeting-time-and-location {
                display: flex;
                width: 100%;
                @include themed() {
                  margin: t("lm-space-xs") 0;
                }
                @include mobileScreenOnly() {
                  flex-direction: column;
                }
                & > span {
                  @include with-icon();
                  padding-left: 20px;
                  left: 0;
                }
                .meeting-duration {
                  @include themed() {
                    padding-right: t("lm-space-medium");
                  }
                  &::before {
                    content: "\e8b5";
                    left: 0;
                  }
                }
                .meeting-location {
                  display: flex;
                  align-content: center;
                  align-items: center;
                  justify-content: flex-start;
                  flex-direction: row;
                  flex: 1;
                  overflow-y: hidden;
                  text-overflow: ellipsis;
                  &,
                  span {
                    //max-width: 200px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    // overflow: hidden;
                  }
                  .text-block {
                    // max-width: 250px;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    // display: inline-flex;
                    overflow: hidden;
                  }
                  &::before {
                    content: "\e0c8";
                    left: 0;
                  }
                  .conference {
                    display: inline-flex !important;
                    svg {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
        @include smScreenAndSmaller {
          flex-direction: column;
          .infos {
            width: 100%;
            padding-bottom: 24px;
          }
        }
      }
    }
    .multiopt-tip {
      display: flex;
      flex-direction: column;
      .multiopt-tip-title {
        font-weight: 500;
      }
      .multiopt-tip-desc {
      }
    }
    .notslot-message {
      text-align: left;
      & > div {
        margin-bottom: 20px;
      }
      strong {
        display: inline;
      }
    }
    .options-with-hs {
      display: flex;
      justify-content: space-between;
      align-items: center !important;

      .options.showHand.disabled {
        pointer-events: none;
        @include themed() {
          border-color: t(lm-border-main-color);
          background-color: t(lm-border-main-color);
          color: t(lm-font-color-secondary);
        }
      }
      .separator{
        @include themed() {
          height: t(lm-space-medium);
        }
      }
    }
  }
  &.multiSlotsFound > div {
    flex-direction: column;
    @include themed() {
      > * {
        // &:first-child {
        //   margin-bottom: t(lm-space-normal);
        // }
        // &:not(:first-child) {
        //   margin-top: t(lm-space-xs);
        // }
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-start;
        svg.illus {
          width: 20px;
          height: 20px;
          margin-right: t(lm-space-small);
        }
      }
    }
    strong {
      display: inline;
    }
    .explanation-block {
      display: flex;
    }
    .options {
      @include themed() {
        color: t(lm-color-pop);
        border: 1px solid t(lm-color-pop);
        background-color: #f5f8ff;
        font-weight: t(lm-font-weight-semibold);
      }
      display: inline-block;
      &.underline {
        text-decoration: underline;
      }
      &.showHand {
        cursor: pointer;
      }
      padding: 0px 6px;
      border-radius: 2px;
      &:hover {
        text-decoration: none;
        @include themed() {
          color: t(lm-color-pop);
        }
        background-color: white;
        color: black !important;
      }
      .pencil {
        width: 18px;
        height: 18px;
        margin-left: 2px;
        vertical-align: bottom;
      }
    }
  }
  .recap-title {
    @include themed() {
      font-weight: t(lm-font-weight-semibold);
      font-size: t(lm-font-size-medium);
      // text-transform: uppercase;
      text-align: left;
      // margin-top: t(lm-space-small);
      margin-bottom: t(lm-space-xs);
    }
  }
}
// .oneslot{
//     // testing
//     @include themed() {
//     background-color: t(color-light-pop)!important;
//     color: white;
//     box-shadow: 0px 4px 7px rgba(0,0,0,0.5);
//     padding: 10px!important;
//     .OneSlotFound{
//       margin-bottom: 0;
//       .meeting-date{
//         margin-left: -38px;
//         transition: all 0.5s ease;
//       }
//       &:hover{
//         .meeting-date{
//           //width: 120px;
//           // min-height: 120px;
//           font-size: 1.3em;
//           margin-left: -45px;;
//         }
//       }
//     }
//     }
//   }

// .OneSlotFound.forced {
//   @include themed() {
//     &> div > div {

//       svg {
//         margin-right: t(lm-space-small);
//       }
//     }
//     .tooltipable {
//       font-weight: t(lm-font-weight-bold);
//       text-decoration: underline;
//       cursor: help;
//     }
//     a {
//       display: block;
//       margin-top: t(lm-space-medium);
//     }
//   }
// }
.NoSlotFound > div:first-child {
  margin-bottom: 10px;
}

.HoldSlotsOption {
  border-bottom: none !important;
  & > div {
    // &:first-child {
    //   @include themed() {
    //     margin-top: t(lm-space-medium);
    //     border-top: 1px solid #dadada;
    //     padding-top: t(lm-space-medium);
    //   }
    // }

    text-align: left;
    width: 100%;
    @include themed() {
      // margin-top: t(lm-space-medium);
      // border-top: 1px solid #dadada;
      // padding-top: t(lm-space-medium);
      display: flex;
      > svg {
        // lock icon
        width: 18px;
        height: 18px;
        margin-top: 9px;
        margin-right: t(lm-space-small);
      }
      > div {
        flex-grow: 1;
      }
      .form-control {
        display: flex;
        margin-left: 0;
        .label {
          padding-top: 9px;
          flex-grow: 1;
        }
        svg.help {
          width: 20px;
          height: 20px;
          margin-top: -0.6em;
          fill: t(lm-color-pop);
        }
      }
      .more {
        color: t(lm-font-color-secondary);
        margin-top: t(lm-space-xsmall);
        ul {
          margin: 0.5em 0;
          li {
            padding: 0.1em 0;
          }
        }
      }
    }
  }
}