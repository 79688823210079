.Version202305{
  .MuiAccordion-root.done-input.foldable{
    margin-top: 0 !important;
  }
  .conference-selector.no-bottom{
    margin-bottom: 0!important;
  }
  .no-stretch{
    flex-grow: 0;
  }
}