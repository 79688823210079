@import "../../../styles/themes";
@import "../../../styles/screens";

.light {
    @include themed() {
        .ProposalAttendees {
            color: t(lm-font-color-proposal-secondary-light);
            .attendee {
                svg {
                    fill: t(lm-font-color-proposal-secondary-light);
                    &.done {
                        fill: t(lm-background-has-voted-light);
                    }
                    &.cannot-vote {
                        fill: t(lm-font-color-proposal-secondary-light) !important;
                    }
                }
                .spin {
                    svg {
                        fill: black;
                        color: black;
                    }
                }
                &.selected {
                    color: t(lm-font-color-proposal-primary-light);
                    svg {
                        &:not(.done) {
                            fill: t(lm-font-color-proposal-primary-light);
                        }
                    }
                }
            }
        }
    }
}

.ProposalAttendees {
    @include themed() {
        // padding: t(lm-space-medium) 0;
        @include lgScreenAndSmaller {
            padding: 0;
            // margin-top: t(lm-space-medium-plus);
        }
        @include mobileScreenOnly {
            // margin-top: t(lm-space-small);
        }
        @include xlScreenOnly {
            padding-left: 0;
        }
        // margin-top: t(lm-space-medium);
        color: t(lm-font-color-proposal-secondary);

        .attendee {
            .org {
                margin-left: t(lm-space-xs);
                align-self: baseline;
                @include lgScreenAndSmaller {
                    display: none;
                }
            }
            @keyframes rolling {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            svg {
                margin-right: t(lm-space-small);
                fill: t(lm-font-color-proposal-secondary);
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                &.done {
                    fill: t(lm-background-has-voted);
                }
                &.cannot-vote {
                    fill: t(lm-font-color-proposal-secondary) !important;
                }
            }

            .spin {
                margin-top: 2px;
                margin-right:  t(lm-space-small);
                &.MuiCircularProgress-root {
                    width: 20px !important;
                    height: 20px !important;
                }
                svg {
                    margin: 0 !important;
                    padding: 2px 2px 2px 2px;
                    fill: white;
                    color: white;
                }
            }

            &.selected {
                color: t(lm-font-color-proposal-primary);
                font-weight: t(lm-font-weight-bold);
                svg {
                    &:not(.done) {
                        fill: t(lm-font-color-proposal-primary);
                    }
                }
            }
            display: flex;
            align-items: center;
            margin: t(lm-space-small) 0;
        }
    }
}
