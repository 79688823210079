@import "../../../../styles/themes";
@import "./common";

.status {
  @include timelineitem();
  @include with-icon("../../../../../assets/calendar.svg");
  @include themed() {
    font-size: t("lm-font-size-normal");
    &::before {
      opacity: 0.5;
    }
  }
  .strong{
      @include themed() {
        font-weight: t("lm-font-weight-bold");
      }
    }
  
  &.active {
    @include themed() {
      font-size: t("lm-font-size-medium");
      font-weight: t("lm-font-weight-bold");
    }
  }
  //&:not(.active){
    @include themed() {
      @include with-line(1px solid t(lm-timeline-line-color));
    }
  //}
  &.complete {
    &.iamhost{@include themed() {
      @include with-line(1px solid t(lm-timeline-line-color));
    }}
    
    &::before {
      content: " ";
      background-image: url("../../../../../assets/picto_mail_success.svg");
      opacity: 1;
      @include themed() {
        background-color: t("lm-color-background");
      }
    }
  }
  &.canceled {
    &::before {
      content: " ";
      background-image: url("../../../../../assets/cancelled.svg");
      opacity: 1;
      @include themed() {
        background-color: t("lm-color-background");
      }
    }
  }
  &.no-avail {
    &::before {
      content: " ";
      opacity: 1;
      background-image: url("../../../../../assets/not_available.svg");
      @include themed() {
        background-color: t("lm-color-background");
      }
    }
  }
  .done-button{
    @include themed(){
      font-size: 14px;
      padding: 2px 5px;
      margin: 5px;

      svg{
        margin-right: 0;
    width: 18px;
    height: 18px;
      }
    }
    
  }
}
