@import "../../styles/themes";
@import "../../styles/_screens.scss";

.create-event-form {
  width: 90%;
  min-width: 200px;
  max-width: 640px;
  margin: auto;
  padding-top: 20px;
  // @include xlScreenOnly {
  //   @include themed() {
  //     background-color: white;
  //     margin-top: 30px;
  //     margin-bottom: 20px;
  //     padding: 24px 40px 20px 40px;
  //     border-radius: t(lm-border-radius-normal);
  //     border: 1px solid t(lm-border-main-color);
  //     box-shadow: t(lm-shadow-normal);
  //   }
  // }
  @include mobileScreenOnly() {
    padding-top: 10px;
  }
  & > *,
  & > .form-content > * {
    &:first-child {
      margin-top: 10px !important;
    }
    margin-top: 15px !important;
    margin-bottom: 10px !important;

    &.free-user-tip {
      margin-top: 0px !important;
      margin-bottom: 30px !important;
    }

    @include mobileScreenOnly() {
      margin-top: 15px !important;
      margin-bottom: 10px !important;
    }
    &.fix-margin {
      margin-top: 10px !important;
    }
    &fieldset {
      margin: 0;
    }

    &.duration-padding {
      display: inline-block;
      width: 100%;
      position: relative;
    }
    &.timeframe-selector {
      margin-bottom: 12px !important;
    }
  }

  .MuiFormLabel-root:not(.fake-label) {
    font-size: 0.85rem;
  }

  .working-hours {
    text-align: left;
    display: flex;
    align-items: center;
    a {
      text-decoration: underline;
      cursor: pointer;
    }
    svg {
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .form-footer {
    bottom: 0;
    display: flex;
    flex-direction: column;
    // position: sticky;
    // background-color: rgba(255, 255, 255, 0.7);
    padding: 0 0 /*16px*/ 0;
    text-align: center;
    z-index: 999;
    .done-button {
      .MuiButton-root {
        min-width: unset;
        white-space: unset;
        font-size: 20px;
      }
      .MuiCircularProgress-root {
        color: white !important;
        max-height: 24px;
        max-width: 24px;
        margin-right: 6px;
      }
      .MuiButton-label {
        text-transform: none;
        @include mobileScreenOnly() {
          font-size: 16px !important;
          width: 100%;
        }
      }
    }

    &.free-user-expire {
      @include themed() {
        margin: 0 !important;
        margin-top: 20px !important;
        font-size: t(lm-font-size-normal);
        display: flex;
        align-content: center;
        justify-content: center;
        color: t(lm-font-color-primary);
        padding: 0 20px 20px 20px !important;
        background: white;
        box-sizing: border-box;
      }
      @include mobileScreenOnly() {
        display: block;
      }
      // $illus-width: 130px;
      // svg.SuccessIllus {
      //   flex-shrink: 0;
      //   width: $illus-width;
      //   height: $illus-width;

      //   @include themed() {
      //     margin-right: t(lm-space-small);
      //   }
      //   @include mobileScreenOnly() {
      //     width: 100px;
      //     height: 100px;
      //     margin-right: 0 !important;
      //     margin: 0 auto;
      //   }
      // }
      .content {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include mobileScreenOnly() {
          max-width: 100% !important;
          text-align: center;
        }
        .title {
          @include themed() {
            font-size: t(lm-font-size-medium);
            font-weight: t(lm-font-weight-bold);
            margin-bottom: t(lm-space-xs);
          }
        }
        .message {
          @include themed() {
            margin-bottom: t(lm-space-small);
            line-height: t(lm-line-height-paragraph-normal);
          }
        }
        .done-button {
          margin: 0 auto 0 0;
          @include mobileScreenOnly() {
            margin: 0 auto;
          }
        }
      }
    }
  }
  .doners {
    margin-top: 8px !important;
    margin-bottom: 18px !important;
  }
  @include themed() {
    .FormNotice {
      background-color: t(lm-background-item-hover);
      border-radius: t(lm-border-radius-normal);
      font-size: t(lm-font-size-normal);
      padding: 14px;
      p {
        margin: 0;
        margin-top: 10px;
        padding: 0;
        a {
          color: t(lm-color-pop);
          text-decoration: underline;
        }
        a.goto {
          color: t(lm-font-color-primary);
          font-weight: t(lm-font-weight-bold);
          text-decoration: none;
        }
        &:first-child {
          margin-top: 0;
        }
        b,
        strong {
          font-weight: t(lm-font-weight-bold);
        }
      }
    }
  }
  &.unactive {
    & > div:not(.user-not-auth):not(.form-content):not(.form-header),
    & > fieldset,
    .form-content > div:not(.user-not-auth),
    .form-content > fieldset {
      opacity: 0.35;
    }
    .form-content {
      box-shadow: none !important;
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        background: linear-gradient(0deg, #f5f8ffff 0%, #f5f8ff00 80%);
      }
    }
    *.done-input,
    button.done-button.form,
    .calendar-selector-simple * {
      @include themed() {
        pointer-events: none !important;
      }
      //   background-color: rgba(0,0,0,0.2);
      //   button.Mui-selected,
      //   button.Mui-selected .strong{
      //     background-color: rgba(0,0,0,0);
      //     color: rgba(0,0,0,0.7)!important;
      //     border: none
      //   }
    }
    .done-input-label,
    label {
      pointer-events: none !important;
      //   color: rgba(0,0,0,0.7);
      //   background: none;
      //   background-color: transparent!important;
    }
    .calendar-selector-simple {
      pointer-events: all !important;
      //   background-color: inherit;
      * {
        pointer-events: all !important;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

@include lgScreenAndSmaller {
  .create-event-heatmap {
    .MuiDialog-paperWidthLg {
      max-width: 1480px;
    }
    .MuiDialog-paper {
      margin: 0;
      width: 100%;
      height: 100%;
      max-height: unset;
    }
    .slots-proposal-day .heatmap-container {
      max-height: 70vh;
    }
  }
}

.billing-dialog {
  @include themed() {
    padding: t(lm-space-large) t(lm-space-large);
  }
  @include mobileScreenOnly() {
    @include themed() {
      padding: t(lm-space-small) t(lm-space-medium);
    }
  }
  & > .title {
    @include themed() {
      color: t(color-font-active);
      font-size: t(header-font-size);
      font-weight: t(lm-font-weight-bold);
      margin-bottom: t(lm-space-medium);
    }
  }

  & > .promo {
    text-align: justify;
    @include themed() {
      color: t(lm-font-color-secondary);
      font-size: t(main-font-size);
    }
  }
  .billing-promo-card {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
    @include themed() {
      margin-top: t(lm-space-medium);
    }
    .title {
      @include mobileScreenOnly() {
        @include themed() {
          font-size: t(main-font-size);
          margin: 0;
        }
      }
      &.offer {
        @include mobileScreenOnly() {
          @include themed() {
            padding-top: 0;
          }
        }
      }
    }
    ul {
      width: 100%;
    }
    .done-button.premium {
      @include mobileScreenOnly() {
        @include themed() {
          margin-top: 0;
        }
      }
    }
  }
}
// .variant-snackbar {
//   .billing-welcome {
//     @include themed() {
//       border: 1px solid t(color-panel-dark);
//     }
//   }
// }
.confirm-not-enough-slots {
  .actions {
    .done-button {
      margin: 0;
    }
  }
}
