@import "../../../styles/themes";
@import "../../../styles/screens";

.light {
    @include themed() {
        .ProposalInfo {
            color: t(lm-font-color-proposal-secondary-light);
            .left-deco {
                background-color: t(pop-color);
            }
            .title {
                color: t(lm-font-color-proposal-primary-light);
            }
        }
    }
}

.ProposalInfo {
    position: relative;
    @include themed() {
        color: t(lm-font-color-proposal-secondary);
        padding: t(lm-space-medium);
        > * {
            &:not(.left-deco) {
                margin: 0px 0px t(lm-space-small) t(lm-space-xs);
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .left-deco {
            position: absolute;
            width: 4px;
            top: 0;
            bottom: 0;
            left: 0;
            border-radius: 5px;
            background-color: t(lm-font-color-proposal-primary);
        }

        .from {
            font-size: t(lm-font-size-small);
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .title {
            color: t(lm-font-color-proposal-primary);
            font-size: 30px;
            @include mobileScreenOnly {
                font-size: t(lm-font-size-xlarge);
            }
        }
        .subinfo {
            .location {
                .conference {
                    svg {
                        display: none;
                    }
                }
            }
        }
        .notes {
            line-height: 1.4em;
            margin-top: t(lm-space-medium);
            font-style: italic;
            max-height: 40vh;
            overflow: auto;
        }
    }
}
.company_logo{
  width: 150px;
  margin: 0 auto;
  display: block;
}