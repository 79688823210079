@import "./styles/constants";

.DoneChip {
    display: inline-flex;
    outline: none;
    min-width: 0;
    max-width: 250px;
    .container {
        border-radius: 26px;
        background-color: $color-whitesmoke;
        display: flex;
        align-items: center;
        padding-right: 6px;

        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            align-items: center;
            user-select: none;
            border-radius: 15px;
            padding: 2px 6px;
            font-size: $pills-font-size;
            color: $color-main-font-color;
        }

        > .answer {
            display: flex;
            align-items: center;
            margin-left: 2px;
            svg {
                height: 16px;
                width: 16px;
            }
            > svg.yes {
                fill: $color-robineggblue;
            }
            > svg.no {
                fill: $color-carminepink;
            }
            > svg.maybe {
                fill: $color-peach;
            }
        }

        .pills-cross {
            height: 12px;
            width: 12px;
            cursor: pointer;
        }
    }
}