@import "../../../../styles/_themes.scss";
@import "../../../../styles/_screens.scss";

.location-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include mdScreenAndLarger() {
    padding: 0px 4px;
  }
  & > svg {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    @include themed() {
      margin-right: t(lm-space-small);
    }
    &.free {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      margin-left: 6px;
    }
  }
  .location-menu-desc {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: unset;
    .location-menu-desc-title {
      @include themed() {
        font-size: t(lm-font-size-normal);
        font-weight: t(header-font-weight);
      }
    }
    .location-menu-desc-desc {
      // margin-top: -4px;
      @include themed() {
        font-size: t(secondary-font-size);
        color: inherit; //t(lm-font-color-secondary);
        line-height: 1em;
      }
    }
  }
}
.done-input-select-label {
  font-size: 0.65rem !important;
  text-align: left;
  position: absolute;
  top: -10px !important;
  left: 9px;
  z-index: 99;
  background-color: white;
  background: linear-gradient(180deg, white 0%, #f8f8f9 50%, rgba(251, 251, 252, 0) 90%);
  padding: 1px 1px 1px 4px !important;
  transform: none !important;
}
.location-selector {
  padding-right: 18.5px;
  .MuiSelect-select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .location-no-focus {
    .MuiSelect-select.Mui-focused,
    .MuiSelect-select:focus {
      background-color: transparent !important;
    }
  }
  .MuiInputBase-multiline {
    padding: 0 !important;
  }
}
.location-selector-item {
  @include themed() {
    display: block;
    cursor: pointer;
    pointer-events: all;
    padding: t("lm-space-small") t("lm-space-medium");
    font-size: t("lm-font-size-medium");
    &.selected,
    &:hover {
      background-color: t(color-outgoing-message-background);
    }
    span {
      pointer-events: none !important;
    }
  }
}
