@import "../../../styles/constants";

.recap-details-invitees.simple-list {
  flex: unset;
  min-height: unset !important;
  &.collapsed {
    .header {
      padding-bottom: 0 !important;
    }
  }
  .header {
    cursor: pointer;
    display: flex;
    div {
      flex: 1;
    }
    .arrow-toggle {
      display: none;
      position: relative;
      left: 0;
      padding-right: 0;
      transition: 150ms;
    }
    @include mobileScreenOnly {
      .content {
        display: none;
      }
      .arrow-toggle {
        display: inline-block;
      }
    }
  }
  &.collapsed .content {
    @include mobileScreenOnly {
      display: none;
    }
  }
  &.expanded {
    @include mobileScreenOnly {
      .content {
        display: block;
      }
      .arrow-toggle {
        transform: rotate(-180deg);
      }
    }
  }
  .MuiChip-root {
    margin: 3px 3px;
    background-color: #f6f6f6;
    font-size: 13px;
    max-width: 300px;
    min-width: 50px;
  }

  .content{
    span.tip{
      width: 100%;
      
      display: block;
    }
  }
}
