@import "../../../../styles/themes";
@import "../../../../styles/screens";
.done-calendar-selector {
  @include themed() {
    border-radius: t(lm-border-radius-input);
  }

  // border: 1px solid blue; // rgba(0, 0, 0, 0.23);
  &:not(.calendar-selector-simple):not(.user-not-auth) {
    @include themed() {
      border: t(form-input-border-default);
    }
    &:hover {
      border: 1px solid#747474;
    }
  }
  legend {
    transform: translate(0px, -6px) scale(0.75);
    display: flex;
    align-items: center;
    svg {
      margin-left: 6px;
      width: 16px;
      height: 16px;
    }
  }
  .done-input-label {
    background-color: white !important;
    padding: 0 5px;
    transform: translate(6px, -6px) scale(0.75);
    color: $color-robineggblue;
  }

  &:hover {
    border: 1px solid#747474;
  }
  &.calendar-selector-simple {
    legend {
      margin-left: 12px;
      pointer-events: auto;
    }
    @include mobileScreenOnly() {
      width: 100% !important;
      padding-top: 6px;
    }
  }

  .selector {
    display: flex;
    margin: 5px 1rem 0px 0px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 12px;
    padding-bottom: 5px;
    @include themed() {
      border-radius: t(lm-border-radius-normal);
    }
    .selected-calendar {
      position: relative;
    }
    span.required {
      position: absolute;
      right: 10px;
    }
    &:hover {
      @include themed() {
        background-color: t(lm-background-item-hover);
      }
    }
  }

  .no-account-user {
    padding: 5px;
    display: flex;
    white-space: nowrap;
    @include mobileScreenOnly() {
      flex-direction: column;
    }
    .no-account-text {
      padding: 10px 0;
      margin-left: 12px;
    }
  }

  // prompt user to signup
  &.user-not-auth {
    border: none;
    @include themed() {
      .title {
        color: t(lm-color-pop);
        font-size: t(lm-font-size-xlarge);
        font-weight: t(lm-font-weight-bold);
        margin-bottom: 1em;
      }
      .container {
        border: 2px solid t(lm-color-pop);
        border-radius: t(lm-border-radius-normal);
        padding: 2.3em 2em;
        @include mdScreenAndSmaller {
          padding: 1.3em 1em;
        }
        .subcontainer {
          // text-align: left ;
        }
        .subtitle1 {
          font-size: t(lm-font-size-large);
          font-weight: t(lm-font-weight-bold);
        }
        .subtitle2 {
          font-size: t(lm-font-size-medium);
          margin-top: 0.8em;
        }
        .providers {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1.7em;
          align-items: stretch;

          @include smScreenAndSmaller {
            flex-direction: column;
            button:first-child {
              margin-bottom: 1em;
              margin-right: 0em!important;
            }
            button {
              flex: 1;
            }
          }
          
          button {
            // flex: 1;
            // width: 270px;
            &:first-child {
              margin-right: 1em;
            }
            text-transform: initial;
            background-color: white;
            padding: 12px 16px;
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  &.disabled {
    @include themed() {
      pointer-events: none;
      .done-input-label {
        color: t(lm-font-color-secondary);
      }
    }
  }
}
.selector-options {
  @include themed() {
    padding: t(lm-space-medium) t(lm-space-small);
  }
  line-height: unset;
  .selected-calendar {
    line-height: normal;
    background-color: white;
    .content {
      text-align: left;
      .title {
        display: flex;
        align-content: center;
        text-transform: uppercase;
        @include themed() {
          font-size: t(lm-font-size-small) !important;
          font-weight: t(lm-font-weight-normal);
          color: t(lm-font-color-secondary);
        }
      }
    }
  }
  & > .MuiList-root {
    & > li:not(:first-child) {
      margin-top: 20px;
    }
  }
  ul {
    background-color: "inherit";
    padding: 0;

    .MuiListItem-root {
      padding: 0;
      padding-left: 16px;
      padding-right: 16px;
    }
    .MuiListSubheader-root {
      font-weight: normal !important;
      margin-bottom: 6px;
    }

    li.manage-link {
      text-align: center;
      .done-button.grey {
        padding: 6px 24px;
        font-size: 16px;
        min-width: unset;
      }
    }
  }
}
.calendar-selector-simple {
  border: none;
  width: min-content;
  padding: 0;
  legend.done-input-label {
    font-size: 12px;
    padding: 0;
    transform: translate(0px, -3px) !important;
    background-color: transparent !important;
  }
  &:hover {
    border: none;
  }
  .selector {
    padding-top: 5px;
    padding-bottom: 0;
    align-items: flex-start;
    button {
      padding: 0;
      margin-left: 10px;
    }
  }
}
