@import "../../../../styles/_themes.scss";
@import "../../../../styles/_screens.scss";

.proposal {
  width: 135px;
  height: 52px;
  margin: 0 20px 0 0;
  border-radius: 3px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  box-sizing: border-box;

  @include themed() {
    border: 1px dashed t(color-light-pop);
    font-size: t(lm-font-size-small);
    color: t(lm-font-color-slot);
    &.is-busy {
      border: t(lm-border-slot-busy) !important;
    }
  }

  &.empty {
    @include themed() {
      border: 1px dashed t(color-font-inactive);
      background-color: t(app-background-color);
    }
  }
  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    @include themed() {
      font-size: t(lm-font-size-small) !important;
    }
  }
  .slot {
    display: none;
    flex-shrink: 0;
    border-radius: 50%;
    color: white;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    @include themed() {
      background-color: t(pop-color);
    }
  }

  button {
    opacity: 1;
    @include mobileScreenOnly() {
      opacity: 0;
    }
    position: absolute;
    top: calc(50% - 11px);
    right: 8px;
    // transform: translateX(10px);
    pointer-events: all;
    transition: all 100ms ease-out;
    & > * {
      pointer-events: none;
    }
    svg {
      @include themed() {
        fill: t(lm-font-color-slot);
      }
      width: 18px;
      height: 18px;
    }
  }
  &.is-busy {
    button svg {
      @include themed() {
        fill: t(lm-font-color-slot-busy);
      }
    }
  }

  &:hover {
    button {
      opacity: 1;
      transform: translateX(0);
      // @include mobileScreenOnly(){
      //   display: none;
      // }
    }
  }
}
