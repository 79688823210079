@import "./styles/_constants.scss";

.tlc {
  font-size: 12px;
  color: #848484;
  text-align: center;
  padding-top: 20px;
  span {
    margin: 0 5px;
  }
  a {
    color: $color-robineggblue;
    font-weight: 500;
  }
}
