@import './styles/_themes.scss';
span.text-block{
  display: block;

  a{
    @include themed() {
      color:t(pop-color);
      word-break: break-all;
    }
  }
}