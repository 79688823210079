@import "./styles/themes";
@import "./styles/_screens.scss";

.onlinehelp-frame{
  width: 100%;
  height: 100%;
  min-height: 300px;
  min-width: 200px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .onlinehelp-iframe{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    min-height: 300px;
    border-width: 0;
  }
  .close-btn{
    position: absolute;
    top: 2px;
    right: 2px;
  }
}
.big-screen > .MuiDialog-container > .MuiPaper-root{
  height: calc(100% - 64px);
  border-radius: 10px;
  max-height: 810px;
  @include lgScreenAndSmaller {
    border-radius: 0px;
    &.MuiDialog-paperWidthLg {
      max-width: 1480px;
    }
    &.MuiDialog-paper {
      margin: 0;
      width: 100%;
      height: 100%;
      max-height: unset;
    }
  }
}