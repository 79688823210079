@import "screens";

// colors

$color-white: white;

$color-licorice: #121212;
$color-robineggblue: #7060DF; // accent color
$color-bittersweet: #fb735b;
$color-bananayellow: #f7db3a;
$color-taupegrey: #888888;
$color-isabelline: #efefef;
$color-whitesmoke: #f4f4f4;
$color-grey: #b2b2b2;
$color-lightpurple: #8886B1;
$color-icterine: #fbf45b;
$color-pear: #dee83a;
$color-acidgreen: #b1ca0e;
$color-aquamarine: #69eecf;
$color-mountainmeedow: #16b569; //
$color-medaquamarine: #65dcd5;
$color-tiffanyblue: #0aa7ac;
$color-peach: #fbc08a; //
$color-palecoper: #d98b72; //
$color-rajah: #fbaf6b; //
$color-cadmiumorange: #e68f2f; //
$color-carminepink: #d74d42;
$color-deepchesnut: #b94041;
$color-white: white;
$color-fgbg: #f6f6f6;
// colors referenced in stylesheets

$busyvert: $color-robineggblue;
$busyorange: $color-peach;
$busyrouge: #f44336;

$color-error: $color-carminepink;

$color-sidepanel-rgba: #fbfbfb;
$color-button-bg-hover: #f6f6f6;

$color-font-inactive: $color-taupegrey;
$color-font-active: $color-licorice;
$color-main-font-color: $color-licorice;
$color-unread: $color-carminepink;
$color-incoming-message-background: white;
$color-incoming-message-border: $color-isabelline;
$color-incoming-message-text: $color-licorice;
$color-outgoing-message-background: $color-whitesmoke;
$color-outgoing-message-text: $color-licorice;
$input-border-color: rgba(99, 127, 159, 0.2);
// overlay background
$overlay-background: rgba(0, 0, 0, 0.5);
// gradient
$blue-background-gradient: linear-gradient(317.05deg, #38d0fb 0%, #1aa3f5 100%);

// DIMENSIONS
$header-height: 80px;
$header-icon-dimension: 35px;
$main-header-font-size: 26px;
$header-font-size: 18px;
$header-font-weight: 500;
$header-icon-size: 18px;
$avatar-font-size: 13px;
$avatar-pills-font-size: 10px;
$avatar-pills-font-size-big: 36px;
$mini-font-size: 13px;
$main-font-size: 16px;
$title-font-size: 36px;
$title-font-weight: 900;
$secondary-font-size: 13px;
$message-font-size: 16px;
$avatar-dimension: 48px;
$avatar-pills-mode-dimension: 26px;
$pills-font-size: $secondary-font-size;
$right-side-panel-width: 410px;
$mobile-mini-frame-padding: 20px;

$todo-x-header-height: 60px;

// border radius
$common-box-border-radius: 6px;

// Done picker
$dp-active_color: $color-licorice;
$dp-unactive_color: #b9b8b8;
$dp-unactive_color2: #e8e8e8;

// export can be used in JS
:export {
  colorLicorice: $color-licorice;
  colorTaupeGrey: $color-taupegrey;
  colorRobinEggBlue: $color-robineggblue;
  colorIcterine: $color-icterine;
  colorPear: $color-pear;
  colorAcideGreen: $color-acidgreen;
  colorAquamarine: $color-aquamarine;
  colorMedAquamarine: $color-medaquamarine;
  colorTiffanyBlue: $color-tiffanyblue;
  colorPeach: $color-peach;
  colorCarminePink: $color-carminepink;
  colorDeepChesnut: $color-deepchesnut;
  colorButtonBgHover: $color-button-bg-hover;
  colorIsabelline: $color-isabelline;
  colorMountainMeedow: $color-mountainmeedow;
  colorPeach: $color-peach;
  colorPaleCoper: $color-palecoper;
  colorRajah: $color-rajah;
  colorCadmiumOrange: $color-cadmiumorange;
  colorBitterSweet: $color-bittersweet;
  colorBananaYellow: $color-bananayellow;
  todoXHeaderHeight: $todo-x-header-height;
  mainFontSize: $main-font-size;
}
