@import "../../../styles/_themes.scss";

@import "../../../styles/_constants";
.done-input-day-availability {
  display: flex;
  align-items: center;
  @include mobileScreenOnly() {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  & > .item.done-input-time-item {
    > div:nth-child(1) {
      padding-right: 16px;
      @include mobileScreenOnly() {
        padding-right: 0;
      }
    }
    @include mobileScreenOnly() {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px !important;
    }
  }
  .day-name {
    min-width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 10px;
    @include mobileScreenOnly() {
      padding-left: 0;
      min-width: unset;
    }
  }
  .timeframe {
    display: flex;
    flex-direction: row;
    .hour-proposal {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      span {
        font-size: 13px;
        color: #888888;
      }
    }
  }
  .actions {
    align-items: center;
    @include mobileScreenOnly() {
      display: flex;
      justify-content: flex-end;
    }
    button:nth-child(1) {
      margin-right: 16px;
    }

    .copy- {
      @include mobileScreenOnly() {
        display: none;
      }
    }

    button {
      @include themed() {
        text-transform: uppercase;
        color: t(pop-color);
      }
      @include mobileScreenOnly() {
        font-size: 11px;
        padding: 0;
        margin-top: 4px;
      }
      span {
        svg {
          @include themed() {
            fill: t(pop-color);
          }
        }
      }
    }
  }
}
