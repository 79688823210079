@import "../../styles/constants";
@import "../../styles/_themes.scss";

.dashboard.clean {
  background-image: none;
  background-color: white;
  // &.mobile {
  //   overflow-y: auto;
  //   header {
  //     > div {
  //       padding: 0;
  //     }
  //     svg:not(.logout) {
  //       // logo resize on mobile
  //       height: 40px;
  //       width: 240px;
  //       margin: 0 auto;
  //     }
  //     .mobile-user {
  //       width: 100%;
  //       position: relative;
  //       .logout {
  //         position: absolute;
  //         right: 0px;
  //         bottom: 0;
  //         top: 10px;
  //       }
  //     }
  //   }
  // }
  // header {
  //   @include mobileScreenOnly {
  //     padding: $mobile-mini-frame-padding $mobile-mini-frame-padding 16px;
  //     .signin {
  //       display: none;
  //     }
  //     svg {
  //       // width: 25%;
  //     }
  //   }
  // }
}

.event-container {
  display: flex;
  flex-direction: column;
  .MuiLinearProgress-root {
    height: 2px !important;
    flex: 0 0 auto !important;
  }
  .meeting-title {
    font-size: 18px;
    font-weight: 500;
  }
}
.event {
  $external-padding: 66px;
  $external-padding-smaller: 20px;
  padding: 10px $external-padding; //0;
  flex: 1 1;
  width: 100%;
  max-width: calc(#{$app-max-width} + 2 * #{$external-padding});
  @include lgScreenAndSmaller {
    padding: 10px $external-padding-smaller;
    max-width: calc(100vw - 10px);
  }
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  .right-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
  @include mdScreenAndSmaller {
    flex-direction: column;
    overflow-y: auto;
  }
  @include mdScreenOnly {
    padding: 10px 20px;
  }
  @include smScreenAndSmaller {
    padding: 10px $mobile-mini-frame-padding;
  }
  padding-bottom: 0 !important;
  .recap-details {
    max-width: 25%;
    min-width: 380px;
    text-align: left;
    overflow-x: hidden;
    padding-bottom: 80px;
    @include mobileScreenOnly {
      overflow-x: unset;
    }
    // margin-top: 16px; //96px;
    @include mdScreenAndSmaller {
      margin-top: 0px;
      &.fancy-scroll {
        overflow-y: unset;
        // min-height: 400px;
      }
      max-width: unset;
      min-width: unset;
      
      padding-bottom: 10px;
    }
    // margin-top: 33px;
    display: flex;
    flex-direction: column;
    // flex: 1;
    & > div {
      flex-shrink: 0;
      border-radius: 5px;
      border: solid 1px #bababa;
      margin-bottom: 5px;
      padding: 26px;
      @include mdScreenAndSmaller {
        padding: 16px;
      }
      @include mobileScreenOnly {
        padding: 8px;
      }
    }
    .recap-details-invitees {
      max-height: unset;
      .no-top{
        top: 0;
      }
      @include mobileScreenOnly {
        .header {
          top: 0!important;
          > strong {
            margin-right: 4px;
          }
        }
      }
    }
    .recap-details-infos {
      // on mobile, make it an expandable box
      transition: 300ms;
      .arrow-toggle {
        display: none;
      }
      & > div:last-child {
        margin-bottom: 0px;
      }
      &.collapsable {
        @include mobileScreenOnly {
          cursor: pointer;
          & > div:last-child {
            margin-bottom: 0px;
          }
          & > div:first-child {
            .arrow-toggle {
              transition: 150ms;
              display: block;
              padding: 0;
            }
          }
          &.collapsed {
            & > div:not(:first-child) {
              display: none;
            }
          }
          &.expanded {
            & > div:first-child {
              margin-bottom: 18px;
              .arrow-toggle {
                // display: block;
                transform: rotate(-180deg);
              }
            }
            & > div:not(:first-child) {
              display: "";
            }
          }
        }
      }
    }
    .recap-item {
      flex-shrink: 0;
      // margin-bottom: 18px;
      @include mobileScreenOnly {
        &.strong {
          margin-bottom: 0px;
        }
      }
    }
    .event-status{
      .ProposalStatus{
        .text-container{
          display: block;
        }
      }
    }
  }
  .event-proposals {
    // flex: 1;
    width: 100%;
    // min-height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-left: 36px;
    @include lgScreenOnly {
      padding-left: 20px;
    }
    @include mdScreenAndSmaller {
      padding-left: 0;
    }
    text-align: left;
    .showBest-container {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      align-self: flex-end;
      @include mobileScreenOnly {
        align-self: center;
      }
      .showBestToggle {
        > .MuiToggleButton-root {
          text-transform: none;
          &.Mui-selected {
            @include themed() {
              background-color: t(pop-color);
              color: t(app-background-color);
            }
          }
          &:not(.Mui-selected) {
            @include themed() {
              color: t(color-main-font-color);
            }
          }
          padding: 3px 10px;
          @include mobileScreenOnly {
            .MuiToggleButton-label {
              font-size: 12px;
            }
          }
        }
      }
      svg {
        width: 20px;
        height: 20px;
        @include mobileScreenOnly {
          width: 26px;
          height: 26px;
        }
        margin-left: 14px;
        cursor: pointer;
        @include themed() {
          fill: t(color-licorice);
        }
      }
    }

    .event-proposal-title {
      padding: 8px 0 6px;
      & > :first-child {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .event-proposal-slots {
      display: flex;
      overflow: hidden;
      margin-bottom: 100px;
      form {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        overflow-x: hidden;
        padding-right: 10px;
        @include mobileScreenOnly {
          padding-right: 0;
        }
        // & > div:nth-last-child(2) {
        //   padding-bottom: 72px;
        // }
      }
      @include mobileScreenOnly {
        padding-right: 0;
      }
      & > div {
        padding-bottom: 18px;
      }
    }
    .event-proposal-actions {
      position: sticky;
      box-sizing: border-box;
      padding: 10px;
      &.mobile {
        position: fixed;
        left: 0;
        right: 0;
        box-sizing: border-box;
        @include themed() {
          border-top: 1px solid t(border-grey-color);
        }
        box-shadow: 0px -3px 13px 0px #0000001c;
        .actions {
          flex-wrap: wrap;
        }
        &.fake-block {
          position: sticky;
          bottom: 0;
          opacity: 0;
        }
      }
      bottom: 0em;
      width: 100%;
      // display: flex; // safari does not like this...
      text-align: right;
      @include themed() {
        background-color: t(app-background-color);
      }
      .actions {
        box-sizing: border-box;
        margin-left: auto;
        display: inline-flex;
        @include mobileScreenOnly {
          flex-direction: column;
          width: 100%;
        }
        align-items: center;
        button {
          &.decline {
            font-size: 13px;
            margin-left: 13px;
            @include mobileScreenOnly {
              margin-left: 0px;
              margin-top: 5px;
            }
          }
          // &.secondary {
          //   min-width: 99px;
          //   font-size: 13px;
          //   background-color: transparent;
          //   box-shadow: none;
          //   &:hover {
          //     background-color: transparent;
          //     box-shadow: none;
          //   }
          //   // min-height: 47px;
          //   padding: 8px 0;
          //   text-transform: Capitalize !important;
          //   .MuiTouchRipple-root {
          //     display: none;
          //   }
          // }
        }
      }
    }
  }
}

.tooltop-declined {
  background-color: #fd4848;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 18px;
  text-align: center;

  span.message {
    margin-right: 5px;
  }
  button {
    color: white;
    svg {
      width: 20px;
      height: 20px;
      fill: white;
    }
    &:hover {
      background-color: #a82f2f;
    }
  }
}
.tooltop-voted {
  background-color: #00cfc0;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 18px;
  text-align: center;

  span.message {
    margin-right: 5px;
  }
  button {
    color: white;
    svg {
      width: 20px;
      height: 20px;
      fill: white;
    }
    &:hover {
      background-color: #00998f;
    }
  }
}
.MuiTooltip-popper > * {
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: "Montserrat" !important;
}
