// colors
@import "../_constants.scss";

$light: (
  heatmap-grid-height: 51px,
  heatmap-decoration-color: #00cfc0,
  slot-gradients:
    linear-gradient(
      -45deg,
      #a6a6a6 7.14%,
      #ffffff 7.14%,
      #ffffff 50%,
      #a6a6a6 50%,
      #a6a6a6 57.14%,
      #ffffff 57.14%,
      #ffffff 100%
    ),
  color-border-unactive: rgba(0, 0, 0, 0.23),
  color-border-hover: #747474,
  color-light-pop: $color-robineggblue,
  color-light-pop-opacity: transparentize($color-robineggblue, 0.85),
  color-light-pop-opacity-ripple: transparentize($color-robineggblue, 0.45),
  color-panel-dark: #f6f6f6,
  color-panel: #f6f8f9,
  color-licorice: #121212,
  color-robineggblue: #7060df,
  color-bittersweet: #fb735b,
  color-bittersweet-alpha: #fb735b33,
  color-bananayellow: #f7db3a,
  color-isabelline: #efefef,
  color-whitesmoke: #f4f4f4,
  color-icterine: #fbf45b,
  color-pear: #dee83a,
  color-acidgreen: #b1ca0e,
  color-aquamarine: #69eecf,
  color-mountainmeedow: #16b569,
  // color-medaquamarine: #65dcd5,
  color-tiffanyblue: #0aa7ac,
  color-peach: #fbc08a,
  color-lightpurple: #8886b1,
  // color-palecoper: #d98b72,
  // color-rajah: #fbaf6b,
  // color-cadmiumorange: #e68f2f,
  // color-carminepink: #d74d42,
  color-deepchesnut: #b94041,
  color-white: white,
  // colors referenced in stylesheets busyvert: $color-robineggblue,
  busyorange: $color-peach,
  busyrouge: #f44336,
  color-error: $color-carminepink,
  color-sidepanel-rgba: #fbfbfb,
  color-button-bg-hover: #f6f6f6,
  color-font-inactive: $color-taupegrey,
  color-font-active: $color-licorice,
  color-main-font-color: $color-licorice,
  color-unread: $color-carminepink,
  color-incoming-message-background: white,
  color-incoming-message-border: $color-isabelline,
  color-incoming-message-text: $color-licorice,
  color-outgoing-message-background: $color-whitesmoke,
  color-outgoing-message-text: $color-licorice,
  input-border-color: rgba(99, 127, 159, 0.2),
  // overlay background overlay-background: rgba(0, 0, 0, 0.5),
  // gradient blue-background-gradient: linear-gradient(317.05deg, #38d0fb 0%, #1aa3f5
  //      100%),
  // DIMENSIONS header-height: 80px,
  home-title-size: 64px,
  font-bold: 700,
  header-icon-dimension: 35px,
  main-header-font-size: 26px,
  header-font-size: 18px,
  header-font-weight: 500,
  header-icon-size: 18px,
  avatar-font-size: 13px,
  avatar-pills-font-size: 10px,
  avatar-pills-font-size-big: 36px,
  mini-font-size: 13px,
  main-font-size: 16px,
  title-font-size: 36px,
  title-font-weight: 900,
  secondary-font-size: 13px,
  message-font-size: 16px,
  avatar-dimension: 48px,
  avatar-pills-mode-dimension: 26px,
  pills-font-size: $secondary-font-size,
  right-side-panel-width: 410px,
  todo-x-header-height: 60px,
  // border radius common-box-border-radius: 5px,
  border-light-color: #e8edf0,
  border-grey-color: #bababa,
  border-grey: 1px solid #bababa,
  form-input-border-default: 1px solid #dadada,
  form-slots-panel-bg-color: #cbf5f2,
  // Done picker dp-active_color: $color-licorice,
  dp-unactive_color: #b9b8b8,
  dp-unactive_color2: #e8e8e8,
  shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.36),
  bg: white,
  //#fcfcfc,
  color: black,
  color-taupegrey: #888888,
  pop-color: $color-robineggblue,
  fade-color: $color-taupegrey,
  item-hover-background-color: rgba(0, 0, 0, 0.04),
  app-background-color: white,
  // #fcfcfc,
  done-button-background-color: $color-robineggblue,
  done-button-color: white,
  done-button-disabled-background-color: #f6f6f6,
  done-button-disabled-color: #636363,
  done-button-disabled-border-color: #63636333,
  dark-done-button-background-color: $color-licorice,
  dark-done-button-color: white,
  dark-done-button-disabled-background-color: $color-grey,
  dark-done-button-disabled-color: white,
  white-done-button-background-color: white,
  white-done-button-color: $color-licorice,
  breadcrumb-item-color: $color-licorice,
  breadcrumb-item-color-disabled: $color-taupegrey,
  breadcrumb-item-number-border: 1px solid $color-robineggblue,
  breadcrumb-item-number-border-disabled: 1px solid $color-taupegrey,
  create-event-step-color: $color-taupegrey,
  create-event-step-size: $secondary-font-size,
  create-event-step-title-color: $color-licorice,
  create-event-step-title-size: 32px,
  input-required-font-size: $main-header-font-size,
  input-error-font-color: $busyrouge,
  input-valid-font-color: $color-robineggblue,
  input-active-font-color: $color-taupegrey,
  input-font-color: $color-licorice,
  input-color-active-alpha: transparentize($color-robineggblue, 0.8),
  calendar-title-font-size: 20px,
  calendar-title-font-color: $color-licorice,
  calendar-email-font-size: 14px,
  calendar-email-font-color: $color-taupegrey,
  panel-title-font-size: 20px,
  space-small: 10px,
  space-medium: 20px,
  space-large: 40px,
  // ----------------
  // new LM constants
  // ----------------
  // => colors
  lm-color-pop: #7060df,
  lm-color-background: white,
  // => Spacing
  lm-space-xs: 5px,
  lm-space-small: 10px,
  lm-space-normal: 15px,
  lm-space-medium: 20px,
  lm-space-medium-plus: 30px,
  lm-space-paragraph: 24px,
  lm-space-large: 40px,
  lm-space-xxl: 50px,
  lm-space-accent: -12px,
  // => Text/Font
  lm-font-color-primary: #1b1b1b,
  lm-font-color-secondary: #636363,
  lm-font-color-tooltip: white,
  lm-font-color-slot: #7060df,
  lm-font-color-slot-busy: #fb735b,
  lm-font-color-day-hover: white,
  lm-font-color-proposal-primary: rgba(255, 255, 255, 0.95),
  lm-font-color-proposal-primary-light: #1b1b1b,
  lm-font-color-proposal-secondary: rgba(255, 255, 255, 0.65),
  lm-font-color-proposal-secondary-light: #636363,
  lm-font-size-xsmall: 10px,
  lm-font-size-small: 12px,
  lm-font-size-normal: 14px,
  lm-font-size-medium: 16px,
  lm-font-size-large: 18px,
  lm-font-size-xlarge: 24px,
  lm-font-size-popup-title: 20px,
  lm-font-weight-light: 300,
  lm-font-weight-normal: 400,
  lm-font-weight-semibold: 500,
  lm-font-weight-bold: 600,
  lm-font-weight-bold-plus: 700,
  lm-line-height-paragraph-normal: 1.5em,
  // => Borders
  lm-border-main-color: #dadada,
  lm-border-radius-input: 4px,
  lm-border-radius-normal: 5px,
  lm-border-radius-small: 3px,
  lm-shadow-normal: 2px 4px 15px rgba(130, 130, 130, 0.13),
  lm-shadow-medium: 2px 4px 15px rgba(130, 130, 130, 0.3),
  lm-shadow-xs: 0px 0px 3px rgba(130, 130, 130, 0.13),
  lm-shadow-xs-hover: 0px 3px 10px -2px rgba(0, 0, 0, 0.2),
  lm-shadow-slot1: 1px 1px 8px 1px rgba(101, 153, 255, 0),
  lm-shadow-slot2: 0px 1px 2px 0px rgba(101, 153, 255, 0),
  lm-shadow-slot1-busy: 1px 1px 8px 1px rgba(255, 101, 101, 0.2),
  lm-shadow-slot2-busy: 0px 1px 2px 0px rgba(255, 101, 101, 0.1),
  lm-border-slot: 1px solid #7060df,
  lm-border-slot-busy: 1px solid #fb735b,
  lm-border-tooltip: 1px solid #dadada,
  lm-border-color-event-hover: #7060df,
  lm-border-heatmap-grid-hour: 1px solid #dadada,
  lm-border-heatmap-grid-quarter: 1px dashed rgba(218, 218, 218, 1),
  lm-border-color-button-secondary: #dadada,
  lm-border-color-slot-shadow: #e3e3e3,
  // => Background
  lm-busy-background-color: rgb(246, 246, 246),
  lm-busy-background-color-hover: #e6e6e6,
  lm-slot-background-color: rgba(192, 213, 255, 0.5),
  lm-slot-background-color-busy: #fff1ef,
  lm-selection-background-color: rgba(192, 213, 255, 0.3),
  lm-background-color-event: #fafafa,
  lm-background-color-day-hover: #7060df,
  lm-background-color-secondary-info: #f6f6f6,
  lm-background-item-hover: #f6f6f6,
  lm-busy-background-tooltip: #1b1b1b,
  lm-background-proposal-side-panel: #1b1b1b,
  lm-background-proposal-side-panel-light: rgba(0, 0, 0, 0.01),
  lm-background-proposal-action-panel: white,
  lm-background-has-voted: #59ff60,
  lm-background-has-voted-light: #00a807,
  lm-background-destructive-action: #fb735b,
  lm-background-destructive-action-hover: darken(#fb735b, 10%),
  lm-lighten-app-color: lighten($color-robineggblue, 10%),
  lm-actions-border-top: 1px solid #636363,
  lm-timeline-line-color: #636363,
  lm-permissions-icon-color: $color-tiffanyblue,
  lm-accent-item-color: rgba(101, 153, 255, 0.1),
  lm-color-forced-slot: $color-peach,
  lm-color-promote-blurred: rgba(255, 255, 255, 0.5),
  premium-done-button-background-color: $color-icterine,
  premium-done-button-color: white,
  premium-done-button-disabled-background-color: $color-grey,
  premium-done-button-disabled-color: white,
  lm-color-funnel-yes: #C7EBDC,
  lm-color-funnel-no: #F8EBE5,
  lm-color-funnel-no-x: darken(#F8EBE5,80%),
  lm-color-funnel-open: #FFE0B2,
  lm-color-funnel-closed: #F5F5F5,
  lm-color-funnel-closed-text: #a5a5a5,

  lm-period-background-color: #FAFAFA,
  lm-period-color: #121212,
  lm-period-title-size: 18px,
  lm-period-title-weight: 600,
  lm-button-bow-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15),
  lm-button-bow-shadow-hover: 0px 6px 20px 5px rgba(0,0,0,0.10),
  lm-poll-bck-color: #C0E7D7,
  lm-funnel-bck-color: #B3E4FA,
  lm-period-poll-border: 1px solid #D5D5D5,
  lm-period-poll-border-radius: 5px,

  lm-newform-container-padding: 26px,
  lm-newform-container-padding-small: 16px,
  lm-newform-padding-top-bottom: 15px,
  lm-newform-padding-left-right: 40px,
  lm-newform-padding-left-right-negative: -40px,
  lm-newform-padding-left-right-negative-small: -20px,
  lm-newform-padding-top-bottom-small: 5px,
  lm-newform-padding-left-right-small: 20px,
  lm-poll-background-color:#C7EBDC,
  lm-funnel-background-color:#B3E4FA,

  lm-font-pop-color: #3D2DAA,
  lm-title-font-size: 28px,
);
