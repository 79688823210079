@import "../../../styles/themes";

.settings-invite {
  .invite-team {
    &.inactive {
      pointer-events: none;
      opacity: 0.8;
    }
    padding-bottom: 80px;
    @include mobileScreenOnly {
      padding-bottom: 20px;
      .mails {
        padding-top: 10px;
      }
    }
    svg.big {
      width: 24px;
      height: 24px;
    }
    svg.red {
      width: 16px;
      height: 16px;
    }
    .email-input {
      min-width: 400px;
      max-width: 80%;
      @include mobileScreenOnly {
        min-width: unset;
        width: 100%;
        max-width: 100%;
        margin: 6px 0;
      }
    }
    .move-down {
      margin-top: 35px;
      @include mobileScreenOnly {
        margin-top: 10px;
        width: 100%;
      }
      .sending-invite {
        margin-left: 5px;
      }
    }
  }
  .sharelink-title{
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #121212;
    padding-bottom: 30px;
  }
  .sharelink-copy {
    @include mobileScreenOnly {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .done-button.MuiButton-root {
        margin: 5px 0;
      }
    }
    .sharelink-url {
      display: inline-block;
      padding-right: 12px;
    }
  }
}
