@import "./styles/_themes.scss";
.done-button.MuiButton-root {
  white-space: nowrap;
  min-width: 162px;
  margin: 0 auto;
  border-radius: 50px;
  text-decoration: 0;
  font-size: 16px;
  padding: 8px 24px;
  @include mobileScreenOnly {
    padding: 2px 12px;
    white-space: unset;
    line-height: 1em;
    font-size: 14px!important;
  }
  &.density-medium {
    padding: 5px 20px;
  }

  @include themed() {
    color: t(done-button-color);
    background-color: t(done-button-background-color);
    border: 2px solid t(done-button-background-color);
  }
  cursor: pointer;

  &.MuiButton-text {
    &:hover,
    &:focus,
    &:active {
      @include themed() {
        color: t(done-button-color);
        background-color: t(done-button-background-color);
      }
    }
    &:hover {
      @include themed() {
        color: t(white-done-button-color);
        background-color: t(dark-done-button-color);
      }
    }
  }

  .MuiButton-label svg:first-child {
      margin-right: 5px;
      padding-right: 5px;
  }
  &.end-endornment{
      .MuiButton-label svg{
        margin-left: 5px;
        margin-right: -6px;
      }
  }
  .MuiButton-label .no-margin{
    svg{
      margin: 4px;
      padding: 0;
      &:first-child{
      
      }
    }
  }

  &.disabled {
    @include themed() {
      color: t(done-button-disabled-color);
      background-color: t(done-button-disabled-background-color);
      border: 2px solid t(done-button-disabled-border-color);
    }
    cursor: inherit;
    pointer-events: none;
    border: 0;
  }

  &.no-margin {
    margin: 0;
  }
  &.dark {
    @include themed() {
      color: t(dark-done-button-color);
      background-color: t(dark-done-button-background-color);
      border: 2px solid t(dark-done-button-background-color);

      &.MuiButton-text {
        &:hover,
        &:focus,
        &:active {
          @include themed() {
            color: t(done-button-color);
            background-color: t(dark-done-button-background-color);
            border: 2px solid t(dark-done-button-background-color);
          }
        }
      }
    }
  }
  &.grey {
    @include themed() {
      color: t(color-font-active);
      background-color: white;
      border: 2px solid t(lm-border-color-button-secondary);

      &.MuiButton-text {
        &:hover,
        &:focus,
        &:active {
          @include themed() {
            color: t(color-font-active);
            background-color: white;
            border: 2px solid t(color-licorice);
          }
        }
        &:hover {
          @include themed() {
            color: t(color-font-active);
            background-color: t(bg);
            border: 2px solid t(color-licorice);
          }
        }
      }
    }
    &:hover {
        border: 2px solid black;
    }
  }
  &.blue-outlined {
    @include themed() {
      color: t(done-button-background-color);
      background-color: white;
      border: 2px solid t(done-button-background-color);

      &.MuiButton-text {
        &:focus,
        &:active {
          @include themed() {
            color: t(done-button-background-color);
            background-color: white;
            border: 2px solid t(done-button-background-color);
          }
        }
        &:hover {
          @include themed() {
            color: white;
            background-color: t(done-button-background-color);
            border: 2px solid t(done-button-background-color);
          }
        }
      }
    }
  }
  &.light {
    @include themed() {
      color: t(color-robineggblue);
      background-color: white;
      border: 2px solid t(color-robineggblue);

      &.MuiButton-text {
        &:hover,
        &:focus,
        &:active {
          @include themed() {
            color: t(color-robineggblue);
            background-color: white;
          }
        }
      }
    }
  }
  &.white {
    @include themed() {
      color: t(white-done-button-color);
      background-color: t(white-done-button-background-color);
      border: 2px solid t(lm-font-color-secondary);

      &.MuiButton-text {
        &:hover,
        &:focus,
        &:active {
          @include themed() {
            color: t(white-done-button-color);
            background-color: t(white-done-button-background-color);
            border: 2px solid t(lm-font-color-primary);
          }
        }
      }
    }
  }
  &.big {
    min-width: 364px;
    font-size: 25px;
    min-height: 95px;
  }
  &.small {
    min-width: 99px;
    font-size: 16px;
  }
  &.form {
    padding: 8px 15px;
    font-size: 14px;
    min-width: 190px;
    .MuiButton-label  svg {
    margin: 0px;
    padding: 0px;
  }
  }

  &.premium {
    @include themed() {
      color: t(color-main-font-color);
      background-color: t(premium-done-button-background-color);
      border: 2px solid t(premium-done-button-background-color);

      &.MuiButton-text {
        &:hover,
        &:focus,
        &:active {
          @include themed() {
            color: t(color-main-font-color);
            background-color: t(premium-done-button-color);
            // border: 2px solid t(white-done-button-background-color);
          }
        }
      }
    }
  }
}
