@import "../../../../styles/themes";
@import "./common";

.unknwon-answer {
  @include timelineitem();
  @include with-icon("../../../../../assets/created.svg");
  @include themed() {
    @include with-line(1px solid t(lm-timeline-line-color));
  }
  @include themed() {
    font-size: t("lm-font-size-normal");
  }
  &.active {
    @include with-icon("../../../../../assets/waiting.svg");
    @include themed() {
      @include with-line(1px solid t(lm-timeline-line-color));
    }
    &:not(.small) .title {
      @include themed() {
        font-size: t("lm-font-size-medium");
        font-weight: t("lm-font-weight-bold");
      }
    }
    &.small {
      &::before {
        background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
          url("../../../../../assets/waiting.svg");
      }
    }
  }
  .invitee.unknown {
    @include themed() {
      margin: t("lm-space-xs") 0;
    }
    // text-indent: 1em;
  }
  .remaining {
    @include themed() {
      margin: t("lm-space-xs") 0;
    }
  }
}
