@import "../../../styles/themes";
@import "../../../styles/screens";

.seats-uploader {
  @include themed() {
    background: #f5f5f5;
    border: 1px dashed #dadada;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    &:hover,
    &.drag-accept {
      border: 1px dashed t(lm-font-color-primary);
      background-color: darken(#f5f5f5, 3%);
    }
    &.drag-accept {
      background-color: white;
    }
    svg {
      background-color: t(lm-color-pop);
      padding: 10px;
      border-radius: 50%;
      margin-bottom: 10px;
    }
    .primary-text {
      color: t(lm-font-color-primary);
      > span {
        font-weight: t(lm-font-weight-semibold);
        text-decoration: underline;
      }
    }
    .secondary-text {
      color: t(lm-font-color-secondary);
      font-size: t(lm-font-size-small);
    }
  }
}

.confirm-emails-seats-popup-title {
  .subtitle {
    @include themed() {
      font-size: t(lm-font-size-medium);
      color: t(lm-font-color-secondary);
      font-weight: t(lm-font-weight-normal);
    }
  }
}
