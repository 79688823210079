@import "../../styles/themes";

// common-box-border-radius: 6px,

.state-event {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border: solid 1px #bababa;
  @include themed() {
    border-radius: t(common-box-border-radius);
  }
  padding: 20px;

  svg {
    width: 100px;
    min-width: 100px;
    min-height: 100px;
    height: 100px;
    margin-bottom: 18px;
  }
  .state-message {
    font-size: 18px;
    // font-weight: 400;
    text-align: center;
    > .title {
      font-weight: 600;
    }
    > .subsubtitle {
      margin-top: 20px;
    }
  }
}
