@import "../../styles/_themes.scss";
@import "../../styles/_screens.scss";

@mixin with-icon {
  padding-left: 10px;
  position: relative;
  left: 10px;
  &::before {
    font-family: "Material Icons";
    position: absolute;
    left: -10px;
    font-size: 15px;
    line-height: 15px;
    top: 2px;
  }
}

.proposal-details-panel {
  background: #f5f8ff;
  display: flex;
  flex: 1;
  padding: 30px;
  padding-top: 52px;
  height: calc(100% - 60px);
  box-sizing: border-box;
  min-height: calc(100% - 20px); // for navigation btn

  @include smScreenAndSmaller {
    flex-direction: column;
    height: unset;
    min-height: unset;
  }

  & > button {
    align-self: flex-start;
    margin-top: -36px;
    border: 1.5px solid #d95f5f;
    color: #d95f5f;
    padding: 2px;
    margin-left: 16px;

    @include smScreenAndSmaller {
      position: absolute;
      right: 48px;
      margin-top: unset;
      top: 12px;
    }
  }
  .full-height-details-container {
    flex: 1;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 300px);

    @include smScreenAndSmaller {
      width: auto;
      margin-right: 0px;
    }

    .proposal-details-navigation {
      padding: 9px 0;
      color: #523fda;
      font-size: 13px;
      line-height: 16px;
      cursor: pointer;
      display: flex;
      .title {
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      .placeholder {
        width: 50%;
      }
      .navigation-prev,
      .navigation-next {
        display: flex;
        align-items: center;
        svg {
          border-radius: 50%;
          background-color: #523fda;
          color: white;
          margin-right: 5px;
        }
        width: 50%;
      }
      .navigation-next {
        text-align: right;
        justify-content: flex-end;
        svg {
          margin-right: 0px;
          margin-left: 5px;
        }
      }
    }
  }
  .proposal-details-container {
    position: relative;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    .proposal-details-header-container {
      padding: 14px;
      display: flex;
      flex-direction: column;
      .general {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .type {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #454545;
          padding: 4px 12px;
          margin-right: 9px;
          border-radius: 4px;
          text-transform: capitalize;
          svg {
            width: 20px;
            height: 20px;
          }
          &::first-letter {
            text-transform: uppercase;
          }
          &.type-funnel {
            @include themed() {
              background-color: t(lm-funnel-background-color);
            }
          }
          &.type-poll {
            @include themed() {
              background-color: t(lm-poll-background-color);
            }
          }
        }
        .created-at {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          align-items: center;
          flex: 1;
          /* Greyscale/Grey Defaut */
          color: #5b5b5b;
        }
        button {
          border: 1.5px solid #242222;
          width: 18px;
          height: 18px;
          box-sizing: border-box;
          font-size: 1rem;
          margin-left: 10px;
          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
      .proposal-time-and-location {
        display: flex;

        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        font-size: 13px;
        @include themed() {
          margin: t("lm-space-xs") 0;
        }
        @include mobileScreenOnly() {
          flex-direction: column;
        }
        & > span {
          @include with-icon();
          padding-left: 20px;
          left: 0;
        }
        .proposal-duration {
          @include themed() {
            padding-right: t("lm-space-medium");
          }
          &::before {
            content: "\e8b5";
            left: 0;
          }
        }
        .location {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          flex: 1;
          overflow-y: hidden;
          text-overflow: ellipsis;
          @include smScreenAndSmaller() {
            max-width: 250px; // because ellipsis need px dimensions...
          }
          &,
          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 0;
          }
          .text-block {
            max-width: 100%;
            text-overflow: ellipsis;
            //white-space: nowrap;
            overflow: hidden;
          }

          .conference {
            display: inline-flex !important;
          }
          span.proposal-duration::before {
            content: "\e0c8";
            left: 0;
          }
        }
        span.location-text {
          width: 100%;
        }
        .text-block {
          width: 100%;
          &::before {
            content: "\e0c8";
            left: 0;
          }
          .conference {
            display: inline-flex !important;
          }
        }
      }
      .proposal-title {
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #5b5b5b;
        margin: 20px 0 8px 0;
        max-width: 600px;
      }
      .proposal-notes {
        @include with-icon();
        &::before {
          content: "\e26c";
        }
        width: 100%;
        font-style: normal;
        font-size: 13px;
        line-height: 17px;
        color: #5b5b5b;
        margin: 8px 0;
        max-width: 600px;
        box-sizing: border-box;
        padding-right: 10px;
      }
      .proposal-participant {
        font-size: 13px;
        @include themed() {
          margin: 10px 0 t("lm-space-xs") 0;
        }
      }
    }
    .proposal-details-data-container {
      padding: 14px;
      .funnel-cmp {
        max-width: 600px;
        position: relative;
        .funnel-attendees .attendee-item .email-avatar .info {
          display: none;
        }
        .fullscreen-loader.centered-loader .lds-roller {
          left: 50%;
        }
      }
      .timeline {
        margin: 0 !important;
        max-width: 600px;
      }
      h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        font-variant: small-caps;
        color: #000000;
      }
      .section-subtext {
        margin-top: -10px;
        margin-bottom: 10px;
        font-size: 14px;
        @include themed() {
          color: t(lm-font-color-secondary);
        }
      }
    }
    .updating-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.65);
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      .MuiCircularProgress-root {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
  .proposal-details-actions {
    nav {
      padding: 0;
    }
    flex: 0 1;
    .MuiListItem-root,
    .action-important {
      background: #ffffff;
      /* Greyscale/Grey lightest */
      border: 1px solid #f5f5f5;
      border-radius: 8px;
      margin-bottom: 7px;
      min-width: 240px;
      padding: 8px 10px;
      box-sizing: border-box;
      .MuiListItemIcon-root {
        min-width: 30px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .MuiListItemText-primary {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #5b5b5b;
        margin-left: 0;
      }
    }
    .done-button {
      .MuiButton-label {
        font-size: 14px;
      }
      padding: 8px 16px;
      svg {
        margin-right: 2 !important;
        padding-right: 0 !important;
      }
    }
    .action-important {
      padding: 14px;
      display: flex;
      flex-direction: column;
      align-content: stretch;
      align-items: stretch;
      justify-content: space-between;
      .message {
        display: flex;
        font-weight: 500;
        font-size: 13px;
        line-height: 120%;
        color: #5b5b5b;
        .alert {
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ec4d2c;
          width: 26px;
          height: 26px;
          margin-top: 4px;
          box-sizing: border-box;
          color: white;
          flex-shrink: 0;
          margin-right: 4px;
          svg {
            color: white;
            width: 18px;
            height: 18px;
          }
        }
        &.coffee {
          flex-direction: column;
          align-items: center;
          font-weight: 700;
          h1,
          h2 {
            font-size: 12px;
            line-height: 150%;
            color: #5b5b5b;
          }
          h1 {
            font-weight: 700;
          }
          h2 {
            font-weight: 500;
            &.hidden {
              display: none;
            }
          }
          .simple-icon {
          }
        }
      }
      a {
        font-weight: 500;
        font-size: 13px;
        /* line-height: 120%; */
        color: #5b5b5b;
        text-align: center;
        margin-top: 8px;
        text-decoration: underline;
      }
      .done-button {
        width: 100%;
        margin-top: 12px;
      }
    }
    nav {
      padding-top: 0;
    }
  }
}
