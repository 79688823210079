@import "../../styles/themes";

.stats-accounts {
  @include themed() {
    margin-bottom: t("lm-space-large");
    @include lgScreenAndSmaller() {
      margin-bottom: 0;
    }
    .profile {
      display: flex;
      align-items: flex-start;
      margin-bottom: t("lm-space-large");
      @include lgScreenAndSmaller() {
        margin-bottom: t("lm-space-medium");
      }
      img {
        width: 72px;
        height: 72px;
        @include mobileScreenOnly() {
          width: 48px;
          height: 48px;
        }
      }
      .profile-details {
        padding-left: t(lm-space-normal);
        text-align: left;
        .title {
          color: t(lm-font-color-primary);
          font-size: t(lm-font-size-medium);
          font-weight: t(lm-font-weight-semibold);
          text-transform: uppercase;
        }
        .emails {
          font-size: t(lm-font-size-normal);
          color: t(lm-font-color-secondary);
          font-weight: t(lm-font-weight-light);
        }
      }
    }
    .availability {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: t(lm-font-weight-semibold);
        letter-spacing: 1px;
        color: t(lm-font-color-secondary);
        margin-bottom: t("lm-space-medium");
        @include lgScreenAndSmaller() {
          margin-bottom: t("lm-space-small");
        }
        svg {
          margin-right: t(lm-space-xs);
        }
      }
      a.item {
        margin-bottom: t(lm-space-small);
        @include lgScreenAndSmaller() {
          margin-bottom: t("lm-space-xs");
        }
        text-align: left;
        text-decoration: none;
        color: t(lm-font-color-primary);
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
        svg {
          margin-left: t(lm-space-small);
          color: t(lm-font-color-secondary);
          width: t(lm-font-size-normal);
          height: t(lm-font-size-normal);
        }
      }
    }
    .visibility {
      color: t(lm-font-color-primary);
      line-height: 1.3em;
      display: flex;
      // padding-bottom: 20px;//t("lm-space-small");
      strong {
        font-weight: t(lm-font-weight-bold);
        background-color: rgba(0, 0, 0, 0.03);
      }
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      svg {
        margin-top: 1px;
        margin-left: t(lm-space-small);
        color: t(lm-font-color-secondary);
        width: t(lm-font-size-normal);
        height: t(lm-font-size-normal);
      }
    }
  }
}
.network-share-popover-container {
  margin-top: 10px;
  .MuiPaper-root {
    max-width: 620px;
  }
}
.network-share-popover {
  @include themed() {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    // Fieldset {
    //   display: flex;
    .MuiFormLabel-root {
      // padding: t('lm-space-medium');

      width: 100%;
      box-sizing: border-box;
      &.opened {
        background-color: t("lm-background-color-event");
      }
    }
    .MuiFormGroup-root {
      // border: t("lm-border-heatmap-grid-hour");
      padding: t("lm-space-small") t("lm-space-normal");
      .MuiTypography-body1 {
        font-size: t(lm-font-size-normal);
      }
      .label-with-icon {
        display: flex;
        align-items: center;
        svg {
          margin-left: t("lm-space-small");
        }
      }
      .MuiFormControlLabel-root.disabled {
        .MuiFormControlLabel-label {
          opacity: 0.6;
        }
        .MuiIconButton-root {
          svg { // that's the checkbox
            // fill: grey;
            opacity: 0.6;
          }
        }
      }
      // }
    }
    .note {
      font-size: t(lm-font-size-normal);
      color: t(lm-font-color-secondary);
      padding: 0 t(lm-space-medium) t(lm-space-medium) t(lm-space-medium);
    }
  }
}
