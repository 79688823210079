.FreeBusyCalendars {
  
    .item {
        &.account {
            padding-left: 0px !important;
            .container{
              flex-direction: column;
            }
        }
    }
    .provider {
        margin-bottom: 10px;
    }
    .calendar-item {
        padding: 0px !important;
        div:last-child {
            margin-left: 10px;
            font-weight: 300;
            &.selected {
                font-weight: 500;
            }
        }
        padding: 4px 0 0 0 !important;
        .MuiCheckbox-root {
            padding: 0px !important;
        }
        .MuiSvgIcon-root {
            margin-bottom: 0px !important;
            width: 24px !important;
            height: 24px !important;
            margin-right: 0 !important;
        }
        .MuiCircularProgress-root {
            width: 24px !important;
            height: 24px !important;
            // margin-right: 8px;
            .MuiCircularProgress-svg {
                margin-bottom: 0px !important;
                width: 24px !important;
                height: 24px !important;
                margin-right: 0px !important;
            }
        }
    }
}
