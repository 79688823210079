@import "../styles/_themes.scss";
.connect-menu-button, .menu-more {
    svg {
        margin-right: 0!important;
        padding-right: 0!important;
    }
}
.connect-menu {
    .MuiPaper-root {
        box-shadow: 1px 4px 10px rgba(181, 181, 181, 0.13);
        border-radius: 5px;
        border: 1px solid #DADADA;
        @include themed() {
            padding: t(lm-space-normal) t(lm-space-small);
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            max-width: 600px;
            .connect-item-group {
                display: flex;
                flex-direction: column;
                padding: t("lm-space-medium") t("lm-space-medium") 0 t("lm-space-medium");
                flex-grow: 0;
                flex: 50% 0 0;
                box-sizing: border-box;
                .group-title {
                    font-size: t("lm-font-size-large");
                    font-weight: t("lm-font-weight-bold");
                    margin-left: 10px;
                    padding-bottom: 0;
                }
                .group-items {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    padding: t("lm-space-small") t("lm-space-small") t("lm-space-small") 0;
                    margin-left: -10px;
                    a {
                        color: t("lm-font-color-primary");
                        &:visited {
                            color: t("lm-font-color-primary");
                        }
                    }
                    .connect-item {
                        &.active {
                            cursor: pointer;
                        }
                        flex: 50% 0 0;
                        box-sizing: border-box;
                        font-size: t("lm-font-size-normal");
                        line-height: 1.5em;
                        .title {
                            display: flex;
                            align-items: center;
                            font-size: t("lm-font-size-medium");
                            font-weight: t("lm-font-weight-semibold");
                            padding-bottom: t("lm-space-xs");
                            svg {
                                width: t("lm-font-size-large");
                                height: t("lm-font-size-large");
                                margin-right: t("lm-space-xs");
                            }
                        }
                        padding: t(lm-space-medium);
                        &:hover {
                            background-color: rgba(0,0,0,0.03);
                        }
                    }
                }
                &.full-width,
                .full-width {
                    flex: 100% 1 0 !important;
                }
            }
        }
    }
}
