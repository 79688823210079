@import "../../../styles/themes";
@import "../../../styles/screens";

$margin-top: 30px;
$min-width: 45px;
$max-width: unset;
$slot-height: 44px;
$slot-width: 44px;
$slot-max-width: 88px;
@mixin proposal-type-none {
  @include themed() {
    background-color: #efefef87;
  }
  &::before {
    content: "";
    @include themed() {
      color: t("lm-color-funnel-closed-text");
    }
  }
}
@mixin proposal-type-open {
  @include themed() {
    background-color: t("lm-color-funnel-open");
  }
  &:before {
    font-family: "Material Icons";
    content: "\e88b";
    color: #ffae64;
  }
}
@mixin proposal-type-cross {
  &:not(.set) {
    &::before {
      font-family: "Material Icons Outlined";
      content: "\e5c9";
      @include themed() {
        color: #d95f5f;
      }
    }
  }
  @include themed() {
    background-color: t("lm-color-funnel-no");
  }
}
@mixin proposal-type-vote {
  @include themed() {
    background-color: t("lm-color-funnel-yes");
    &::before {
      font-family: "Material Icons Outlined";
      content: "\e86c";
      color: #00943b;
    }
  }
}

.funnel-container {
  // display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   justify-content: flex-start;
}
.funnel-cmp {
  margin-bottom: 40px;
  //width: fit-content;
  @include smScreenAndSmaller() {
    margin-bottom: 10px;
  }
  &.non-interactive .funnel .hoverable-group {
    pointer-events: none;
  }

  .title {
    button {
      padding: 0;
      .MuiButton-label {
        text-align: left;
        padding: 0 0 0 20px;
        white-space: nowrap;
        @include themed() {
          font-size: t(lm-font-size-small) !important;
          font-weight: t(lm-font-weight-normal);
        }
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-decoration: underline;
      }
    }
  }
  .subtitle {
    text-align: left;
    padding: 0 20px;
    @include themed() {
      font-size: t(lm-font-size-normal);
      font-weight: t(lm-font-weight-normal);
    }
    b {
      @include themed() {
        font-weight: t(lm-font-weight-bold);
      }
    }
  }
}
.inactive {
  .funnel-attendees,
  .hoverable-group {
    pointer-events: none;
  }
}
.funnel {
  display: flex;
  //justify-content: center;
  align-items: flex-start;
  margin: 10px 0px 0 0px;
  // max-height: 300px;
  overflow-y: auto;
  flex-wrap: wrap;
  @include smScreenAndSmaller() {
    margin: 10px 0px;
  }
  .funnel-attendees {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;

    margin-top: 39px;
    background: #f5f8ff;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #f5f8ff;
    & > div:not(.placeholder) {
      border-top: 1px solid white;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      height: $slot-height + 12px;
      & > div {
        width: 100%;
      }
    }
    .attendee-item {
      &.placeholder {
        pointer-events: none;
        opacity: 0;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #5b5b5b;
        margin: 16px auto 10px auto;
        text-transform: uppercase;
        width: 38px;
        height: unset;
      }

      display: flex;
      align-items: center;
      //text-align: right;
      padding-right: 2px;
      padding: 0 10px;
      // margin: 1px;
      box-sizing: border-box;
      // border-radius: 30px;
      //text-decoration: underline;
      // button {
      //   display: none;
      // }
      @include smScreenAndSmaller() {
        padding: 0 5px;
      }
      span:not(.LMTooltip-children) {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
      }
      @include themed() {
        font-weight: t(lm-font-weight-normal);
      }
      span:not(.has-voted) {
        @include themed() {
          font-weight: t(lm-font-weight-semibold);
        }
      }
      &:hover {
        // button {
        //   display: block;
        // }
        // background-color: rgba(0, 0, 0, 0.08);
        cursor: pointer;
      }
      span.optional {
        font-style: italic;
        @include themed() {
          font-weight: t(lm-font-weight-normal);
        }
      }
      &.organizer {
        pointer-events: none;
        text-decoration: none;
        width: 100%;
        span {
          @include themed() {
            font-weight: t(lm-font-weight-normal);
          }
        }
      }
      .email-avatar {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        .avatar-state {
          &::before {
            content: "";
            background-color: white;
            border-radius: 50%;
            position: absolute;
            top: 4px;
            left: 4px;
            bottom: 4px;
            right: 4px;
          }
          .Avatar {
            width: 36px;
            height: 36px;
            font-size: 10px;
            line-height: 15px;
            color: #242222;
            position: relative;
          }
        }

        .info {
          padding-left: 8px;
          font-size: 10px;
          display: flex;
          flex-direction: column;
          span {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            @include themed() {
              color: t(lm-font-color-secondary);
              &:first-child {
                color: t(lm-font-color-primary);
                font-size: t(lm-font-size-small);
                font-weight: t(lm-font-weight-semibold);
              }
            }
          }
        }
      }
    }
  }
  .funnel-votes-with-dates {
    display: grid;
    overflow-x: auto;
    padding: 0 5px;
    padding-left: 0;
    padding-bottom: 10px;
    position: relative;
    flex: 1;
    @include smScreenAndSmaller() {
      padding: 0;
      padding-bottom: 10px;
    }
    .funnel-date-container {
      display: flex;
      position: relative;
      flex: 1;
      // min-height: $margin-top;
      z-index: 2;
      margin-bottom: 10px;
      // padding: 0 10px 0 10px;
      background-color: white;

      .funnel-date {
        font-size: 10px;
        position: relative;
        height: 30px;
        white-space: nowrap;
        box-sizing: border-box;
        flex: 1;
        font-weight: 500;
        z-index: 1;
        max-width: $max-width;
        min-width: $min-width;
        pointer-events: none;
        display: flex;
        align-items: flex-end;
        overflow-x: visible;
        max-width: $slot-max-width + 12px;
        &.filtered-out {
          display: none;
        }
        .the-date {
          display: block;
          background-color: white;
          padding: 0px 1px;
          box-sizing: border-box;
          width: 100%;
          text-align: center;
          height: 1px;
          // transform: rotateZ(-45deg);
          // transform-origin: top left;
        }

        // &.even {
        //   //background-color: rgb(212, 212, 212);
        //   // .the-date {
        //   //   background-color: #efefef;
        //   // }
        // }
      }
      .funnel-day {
        //width: 100%;
        overflow: visible;
        z-index: 0;
        position: absolute;
        // top: -10px;
        // left: 0px;
        // right: 0px;
        // bottom: 0px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        white-space: nowrap;
        // transform: rotateZ(-45deg);
        // transform-origin: top left;
        @include themed() {
          font-weight: t(lm-font-weight-bold);
        }
        pointer-events: none;
        &.large {
          transform: none;
          // &:before{
          //   transform: rotateZ(-90deg);
          //   bottom: 82px;
          //   left: -13px;
          // }
        }
        & > span {
          background-color: white;
          display: block;
          text-align: left;
          padding: 0 4px;
          box-sizing: border-box;
        }
        // &:before {
        //   content: "";
        //   position: absolute;
        //   border-bottom: 1px solid;
        //   width: 10px;
        //   height: 1px;
        //   transform: rotateZ(-45deg);
        //   bottom: 82px;
        //   left: -13px;
        // }
      }

      & > span:before {
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #242222;
        bottom: 5px;
        content: "";
      }
    }
    .fixed-header {
    }
  }
  .funnel-votes {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    // padding: 0 5px;
    // padding-bottom: 10px;
  }
  .hoverable-group {
    display: flex;
    position: relative;
    flex: 1;
    max-width: $slot-max-width + 12px;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;

    &:hover {
      z-index: 101;
      .the-date {
        font-weight: 700;
      }
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.filtered-out {
      display: none;
    }
    .fattendees {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      bottom: -2px;
      top: -20px;
      left: -2px;
      z-index: 100;
      background-color: #000000ba;
      color: white;
      padding-left: 40px;
      padding-right: 5px;
      padding-bottom: 2px;
      z-index: 2;
      border-radius: 4px;
      z-index: 1;
      pointer-events: none;
      span {
        line-height: $slot-height;
        padding: 1px;
      }
      span.fdate {
        line-height: initial;
        font-size: x-small;
        white-space: nowrap;
      }
      &.ltr {
        bottom: -2px;
        top: -20px;
        right: -2px;
        left: unset;
        align-items: flex-end;
        padding-right: 40px;
        padding-left: 5px;
      }
    }

    .simple-flip-btn {
      flex: 1;
    }
  }
  .funnel-group {
    .the-date {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #5b5b5b;
      margin: 16px auto 10px auto;
      text-transform: uppercase;
      width: 25px;
      &.meeting-set {
        font-weight: 700;
      }
    }
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0px;
    top: 0px;
    //margin-top: $margin-top;
    // padding-bottom: 10px;
    max-width: $slot-max-width + 12px;
    box-sizing: border-box;
    &.alpha:not(.hovering) {
      opacity: 0.5;
    }

    .funnel-slot {
      min-width: $slot-width;
      max-width: $slot-max-width;
      height: $slot-height;
      margin: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 8px;
      &.none {
        @include proposal-type-none();
      }
      &.open {
        @include proposal-type-open();
      }
      &.cross {
        @include proposal-type-cross();
      }
      &.vote {
        @include proposal-type-vote();
      }
      &.optional {
        opacity: 0.5;
      }
      &.set {
        z-index: 0;
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include themed() {
          background-color: t("lm-color-funnel-yes");
        }
      }
    }
    &.confirmed {
      &.set {
        content: "";
        position: absolute;
        border: 2px solid #000000aa;
        z-index: 50;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        margin-bottom: -2px;
        margin-top: -2px;
      }
      &:not(.set) {
        opacity: 0.5;
      }
    }
    &.hovering {
      cursor: pointer;
      box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.282), 1px 1px 4px rgba(0, 0, 0, 0.5);
      z-index: 50;
      .funnel-slot::after {
        content: "";
        position: absolute;
        border: 2px solid #000000aa;
        z-index: 50;
        left: -1px;
        top: -1px;
        right: -1px;
        bottom: -1px;
        pointer-events: none;
      }
      .funnel-slot {
        z-index: 3;
      }
    }
    &.meeting-set {
      position: relative;
      max-width: $slot-max-width + 12;
      box-sizing: border-box;
      border: 1px solid #f5f5f5;
      @include themed() {
        // background-color: t("lm-color-funnel-yes");
        // box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.1);
        // border-radius: 4px;
        .the-date {
          //margin-top: 8px;
          z-index: 3;
        }
        &::before {
          z-index: 1;
          background-color: t("lm-color-funnel-yes");
          box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          position: absolute;
          top: 6px;
          bottom: 6px;
          left: 6px;
          right: 6px;
          content: "";
        }
        &::after {
          z-index: 2;
          font-family: "Material icons";
          content: "\e878";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          bottom: 0;
          left: 0;
          right: 0;
          line-height: 1em;
          font-size: 24px;
          height: $slot-height - 4px;
          margin: 3px auto;
          aspect-ratio: 1;
          border-radius: 8px;
          background-color: white;
          color: #2e9457;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .funnel-group {
        .funnel-slot {
          max-width: $slot-max-width - 4px;
          min-width: $slot-width - 4px;
        }
      }
    }
  }
  &.no-grow {
    .funnel-votes {
      // flex-grow: 0;
    }
  }
  .hoverable-group.proposed-by {
    .proposed-by-att {
      position: absolute;
      background-color: #7060df;
      border-radius: 8px;
      color: white;
      font-size: 10px;
      width: calc(100% - 20px);
      margin: 0 8px;
      text-align: center;
      border: 2px solid white;
    }
  }
}

.funnel-cmp .fullscreen-loader.centered-loader .lds-roller {
  left: unset;
  z-index: 9001; // ensure hover flip confirm
}
.funnel-dlg .fullscreen-loader.centered-loader .lds-roller {
  left: 50%;
  z-index: 9001; // ensure hover flip confirm
}
.FunnelLegend {
  //width: min-content;
  text-align: left;
  margin-top: 8px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #454545;
  flex: 1;
  width: 100%;
  .funnel-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    margin: 8px 16px 8px 16px;
  }
  .legend-item {
    display: inline-block;
    white-space: nowrap;
    margin-right: 16px;

    display: flex;
    align-items: center;
    .legend-shape {
      @include themed() {
        margin-right: t(lm-space-xs);
      }
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &.vote {
        @include proposal-type-vote();
      }
      &.cross {
        @include proposal-type-cross();
      }
      &.open {
        @include proposal-type-open();
      }
      &.none {
        @include proposal-type-none();
      }
      @include themed() {
        &:before {
          content: "" !important;
        }
      }
    }
  }
  .funnel-cmds {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 16px 8px 16px;
    //width: 100%;
    flex: 1;
    border-top: 1px solid #f5f5f5;
    .cmds {
      display: flex;
      flex: 1;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      box-sizing: border-box;
      .cmds {
        display: flex;
        flex: 1;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
        box-sizing: border-box;
        padding: 0 0 0 10px;
        font-size: 12px !important;
      }
      @include smScreenAndSmaller() {
        padding: 10px 2px 0 2px;
      }
    }
  }
}

.MatrixFilter {
  display: flex;
  align-items: center;
  @include themed() {
    font-size: t(lm-font-size-small);
  }
}

.LMTooltip > .matrix-guest-menu {
  @include themed() {
    margin: 6px 0;
    // > div:first-child {
    //   &:not(.item) {
    //     padding: 8px 0;
    //     font-weight: t(lm-font-weight-semibold);
    //     border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    //   }
    // }
    .attendee-email {
      width: 100%;
      text-align: center;
      font-weight: t(lm-font-weight-semibold);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding: 5px 0 15px 0;
    }
    > div.item {
      font-size: t(lm-font-size-normal);
      font-weight: t(lm-font-weight-semibold);
      padding: 8px 8px;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }
      > div:last-child {
        font-size: t(lm-font-size-small);
        font-weight: t(lm-font-weight-normal);
      }
      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.95);
        color: t(lm-font-color-primary);
      }
    }
  }
}
.slots-dlg-title {
  @include smScreenAndSmaller() {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  h2 {
    @include smScreenAndSmaller() {
      margin: 0;
    }
  }
}

/*
@media (orientation: portrait) {
  .portrait-orientation{
    .MuiDialog-container {
      transform: rotateZ(90deg) translate(-0%, -100%);
      width: 100vh;
      height: 100vw;
      transform-origin: top left;
    }
  }
}
*/
.flip-warning {
  @include themed() {
    margin-right: t(lm-space-small);
    font-weight: t(lm-font-weight-semibold);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: column;
    // &::after {
    //   content: url(../../../../assets/not_available.svg);
    //   margin-right: t(lm-space-xs);
    //   position: relative;
    //   top: 2px;
    // }
  }
}

.skeleton {
  animation: skeleton-pulse 1.5s ease-in-out 0.5s infinite;
  margin: auto;
  max-width: 800px;
  .skeleton-text {
    color: transparent;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .attendee-item.organizer {
    width: 240px;
    border-radius: 0;
  }
  .legend-shape,
  .funnel-slot,
  .funnel-date {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .funnel-date {
    margin: 0 1px 2px;
  }
}
@keyframes skeleton-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
