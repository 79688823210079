@import "./constants";

@mixin badge {
    border-radius: 40px;
    background-color: $color-unread;
    height: 18px;
    font-size: 10px;
    color: white;
    align-self: center;
    padding: 3px 5px;
    min-width: 18px;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

// used for icon buttons
@mixin svgButton {
    svg {
        width: 28px;
    }
}

@mixin headerText {
    color: $color-main-font-color;
    font-size: $header-font-size;
    font-weight: $header-font-weight;
}

.formSvgIcon {
    width: 18px;
    height: 18px;
}

.addMenuIcon {
    width: 24px;
    height: 24px;
    color: $color-licorice;
}

.iconButtonSvgIcon {
    width: 28px;
    height: 28px;
    // transition: 140ms;
}
// hover on any icon button
.MuiSwitch-root {
    .MuiIconButton-root:hover {
        background-color: $color-isabelline;
        // i,
        // svg {
        //     // transform: scale(1.05);
        // }
    }
}

@mixin selectableItem {
    position: relative;
    &:hover::before {
        background-color: black;
        opacity: 0.04;
        cursor: pointer;
    }

    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background-color: black;
        opacity: 0;
    }
}
