@import "../../../styles/themes";

.settings-dev {
    .valign-center{
        align-items: center;
    }
    .section>div {
        &:first-child {
            flex: 1;
        }
    }
}