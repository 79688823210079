@import "../../styles/_themes.scss";
@import "../../styles/constants";
#main-nav {
  display: flex;
  align-items: center;

  .extension {
    display: flex;
    align-items: center;
    white-space: nowrap;
    svg {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
  }
  a.ext-link {
    @include lgScreenAndSmaller {
      display: none!important;
    }
  }
  .connect-menu-button,
  .menu-more {
    @include smScreenAndSmaller {
      display: none!important;
    }
  }
  .hide-on-mobile {
    @include smScreenAndSmaller {
      display: none!important;
    }
  }
  .nav-link {
    @include themed() {
      color: t(lm-font-color-primary);
      height: 1.4em;
      font-size: 16px;
      @include lgScreenAndSmaller {
        font-size: 16px;
      }
      @include smScreenAndSmaller {
        font-size: 14px;
      }
      display: flex;
      align-items: center;
      margin: 0 10px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      font-weight: t(lm-font-weight-semibold);
      &:hover {
        // text-decoration: underline;
        border-bottom: 2px solid black;
      }
      &.selected {
        font-weight: t(lm-font-weight-bold);
        border-bottom: 2px solid black;
      }
    }
    &.nav-link-more {
      margin-right: 20px;
    }
  }
  .nav-link-more {
    svg {
      margin-right: -4px;
    }
  }
  .create-meeting {
    margin-right: 20px;
    box-sizing: border-box;
    transition: none;
    font-size: 16px;
    padding: 5px 24px;
    // height: 48px;
    .mobile {
      display: none;
    }
    @include lgScreenAndSmaller {
      min-width: unset;
      width: unset;
      padding: 5px 18px;
      font-size: 16px;
      height: unset;
      @include smScreenAndSmaller {
        margin-right: 10px;
        font-size: 14px;
        padding: 5px 10px;
      }
      @include mobileScreenOnly {
        .desktop {
          display: none;
        }
        .mobile {
          display: block;
        }
        padding: 4px 6px;
      }
    }
    // width: 100%;
  }
  .menu-items {
    display: flex;
    align-items: center;

    .profile-avatar {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        width: 1.3em;
        height: 1.3em;
      }
      img {
        // -webkit-filter: grayscale(100%);
        // filter: grayscale(100%);
      }
      > div,
      &::before {
        transition: 100ms ease-in;
      }
      &:hover {
        > div {
          transform: scale(0.93);
        }
        &::before {
          content: "";
          position: absolute;
          // border: 3px solid black;
          // background-color: rgba(0, 0, 0, 0.4);
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          border-radius: 50%;
        }
      }
    }
    &.selected {
      @include themed() {
        font-size: t(header-font-size);
        color: t(color-robineggblue);
        font-weight: 900;
      }
    }
    a {
      @include themed() {
        color: t(lm-font-color-primary);
      }
      // &.selected {
      //   @include themed() {
      //     font-size: t(header-font-size);
      //     color: t(color-robineggblue);
      //     font-weight: 900;
      //   }
      // }
      // svg {
      //   transition: transform 0.15s ease-out;
      // }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .iconified svg {
    width: 24px;
    height: 24px;
  }
  // manage menu on mobile
  .BurgerMenu {
    // by default do not show burger handle
    display: none;
  }
  // elements only visible in burger menu
  .in-burger-only {
    display: none;
  }

  @include lgScreenAndSmaller {
    .menu-items {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: white;
      z-index: 102;
      display: none;
      padding: 30px 10px 20px 10px;
      flex-direction: column;
      > * {
        display: block;
        margin: 0 !important;
        margin-bottom: 30px !important;
        width: 100%;
      }

      > :not(.in-burger) {
        display: none;
        // @include mobileScreenOnly {
        &.force-in-header {
          display: block;
        }
        // }
      }

      &.force-in-header {
        display: block;
      }
      .in-burger-only {
        display: block;
      }
      &.mobile-menu-visible {
        display: flex;
      }
    }
    .BurgerMenu {
      // show burger handle
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 36px;
        height: 36px;
      }
    }
  }

  // overlay
  .mobile-menu-overlay {
    @include xlScreenOnly {
      display: none;
    }
    position: fixed;
    content: "";
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $overlay-background;
    z-index: 101;
  }
}

.profile-menu {
  .MuiPaper-root {
    padding: 10px 0;
    .MuiMenuItem-root {
      padding: 10px 20px;
      a {
        color: inherit;
      }
    }
  }
}
