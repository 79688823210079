@import "../../../../styles/_themes.scss";
@import "../../../../styles/_screens.scss";
.week-selector {
  @include themed() {
    font-size: t(lm-font-size-large);
    color: t(color-taupegrey);
  }
  @include mobileScreenOnly() {
    width: 100%;
    @include themed() {
      font-size: t(secondary-font-size);
    }
  }
  display: flex;
  justify-content: flex-start;
  margin: 0;
  & > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobileScreenOnly() {
      width: 100%;
      justify-content: space-between;
    }
    button {
      padding: 0;
      font-size: 10px !important;
    }
    .prev-next {
      background-color: #f7f7f8;
      border-radius: 20px;
      button {
        &:hover {
          @include themed() {
            background-color: t(lm-color-pop);
            // background-color: #f5f8ff;
            svg {
              color: white;
            }
          }
        }
        padding: 0;
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
    .periodStr {
      display: flex;
      align-items: center;
      margin: 0 8px 0 8px;
      white-space: nowrap;
      @include themed() {
        color: t(lm-font-color-primary);
        font-weight: t(lm-font-weight-semibold);
      }
      cursor: pointer;
      @include mobileScreenOnly() {
        margin: 0 6px 0 6px;
      }
    }
    .todayBtn {
      svg {
        margin-right: 5px;
        width: 18px;
        height: 18px;
      }
      margin-right: 8px;
      text-transform: initial;
      white-space: nowrap;
      @include themed() {
        font-size: t(mini-font-size);
      }
      @include mobileScreenOnly() {
        @include themed() {
          font-size: t(avatar-pills-font-size);
        }
      }
    }
  }
}
.pick-a-period {
  .week {
    &:last-child {
      margin-bottom: 0;
    }
    .donepicker-date {
      @include themed() {
        border-radius: t(lm-border-radius-normal);
        font-size: t(lm-font-size-large);
      }
      justify-content: center;
      border: 0 !important;
      &::after,
      &::before {
        border: 0 !important;
      }
      &:hover {
        @include themed() {
          background-color: t(lm-background-color-day-hover);
          color: t(lm-font-color-day-hover);
        }
      }
      .day {
        display: none;
      }
      svg.action {
        display: none;
      }
    }
  }
}
