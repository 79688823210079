@import "./components/styles/_themes.scss";
@import "./components/styles/_screens.scss";
$xl_header_height: 69px;
$lg_header_height: 70px;
$xs_header_height: 60px;
html{
  background-color: white;
}
.App {
  @include themed() {
    color: t(color);
    background: t(bg);
  }
  text-align: center;
  width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  // Ensure child is growing as much as possible
  & > div {    
    flex-grow: 1;
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    background-color: white;
    height: $xl_header_height;
    @include lgScreenAndSmaller {
      height: $lg_header_height;
    }
    @include mobileScreenOnly() {
      height: $xs_header_height;
    }
  }
  section {
    margin-top: $xl_header_height;
    @include lgScreenAndSmaller {
      margin-top: $lg_header_height;
    }
    @include mobileScreenOnly() {
      margin-top: $xs_header_height;
    }
    .page-content {
      .header {
        position: sticky;
        top: $xl_header_height;
        z-index: 999;
        background-color: white;
        @include lgScreenAndSmaller {
          top: $lg_header_height;
        }
        @include mobileScreenOnly() {
          top: $lg_header_height;
        }
      }
    }
  }
  .test-only{
    display: none;
  }
  .no-calendar{
    display: flex;
    white-space: pre-line;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    margin: auto;

    a{
      padding: 10px;
    }
  }
}

// .fancy-scroll::-webkit-scrollbar-track,
// body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//   background-color: #f5f5f5;
// }

// .fancy-scroll::-webkit-scrollbar,
// body::-webkit-scrollbar {
//   width: 3px;
//   background-color: #f5f5f5;
// }

// .fancy-scroll::-webkit-scrollbar-thumb,
// body::-webkit-scrollbar-thumb {
//   background-color: $color-robineggblue;
// }
// .fancy-scroll {
//   overflow-y: auto;
// }

.fancy-scroll::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  background-color: #fff; //#f5f5f5;
  border-radius: 50px;
}

.fancy-scroll::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 7px;
  background-color: #fff; //#f5f5f5;
}

.fancy-scroll::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #DADADA; //$color-robineggblue;
  border-radius: 50px;
  &:hover {
    background-color: $color-robineggblue;
  }
}
.fancy-scroll {
  overflow-y: auto;
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  animation: fade-in 1s;
  display: flex;
}
.tooltop-error {
  margin: auto;
  @include themed() {
    background-color: t(color-bittersweet);
    color: white;
    font-size: t(lm-font-size-normal)
  }
  a {
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: underline;
  }
  display: flex;
  padding: 2px;
  align-items: center;
  justify-content: center;
  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  & > span {
    margin-left: 10px;
  }
}
.bold{
  font-weight: 600;
}
