@import "../../styles/_themes.scss";
@import "../../styles/constants";

.proposal-card-container-parent {
  margin-bottom: 16px;
}
.proposal-card-container {
  position: relative;
  margin: 0 8px;
  .proposal-card-alert {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ec4d2c;
    width: 26px;
    height: 26px;
    margin-top: 4px;
    box-sizing: border-box;
    color: white;
    position: absolute;
    bottom: -32px;
    right: 0;
    svg {
      color: white;
      width: 18px;
      height: 18px;
    }
    &.expiring,
    &.must-vote-alert,
    &.poll-complete {
      animation: tada 1s ease-out infinite;
      animation-iteration-count: 1;
      animation-delay: 300ms;
      animation-fill-mode: forwards;
    }
    .alert-msg {
      display: none;
      position: relative;
      overflow: hidden;
      text-align: left;
      flex: 1;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
    }
    &:hover {
      padding: 0 12px 0 8px;
      
      svg {
        margin-right: 4px;
      }
      width: unset;
      .alert-msg {
        display: block;
        white-space: nowrap;
      }
    }
  }
  .proposal-card-guest-msg {
    background-color: #0166D8;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 2px;
    align-self: flex-start;
    margin-bottom: 8px;
    strong {
      font-weight: 600;
    }
  }
  .proposal-card {
    z-index: 0;
    width: 344px;
    height: 134px;
    padding: 12px;
    border: 2px solid #dadada;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    transition: 0.1s ease-out;
    &.selected,
    &:hover {
      border: 2px solid #5b5b5b;
      box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.15);
      top: -1px;
    }

    &.card-guest {
      border-color: #7060DF;
      margin-bottom: 2px;
      &:hover {
        border-color: inherit;
      }
    }
    .proposal-card-content {
      display: flex;
      height: 100%;
      .proposal-card-type-logo {
        flex: 0;
        width: 48px;
        height: 48px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px;
        aspect-ratio: 1;
        gap: 2px;
        &.complete {
          padding: 0;
          gap: 0px;
        }
        border-radius: 9px;
        box-sizing: border-box;
        &.funnel-card {
          @include themed() {
            &:not(.complete),
            .month-of-year {
              background-color: t(lm-funnel-background-color);
            }
            border: 2px solid t(lm-funnel-background-color);
          }
        }
        &.poll-card {
          @include themed() {
            &:not(.complete),
            .month-of-year {
              background-color: t(lm-poll-background-color);
            }
            border: 2px solid t(lm-poll-background-color);
          }
        }
        &.link-card {
          @include themed() {
            .month-of-year {
              background-color: t(lm-color-pop);
              color: white !important;
            }
            .day-of-week {
              color: t(lm-font-color-primary);
            }
            border: 2px solid t(lm-color-pop);
          }
        }
        .proposal-card-type-label {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          align-items: center;
          font-variant: small-caps;
          color: #454545;
        }
        &.complete {
          .month-of-year {
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            letter-spacing: 0.05em;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            width: 100%;
            text-align: center;
            padding: 2px 0 1px 0;
            vertical-align: middle;
            /* Greyscale/Grey Defaut */
            color: #5b5b5b;
            text-transform: uppercase;
          }
          .day-of-week {
            font-weight: 500;
            font-size: 24px;
            line-height: 100%;
            text-align: center;
            /* Greyscale/grey darkest */
            color: #242222;
            padding: 4px;
          }
        }
      }
      .proposal-card-title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        padding: 0 8px;
        color: #242222;
        flex: 1;
        text-align: left;
        .proposal-title {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          width: 100%;
        }
        .meeting-hour {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
        }
        .meeting-tz {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          /* Greyscale/Grey Defaut */
          color: #5b5b5b;
        }
      }
      .link-card-title {
        padding: 0;
        .link-duration {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          display: flex;
          align-items: center;
          font-variant: all-small-caps;
          color: #898989;
        }
      }
      .proposal-card-actions {
        display: flex;
        flex-direction: column;
        position: relative;
        height: fit-content;
        .proposal-card-menu {
          flex: 0;
          margin-left: auto;
          .MuiListItem-root {
            font-size: 14px;
          }
          button {
            border: 1.5px solid #5b5b5b;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            transition: background-color 0.3s, color 0.2s, border-color 0.2s;
            @include themed() {
              &:hover {
                background: #dedede;
              }
            }
          }
        }
      }
    }
    .proposal-card-footer {
      display: flex;
      margin-top: 8px;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
      .card-location {
        height: 18px;
        svg {
          margin-right: 8px;
          width: 18px;
          height: 18px;
        }
      }
      .card-guests {
        display: flex;
        align-items: center;
        svg {
          display: none;
          margin-right: 5px;
        }
      }
      .card-status {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #454545;
        svg {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
        &.cancel {
          svg {
            fill: #d95f5f;
          }
        }
        &.confirmed {
          svg {
            fill: #00943b;
          }
          &.past svg {
            fill: black;
          }
        }
      }
      .card-replies {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        .card-vote {
          display: flex;
          align-items: center;
          margin: 0 5px;
          svg {
            width: 15px;
            height: 15px;
          }
          &.card-vote-yes {
            svg {
              color: #00943b;
            }
          }
          &.card-vote-no {
            svg {
              color: #d95f5f;
            }
          }
        }
      }
    }
    .link-card-footer {
      flex-direction: column;
      align-items: flex-start;
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #7060df;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      .link-view-page {
        margin-bottom: 12px;
      }
      .link-actions {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .LMSwitch.small {
          margin: 0;
        }
        .link-get-link {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          svg {
            width: 20px;
            height: 20px;
            fill: white;
            padding: 2px;
            background-color: #7060df;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
    }

    &.link-unactive {
      background-color: #f8f8f8;
      a {
        pointer-events: none;
        color: #dadada;
      }
      .link-card-footer {
        .link-actions {
          .link-get-link {
            svg {
              background-color: #dadada;
            }
          }
        }
      }
    }
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.5, 0.5, 0.5) rotate3d(0, 0, 1, -5deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 5deg) translateX(2px);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -5deg) translateX(2px);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}
.proposal-card-menu-popper ul li {
  font-size: 14px;
  font-weight: 500;
  padding-left: 14px !important;
  padding-right: 14px !important;
  svg {
    @include themed() {
      fill: t(lm-font-color-primary);
    }
  }
}
