@import "../../../styles/_themes.scss";
@import "../../../styles/_screens.scss";

.guestsv3 {
  .guest-v3 {
    background: #ffffff;
    border: 1px solid #dadada;

    border-radius: 100px;
    padding: 4px 8px;
    margin-bottom: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    *{
      box-sizing: content-box;
    }
    .email-avatar,
    .email .email-avatar {
      display: flex;
      align-items: center;
      &.optional {
        font-style: italic;
        .optional-mark {
          margin-left: 3px;
        }
      }
      .Avatar {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        @include smScreenAndSmaller(){
          display: none;
        }
      }
      .info {
        display: flex;
        // flex-direction: column;
        align-items: flex-start;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #5b5b5b;
        @include smScreenAndSmaller(){
            flex-direction: column;
          }
        .name {
          margin-right: 4px;
          
        }
        span {
          // font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #242222;
          display: flex;
          text-align: left;
          word-break: break-word;
          @include smScreenAndSmaller(){
            &:not(.optional-mark){
              white-space: nowrap;
            max-width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            }
            
          }

        }
        svg {
          margin-left: 8px;
          flex-shrink: 0;
          width: 16px;
          height: 16px;
        }
      }
    }
    .email-avatar {
      .avatar-state {
        .MuiCircularProgress-root.decorator.small {
          width: 30px !important;
          height: 30px !important;
          background-color: white !important;
        }
      }
    }
    &:nth-child(odd) {
      background: white;
      .email .email-avatar .avatar-state .Avatar.pills-mode {
        background-color: #f5f8ff;
      }
    }
    &:nth-child(even) {
      background: #f5f8ff;
      .email .email-avatar .avatar-state .Avatar.pills-mode {
        background-color: white;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      box-sizing: content-box;
      .separator {
        width: 1px;
        background-color: #dadada;
        margin: 0 15px;
        @include smScreenAndSmaller(){
            margin: 0 8px;
          }
      }
      svg {
        color: #3d2daa;
      }
      .with-border:not(:last-child) {
        margin-right: 14px;
        @include smScreenAndSmaller(){
            margin-right: 8px;
          }
      }
      .with-border {
        svg {
          border: 2px solid #3d2daa;
          border-radius: 50%;
          color: #3d2daa;
          width: 18px;
          height: 18px;
          padding: 4px;

          @include smScreenAndSmaller(){
             width: 10px;
            height: 10px;
            padding: 4px;
          }
        }
      }
    }
  }
}
#lock-menu{
  li{
    border-bottom: 1px solid #DADADA;
    &:last-child{
      border-bottom: none;
    }
  }

  .form-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    
    span:first-child{
      margin-right: 8px;
    }
    svg {
      color: #3d2daa;
      width: 16px;
      height: 16px;
      @include smScreenAndSmaller(){
              width: 10px;
              height: 10px;
      }
    }
  }
}