@import "../../../styles/themes";
@import "../../../styles/screens";

$panel-inner-width: 480px;
$default-top-padding: 50px;

// // hack mode ON
// .App {
//     @include smScreenAndSmaller {
//         height: auto !important;
//     }
// }
// // hack mode OFF

.proposal-page {
    display: flex;
    overflow-x: hidden;
    @include smScreenAndSmaller {
        flex-direction: column;
    }
    min-width: 100%;
    font-size: 16px;
    // letter-spacing: 0.4px;
    @include mdScreenAndSmaller {
        font-size: 14px;
    }
    text-align: left;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    @include themed() {
        font-weight: t(lm-font-weight-normal);
        // background-color: t(lm-background-proposal-side-panel);
    }

    .proposal-info-panel {
        // transition: width 500ms cubic-bezier(0.65, 0.05, 0.36, 1);
        overflow-x: hidden;
        height: 100vh;
        overflow-y: auto;
        .secret {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
        }
        .loading {
            @include themed() {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100vh;
                justify-content: center;
                // margin-bottom: t(lm-space-large);
                color: t(lm-font-color-proposal-primary);
                .MuiCircularProgress-root {
                    color: t(lm-font-color-proposal-primary);
                    margin-bottom: t(lm-space-medium-plus);
                }
            }
        }
        .inner {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            > div {
                @include smScreenAndSmaller {
                    width: 100%;
                }
            }
        }
        width: 40%;
        &.full {
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include themed() {
            padding-top: t(lm-space-medium-plus);
            @include lgScreenAndSmaller {
                padding-top: t(lm-space-medium-plus);
            }
            @include mdScreenAndSmaller {
                padding-top: t(lm-space-small);
            }
            @include smScreenAndSmaller {
                width: 100%;
                height: unset;
                padding-top: 0;
            }

            background-color: t(lm-background-proposal-side-panel);
        }

        .proposal-status {
            @include themed() {
                color: t(lm-font-color-proposal-primary);
                margin-top: t(lm-space-medium);
                margin-bottom: t(lm-space-medium);
                .title {
                    font-size: t(lm-font-size-xlarge);
                    font-weight: t(lm-font-weight-semibold);
                }
                .subtitle {
                    margin-top: t(lm-space-small);
                    color: t(lm-font-color-proposal-secondary);
                    font-size: t(lm-font-size-large);
                    line-height: 1.4em;
                }
            }
        }

        .signature {
            align-self: flex-start;
            display: flex;
            align-items: flex-end;
            @include themed() {
                margin: 0 0 t(lm-space-large) t(lm-space-large);
                @include smScreenAndSmaller {
                    svg {
                        width: 68px;
                    }
                    margin: 0 0 t(lm-space-medium) t(lm-space-medium);
                }
            }
        }

        .inner {
            width: $panel-inner-width;
            @media (min-width: 1700px) {
                align-self: flex-end;
                margin-right: 120px;
            }
            box-sizing: border-box;
            @include themed() {
                padding: t(lm-space-medium);
                @include lgScreenAndSmaller {
                    margin-right: 0;
                    width: 100%;
                }
                .error {
                    color: t(lm-font-color-proposal-primary);
                    text-align: center;
                    h1 {
                        font-size: t(lm-font-size-xlarge);
                        font-weight: t(lm-font-weight-bold);
                    }
                    p {
                        font-size: t(lm-font-size-medium);
                        margin-top: t(lm-space-small);
                    }
                }
            }
        }
        &.full {
            .inner {
                @media (min-width: 1700px) {
                    margin-right: 0;
                }
                @include themed() {
                    align-self: initial;
                    width: $panel-inner-width;
                    @include smScreenAndSmaller {
                        width: 100%;
                    }
                    .ProposalInfo {
                        padding: t(lm-space-medium);
                        .left-deco {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .proposal-action-panel {
        .relative {
            position: relative;
        }
        overflow-x: hidden;
        @include themed() {
            background-color: t(lm-background-proposal-action-panel);
            width: 100%;
            max-height: 100%;

            align-self: center;
            justify-self: center;
            padding: $default-top-padding t(lm-space-large) t(lm-space-large) $default-top-padding;
            @include lgScreenAndSmaller {
                padding: $default-top-padding t(lm-space-medium-plus) t(lm-space-medium-plus) t(lm-space-medium-plus);
            }
            @include mdScreenAndSmaller {
                padding: t(lm-space-medium);
            }
            @include smScreenAndSmaller {
                overflow-y: inherit;
            }
            .header {
                max-width: 600px;
                & > * {
                    margin: 0;
                    padding: 0;
                    font-weight: t(lm-font-weight-normal);
                }
                h1 {
                    font-size: t(lm-font-size-xlarge);
                    font-weight: t(lm-font-weight-bold);
                }
                p {
                    font-size: t(lm-font-size-medium);
                    margin-top: t(lm-space-small);
                    margin-bottom: t(lm-space-medium-plus);
                }
            }
        }
    }
    @include themed() {
        .button-like-a-link {
            margin: 0;
            padding: 0;
            text-decoration: underline;
            color: t(lm-font-color-primary);
            display: inline-block;
            &:hover {
                text-decoration: none;
                cursor: pointer;
            }
        }
        .view-more {
            margin: t(lm-space-medium) 0;
            margin-bottom: t(lm-space-large);
            .view-more-desc {
                margin-right: t(lm-space-xs);
                font-style: italic;
            }
        }
        .promote {
            padding: t(lm-space-medium);
            border-radius: t(lm-border-radius-normal);
            color: t(lm-font-color-proposal-primary);
            background: rgba(255, 255, 255, 0.07);
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: t(lm-space-large);
            font-weight: t(lm-font-weight-semibold);
            > p {
                margin: 0 5px 0 0;
                padding: 0;
            }
            .ondark {
                // border: 2px solid t(lm-font-color-proposal-primary);
                background: transparent;
                color: t(lm-font-color-proposal-primary);
                // padding: 4px 12px;
                margin-top: t(lm-space-normal);
                font-size: t(lm-font-size-normal);
                // box-sizing: border-box;
            }
            @include mobileScreenOnly {
                flex-direction: column;
                align-items: flex-start;
                .ondark {
                    margin-top: t(lm-space-normal);
                }
            }
        }
    }

    &.light {
        @include themed() {
            .proposal-info-panel {
                border-right: 1px solid rgba(0, 0, 0, 0.05);
                @include smScreenAndSmaller {
                    border-right: 0px solid rgba(0, 0, 0, 0.05);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                }
                &.full {
                    border: none;
                }
                background-color: t(lm-background-proposal-side-panel-light);
                .loading {
                    color: t(lm-font-color-proposal-primary-light);
                    .MuiCircularProgress-root {
                        color: t(lm-font-color-proposal-primary-light);
                    }
                }
                .proposal-status {
                    color: t(lm-font-color-proposal-primary-light);
                    .subtitle {
                        color: t(lm-font-color-proposal-secondary-light);
                    }
                }
                .inner {
                    .error {
                        color: t(lm-font-color-proposal-primary-light);
                    }
                }
            }
            .promote {
                color: t(lm-font-color-proposal-primary-light);
                background: rgba(0, 0, 0, 0.03);
                .ondark {
                    color: t(lm-color-pop);
                    // border: 2px solid t(lm-font-color-proposal-primary-light);
                    // background: transparent;
                }
            }
        }
    }
    .connection {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 400px;
        // justify-content: space-evenly;
        // margin: auto;
        @include themed() {
            border-radius: 3px;
            box-sizing: border-box;
            border: 2px solid #0000000c;
            padding: t("lm-space-medium");
            margin-top: t(lm-space-large);
        }
        .connection-content {
            margin-bottom: 20px;
            @include smScreenAndSmaller() {
                max-width: 90%;
            }
            .title {
                text-transform: uppercase;
                letter-spacing: 2px;
                @include themed() {
                    margin-bottom: t(lm-space-normal);
                    font-size: t(lm-font-size-small);
                    font-weight: t("lm-font-weight-semibold");
                }
            }
            .text {
                line-height: 1.6em;
            }
        }
    }
    #slots-container {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        .relative{
          margin: auto 0;
        }
    }
}
