@import "../../../../styles/_themes.scss";

.all-day-bar {
  display: flex;
  margin-left: 45px;
  margin-right: 7px;
  @include lgScreenAndSmaller {
    margin-left: 0;
  }
  max-height: 10%; // more or less?
  @include themed() {
    border-bottom: t(lm-border-heatmap-grid-hour);
    // margin-bottom: t(space-small);
    // height: t(space-medium);
  }
  .day-item {
    width: calc(100% / 7);
    display: flex;
    flex-direction: column;
    @include themed() {
      border-left: t(lm-border-heatmap-grid-hour);
      &:last-child {
        position: relative;
        &::after {
          content: "";
          width: 1px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          border-right: t(lm-border-heatmap-grid-hour);
        }
      }
    }
    // max-height: 50px;
    // height: 50px;
    overflow-y: auto;
    // min-width: 0;
    & > .all-day-event-item {
      border: 1px solid white;
      // border-left-width: 3px;
      @include themed() {
        font-size: t(lm-font-size-xsmall);
      }
      padding: 3px 5px;
      box-sizing: border-box;
      // text-align: center;
      // flex-grow: 1;
      // flex: 1;
      min-width: 0;
      // overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }
}
