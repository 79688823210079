@import "../../../styles/themes";
@import "../../../styles/screens";

.billing-free-user {
  display: flex;
  flex-direction: column;
}

.billing-free-user,
.billing-premium-user,
.billing-welcome {
  // max-width: 600px;
  > .title {
    @include themed() {
      color: t(lm-font-color-primary);
      font-size: t(header-font-size);
      font-weight: t(lm-font-weight-bold);
    }
  }
  > .subtitle,
  & > .promo {
    @include themed() {
      color: t(lm-font-color-secondary);
      font-size: t(main-font-size);
    }
  }
  & > .subtitle {
    margin: 10px 0;
  }

  & > .billing-promo-card {
    align-self: flex-start;
    @include themed() {
      margin: t(lm-space-medium) 0px;
    }
  }
  .stripe-infos {
    @include themed() {
      margin: t(lm-space-medium) 0px;
      color: t(lm-font-color-primary);
      display: flex;
      flex-direction: column;
      font-size: t(lm-font-size-normal);
    }
    .stripe-title {
      display: flex;
      align-items: center;
      @include themed() {
        padding-bottom: t(lm-space-small);
      }
      svg {
        width: 18px;
        height: 18px;
      }
      .msg {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include themed() {
          margin-left: t(lm-space-xs);
          padding: 0 t(lm-space-xs);
          @include mobileScreenOnly {
            font-size: t(lm-font-size-small);
          }
        }
        .strong {
          @include themed() {
            font-weight: t(lm-font-weight-bold);
            padding: t(lm-space-xs);
          }
        }
        .powered {
          @include themed() {
            border: 1px solid t(color-border-unactive);
            border-radius: t(lm-space-xs);
            padding: t(lm-space-xs) t(lm-space-small);
            margin-left: t(lm-space-small);
            @include mobileScreenOnly {
              padding: 0 0 0 t(lm-space-xs);
              border: 0;
              margin-left: 0;
              font-weight: t(lm-font-weight-bold);
            }
          }
          background: white;
        }
      }
    }
    .stripe-support {
    }
  }
}
