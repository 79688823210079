@import "../../styles/themes";
@import "../../styles/screens";

.LinkInfo {
  position: relative;
  @include themed() {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #5b5b5b;
    padding: t(lm-space-medium);
    max-width: 400px;
    @include lgScreenAndSmaller {
      max-width: unset;
    }
    > * {
      margin-bottom: 20px;
      @include lgScreenAndSmaller {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.from {
        margin-bottom: 12px;
      }
    }
    .withicon {
      &.mobile-only {
        display: none;
      }
      display: flex;
      @include mdScreenAndSmaller {
        &.mobile-only {
          display: flex;
        }
        &.desktop-only {
          display: none;
        }
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        margin-top: -2px;
      }
    }
    .link-organizer {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #242222;
      .link-organizer-name {
        margin: 0 10px;
        font-weight: t(lm-font-weight-bold);
      }
      .link-avatar {
        width: 40px;
        height: 40px;
        color: white;
      }
    }
    > .title {
      border-top: 1px solid #dadada;
      padding-top: 24px;
      margin-bottom: 20px;
      color: #242222;
      font-weight: t(lm-font-weight-bold);
      font-size: 18px;
      line-height: 120%;
      @include mdScreenAndSmaller {
        font-size: 18px;
      }
    }
    .notes {
      line-height: 1.4em;
      max-height: 40vh;
      overflow: auto;
      @include mdScreenAndSmaller {
        max-height: 100vh;
      }
    }
    .location {
      word-break: break-all;
    }
  }
  .link_company_logo {
    max-width: 200px;
    max-height: 100px;
    @include mdScreenAndSmaller {
      max-width: 100px;
      max-height: 70px;
    }
    height: auto;
    margin: 0;
    display: block;
    margin-bottom: 39px;
  }
}
