@import "../../../../styles/_themes.scss";

@mixin with-heatmap-disabled {
  position: relative;
  &.disabled {
    height: calc(50vh - 240px);
  }
  .disabled {
    position: absolute;
    z-index: 99999;
    background-color: rgba(255, 255, 255, 0.85);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    &.hand {
      cursor: pointer;
    }
    > div {
      max-width: 390px;
      padding: 10px;
      text-align: center;
      &.link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    svg {
      width: 90px;
    }
    img {
      width: 80%;
      max-width: 600px;
      @include smScreenAndSmaller {
        max-width: 360px;
      }
      border-radius: 6px;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.20);
      margin: -10px 0;
    }
  }
}

.no-overflow {
  display: flex;
  .MuiDialog-container {
    margin: auto;
    .MuiDialog-paperScrollPaper {
      overflow: hidden;
    }
  }
}
.add-guests-panel {
  text-align: left;
  display: flex;
  padding-bottom: 5px;
  // padding: 5px 66px;
  max-height: 1000px;

  flex: 1 1;
  overflow: auto;
  @include with-heatmap-disabled();
  & > div {
    padding: 10px 34px;
  }
  .title {
    // margin-right: 10px;
    // margin-bottom: 5px;
    @include themed() {
      font-size: t(lm-font-size-popup-title);
      font-weight: t(lm-font-weight-semibold);
    }
  }
  .description {
    @include themed() {
      font-size: t(lm-font-size-normal);
      color: t(lm-font-color-secondary);
    }
    @include mobileScreenOnly() {
      display: none;
    }
  }
  .add-guests-agenda {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 10px 34px 10px 10px;
    @include mobileScreenOnly {
      padding-right: 10px;
    }
    .agenda-head {
      flex-grow: 0;
      padding: 20px 20px 0px 20px;
      @include lgScreenAndSmaller {
        padding: 0;
        padding-top: 10px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mdScreenAndSmaller {
        flex-direction: column;
        align-items: center;
      }
      .textual {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        // margin-bottom: 6px;
      }
    }
    .toolbar {
      display: inline-flex;
      align-items: center;
      // width: 100%;
      flex-direction: row;
      //justify-content: flex-end;
      button {
        padding: 0;
        line-height: 1em;
        text-align: left;
        font-size: 10px;
        min-width: unset;
        @include themed() {
          margin-right: t(lm-space-small);
        }
      }
    }
    .footer {
      padding-left: 45px;
      flex-grow: 0;
      .helper {
        @include themed() {
          font-size: t(mini-font-size);
        }
      }
      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .or {
          text-transform: uppercase;
          margin: auto;
        }
        .done-button {
          margin: 0;
          padding: 8px 12px;
          // text-transform: Capitalize !important;
          font-size: 18px;
          min-width: 300px;
        }
      }
    }
    button {
      // margin-top: auto;
    }
    .heatmap {
      flex-grow: 1;
      display: flex;
      /*overflow-y: hidden;
      overflow-x: visible;*/
      overflow: auto;
      // margin-left: 45px; // This should be calculated depending on how much timezone we display
      .timezone {
        flex-grow: 0;
        flex-basis: 0;
      }
      .agenda {
        flex-grow: 1;
        flex-basis: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        .week-selector {
          // margin-left: 45px;
          margin-left: 45px;
          // @include themed() {
          //   border: t(lm-border-heatmap-grid-hour);
          // }
        }
        .done-picker {
          margin-left: 45px;
          z-index: 10;
          // @include themed() {
          //   border-bottom: t(lm-border-heatmap-grid-hour);
          // }
          border-top: none;
          background-color: white;
          .calendar-base {
            padding: 0;
            .dates {
              .week {
                padding: 0;
                margin-bottom: 0px;
                margin-right: 7px;
              }
            }
          }
          .days {
            margin-bottom: 0;
            .dayOfWeek {
              @include themed() {
                font-size: t(mini-font-size);
              }
              text-transform: capitalize;
              min-width: calc(100% / 7);
              font-weight: normal;
            }
          }
          .donepicker-date {
            margin: auto;
          }
        }
      }
    }
  }
  @include lgScreenAndSmaller {
    max-height: unset;
    $left-margin: 2px;
    .add-guests-agenda {
      // padding: $left-margin !important;
      // padding-left: 24px;

      .agenda-head {
        // padding-left: 5px;
      }
      .heatmap {
        .agenda {
          .week-selector {
            margin-left: 0;
            .periodStr {
              margin: 0 6px 0 16px;
            }
          }
          .done-picker {
            margin-left: 0;
          }
          .heatmap-grid {
            .timezone {
              width: 0;
              .tz-hour {
                left: 0;
                text-align: left;
                padding-left: 10px;
                z-index: 1;
              }
            }
          }
          .busy-times {
            margin-left: 0;
          }
          .heatmap-time-container {
            margin-left: 0;
          }
          .heatmap-drawing {
            margin-left: $left-margin;
            .proposal {
              .proposal-title {
                // display: none;
              }
              .slot {
                position: absolute;
                right: 2px;
              }
              button {
                // left: 2px;
              }
            }
          }
        }
      }
    }
  }
}
.agenda-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px 20px 20px;
  position: sticky;
  bottom: 0;
  background-color: white;
  @include mobileScreenOnly {
    padding: 10px;
    padding-top: 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    .form-control {
      margin-left: auto !important;
    }
    .label {
      // font-size: 11px;
      margin-bottom: 10px;
    }
    button {
      min-width: 45% !important;
    }
  }
  button.done-button.MuiButton-root {
    margin: 0 5px;
    padding: 4px 12px;
    font-size: 16px;
  }
  .form-control {
    margin-right: auto;
    margin-left: 20px;
    padding-left: 13px;
    padding-right: 3px;
    max-width: 90%;

    &.shaker {
      border-radius: 50px;
      @include themed() {
        font-weight: t(lm-font-weight-bold);
        border: 2px solid t(lm-color-pop);
      }
      animation: shakeAndHightlight 0.5s;
      background-color: rgba(192, 213, 255, 0.3);
    }
    .label {
      display: flex;
      align-items: center;
      .centered {
        display: flex;
        align-items: center;
      }
      .LMTooltip-children {
        display: inline-flex;
        align-items: center;
        margin-top: -0.5em;
      }
      svg.help {
        @include themed() {
          color: t(lm-color-pop);
        }
        font-size: 1.2em;
      }
    }
  }
}
.MuiDialog-root.outlook {
  .add-guests-panel {
    max-height: unset;

    .add-guests-agenda {
      padding: 0 !important;

      .agenda-head {
        padding-left: 5px;
      }
      .heatmap {
        .agenda {
          .week-selector {
            margin-left: 0;
            .periodStr {
              margin: 0 6px 0 16px;
            }
          }
          .done-picker {
            margin-left: 0;
          }
          .heatmap-grid {
            .timezone {
              width: 0;
            }
          }
          .busy-times {
            margin-left: 0;
          }
          .heatmap-time-container {
            margin-left: 0;
          }
          .heatmap-drawing {
            margin-left: 0;
            .proposal {
              .proposal-title {
                // display: none;
              }
              .slot {
                position: absolute;
                right: 2px;
              }
              button {
                // left: 2px;
              }
            }
          }
        }
      }
    }
  }
}
