@import "../../../styles/_themes.scss";
.done-input {
  position: relative;

  fieldset {
    @include themed() {
      border: t(form-input-border-default);
      border-radius: t(lm-border-radius-input);
    }
  }
  .MuiOutlinedInput-root:hover:not(.Mui-error):not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
    border-color: #747474;
  }

  .Mui-focused:not(.Mui-error) fieldset {
    @include themed() {
      border-color: t(color-robineggblue);
      border-width: 1px;
      color: t(input-active-font-color);
    }
  }
  label {
    color: $color-robineggblue;
  }
  label.Mui-focused:not(.Mui-error) {
    @include themed() {
      color: t(color-robineggblue) !important;
    }
  }
  &.MuiToggleButtonGroup-root {
    width: 100%;
    display: flex;
    & > * {
      flex-grow: 1;
      width: auto;
    }
  }
  &.MuiToggleButtonGroup-root .MuiToggleButton-root,
  .MuiOutlinedInput-root {
    @include themed() {
      // border-color: t(input-font-color);
      color: t(input-font-color);
    }
    &:hover {
      border-color: #747474 !important;
      z-index: 2;
    }
    &.no-ripple {
      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
  &.MuiToggleButtonGroup-root .MuiToggleButton-root {
    // height: 87px;
    // border: 1px solid blue; //rgba(0, 0, 0, 0.23);
    @include themed() {
      border: t(form-input-border-default);
    }
    &.custom-duration-input {
      // min-width: 170px;
      // @include mobileScreenOnly {
      //   min-width: 64px;
      // }
      .strong {
        // text-transform: uppercase !important;
        // font-size: 12px !important;
        // letter-spacing: 1px !important;
        @include themed() {
          //color: $color-robineggblue;
          font-weight: t(lm-font-weight-bold);
        }
      }
      &:hover {
        //background-color: inherit;
      }
      input {
        // display: none;
        max-width: 30px;
      }

      &.Mui-selected {
        input {
          display: inherit;
          // text-align: center;
          font-weight: 500;
          font-size: 18px;
          max-width: 35px;
          margin: 0;
        }
      }
    }

    .MuiTouchRipple-child {
      @include themed() {
        // border-color: t(input-font-color);
        color: t(color-light-pop-opacity-ripple);
      }
    }
  }

  &.MuiToggleButtonGroup-root .MuiToggleButton-root .MuiToggleButton-label {
    display: flex;
    @include mobileScreenOnly {
      display: block;
    }
    // padding: 0 21px;
    width: unset !important;
    .strong {
      font-size: 14px;
      margin-right: 3px;
      @include mobileScreenOnly {
        font-size: 14px;
        line-height: 1em;
      }
      /* font-weight: 700; */
      line-height: 18px;
      text-transform: none;
    }
    input {
      font-weight: 700;
      font-size: 20px;
      // padding: 12px 15px;
      padding: 0;
    }
    .secondary-text {
      @include themed() {
        color: t(lm-font-color-secondary);
      }
    }
  }
  &.MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected {
    border: 1px solid $color-robineggblue;
    background-color: #f5f8ff;
    color: $color-robineggblue;
    z-index: 98;
    .secondary-text {
      @include themed() {
        color: t(lm-color-pop);
      }
    }
  }

  /*&.MuiFormControl-root,
  &.done-input-valid{
    .MuiFormLabel-root.Mui-focused:not(.Mui-error) {
        
          @include themed() {
            color: t(input-active-font-color);
          }
    }
    //.MuiOutlinedInput-root {
      
      &.Mui-focused:not(.Mui-error) fieldset {
        @include themed() {
          border-color: t(input-active-font-color);
          color: t(input-active-font-color);
        }
      }
    //}
  }*/
  .required {
    @include themed() {
      font-size: t(input-required-font-size);
      color: t(color-robineggblue);
      width: 46px;
    }
  }
  &.Mui-error,
  .Mui-error {
    &label,
    & label {
      @include themed() {
        color: t(input-error-font-color);
        font-weight: t(lm-font-weight-bold);
      }
    }
    &.MuiFormHelperText-root {
      @include themed() {
        font-weight: t(lm-font-weight-bold) !important;
        animation: shake 0.5s;
      }
    }
    &.MuiInputLabel-root {
      @include themed() {
        font-weight: t(lm-font-weight-bold) !important;
      }
    }
    .required {
      @include themed() {
        font-size: t(input-required-font-size);
        color: t(input-error-font-color);
      }
      animation: shake 0.5s;
    }
    fieldset {
      border-width: 1px !important;
      @include themed() {
        border-color: t(input-error-font-color);
      }
    }
    &.MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected {
      @include themed() {
        border: 1px solid t(input-error-font-color);
        background-color: #f4433611;
        color: t(input-error-font-color);
      }
      background-color: inherit;
    }
  }

  /*&.done-input-valid{
    .required {
      @include themed() {
        color: t(input-valid-font-color);
      }
    }
    .MuiFormLabel-root {
        
      @include themed() {
        color: t(input-valid-font-color)  !important;
      }
    }
    fieldset {
      @include themed() {
        border-color: t(input-valid-font-color) !important;
        
      }
    }
    
  }*/
  .MuiRadio-root,
  &.MuiRadio-root,
  &.MuiCheckbox-root {
    .Mui-checked,
    &.Mui-checked,
    .MuiTouchRipple-root {
      @include themed() {
        color: t(input-valid-font-color) !important;
      }
    }
    &:hover,
    &.Mui-checked:hover {
      @include themed() {
        background-color: t(input-color-active-alpha) !important;
      }
    }
  }

  &.multiline-fix {
    .MuiInputAdornment-positionStart {
      position: absolute;
      top: 18.5px;
      svg {
        position: relative;
        top: 10.4px;
      }
    }
    textarea,
    input {
      padding-left: 2rem;
    }
  }

  &.done-calendar-selector {
    .desactivated {
      pointer-events: none;
    }
    .MuiInput-underline:before {
      border: none !important;
      pointer-events: none;
    }
    .white-btn {
      background-color: white;
    }

    .invisible {
      display: none;
    }
  }

  .no-grow {
    flex-grow: 0;
  }

  .MuiChip-root {
    @include themed() {
      background-color: t(pop-color);
      color: t(app-background-color);
    }
  }
  .shrinkalittlebit {
    padding: 0 15px;
  }

  .MuiAutocomplete-inputRoot {
    padding-left: 15px !important;
  }

  .react-tel-input {
    display: flex;
    padding: 18.5px 14px;
    .form-control {
      border: none !important;
      height: 19px;
    }
  }
}
p.align-right {
  text-align: right;
}
p.done-Mui-error {
  color: #f44336;
  margin: 0;
  font-size: 0.65rem;
  margin-top: 3px;
  font-weight: 400;
  line-height: 1.66;
  margin-left: 14px;
  margin-right: 14px;
}
.MuiAutocomplete-popper {
  // height: 200px!important;
}
label.fake-label {
  font-size: 0.65rem;
  text-align: left;
  margin-left: 14px;
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  // 10%,
  // 30%,
  // 50%,
  // 70%,
  // 90%,
  20%,
  60% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  // 20%,
  // 40%,
  // 60%,
  // 80%,
  40%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shakeAndHightlight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
  20%,
  60% {
    transform: translate3d(0, -10px, 0);
    background-color: rgba(192, 213, 255, 0.5);
  }
  40%,
  80% {
    transform: translate3d(0, 10px, 0);
  }
}
.done-input.with-help > label,
.with-help-button {
  display: flex !important;
  align-items: center !important;
  pointer-events: all;
  z-index: 5;
  .showHand {
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
.done-input.with-help > label {
  .showHand {
    margin-left: 8px;
  }
}
.done-input.with-help > label .showHand svg {
  transform: scale(1.33);
}
.fast-selector legend.done-input-label {
  pointer-events: all;
  .showHand {
    cursor: pointer;
    svg {
      transform: scale(1.33);
    }
  }
}
.done-input {
  fieldset legend {
    > span {
      font-size: 0.6rem;
    }
  }
}
