@import "./styles/_themes.scss";

.LMSwitch {
  @include themed() {
    position: relative;
    display: inline-flex;
    align-items: center;
    $sw_height: 20;
    $sw_width: 40;
    $sw_height-small: 18;
    $sw_width-small: 34;
    $black: #5b5b5b;
    margin: 8px;
    &.no-margin {
      margin: 0;
    }
    // Labels
    .label {
      font-size: t(lm-font-size-normal);
      font-weight: t(lm-font-weight-semibold);
      &:first-letter {
        text-transform: uppercase;
      }
      transition: all 0.1s;
      opacity: 1;

      &.right {
        color: t(lm-color-pop);
        margin-left: 0.3em;
      }
      &.left {
        color: $black;
        margin-right: 0.3em;
      }
      &.unchecked {
        color: $black;
      }
      &.invisible {
        opacity: 0;
        &:first-child {
          transform: translateX(10px);
        }
        &:last-child {
          transform: translateX(-10px);
        }
      }
    }
    .sameplace {
      display: flex;
      flex-direction: column;
      .placeholder {
        font-size: t(lm-font-size-normal);
        font-weight: t(lm-font-weight-semibold);
        height: 0;
        overflow: hidden;
        margin-left: 0.3em;
      }
    }
    // Styles applied to the switch root element.
    .MuiSwitch-root {
      margin: 0;
      .MuiIconButton-root:hover {
        background-color: transparent;
        & + .MuiSwitch-track {
          box-shadow: inset 0px 0px 1px 0px #00000050;
        }
      }
      width: $sw_width * 1px;
      height: $sw_height * 1px;
      padding: 0;
      // Styles applied to the track element.
      .MuiSwitch-track {
        border-radius: 50px;
        border: 1px solid $black;
        background-color: white;
        opacity: 1;
        transition: all 0.1s;
        box-sizing: border-box;
      }
      // Styles applied to the internal SwitchBase component's root class.
      .MuiSwitch-switchBase {
        padding: 2px;
        // Styles used to create the thumb passed to the internal SwitchBase component icon prop.
        .MuiSwitch-thumb {
          width: $sw_height * 1px - 4px;
          height: $sw_height * 1px - 4px;
          background-color: $black;
          box-shadow: none;
          &:after {
            transition: opacity 0.1s;
            opacity: 0;
            content: "";
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            background-image: url(../assets/check.svg);
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        // Pseudo-class applied to the internal SwitchBase component's checked class.
        &.MuiSwitch-checked,
        &.Mui-checked {
          transform: translateX(($sw_width - $sw_height) * 1px);
          // Styles applied to the track element.
          & + .MuiSwitch-track {
            border-color: t(lm-color-pop);
            opacity: 1;
          }
          // Styles used to create the thumb passed to the internal SwitchBase component icon prop.
          .MuiSwitch-thumb {
            background-color: t(lm-color-pop);
            position: relative;
            &:after {
              opacity: 1;
              // content: "";
              // position: absolute;
              // top: 2px;
              // right: 2px;
              // bottom: 2px;
              // left: 2px;
              // background-image: url(../assets/check.svg);
              // background-size: contain;
              // background-repeat: no-repeat;
            }
          }
        }
      }
    }
    &.small {
      margin: 4px;
      .placeholder, .label {
        font-size: t(lm-font-size-xsmall);
        font-weight: t(lm-font-weight-semibold);
      }
      .MuiSwitch-root {
        width: $sw_width-small * 1px;
        height: $sw_height-small * 1px;
        .MuiSwitch-switchBase {
          .MuiSwitch-thumb {
            width: $sw_height-small * 1px - 4px;
            height: $sw_height-small * 1px - 4px;
          }
          &.MuiSwitch-checked,
          &.Mui-checked {
            transform: translateX(($sw_width-small - $sw_height-small) * 1px);
          }
        }
      }
    }
    &.disabled {
      opacity: 0.7;
    }
  }
}
