@import "./styles/_themes.scss";
@import "./styles/_constants.scss";

@import "./styles/screens";
$newDarkerPurple: #36279d;
.fullscreen-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $color-robineggblue;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.signin-dialog {
  position: relative;
  padding: 50px 60px 36px;
  @include mobileScreenOnly {
    padding: 20px 16px;
  }
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
  @include themed() {
    background-color: t(app-background-color);
    font-size: t(header-font-size);
  }
  text-align: center;
  max-width: 500px;

  .header {
    @include themed() {
      font-size: t(title-font-size);
    }
    @include mobileScreenOnly {
      font-size: 24px !important;
    }
    line-height: 40px;
    margin-bottom: 55px;
    @include mobileScreenOnly {
      margin-bottom: 30px;
    }
  }
  .providers {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-height: 0;
    button {
      background-color: white;
      min-width: 350px;
      @include mobileScreenOnly {
        min-width: 100%;
      }
      font-size: 14px;
      text-transform: initial;
      margin-bottom: 20px;
      height: 56px;
      min-height: 56px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    button:last-child {
      margin-bottom: 0px;
    }
  }
  .fixed-top {
    position: absolute;
    right: 0;
    top: 0;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.no-display {
  display: none;
}

.dialog-error-auth {
  @include themed() {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
    .header {
      margin-bottom: 24px;
      margin-top: 24px;
    }
    .content {
      color: #454545;
      max-width: 555px;
      background: #f5f8ff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px;
      P.pop-color {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #3d2daa;
      }
      a {
        color: #454545;
        text-decoration: underline;
        white-space: nowrap;
      }
      .providers {
        width: 100%;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 0;
        button:first-child {
          margin-right: 24px;
        }
        @include smScreenAndSmaller {
          flex-direction: column;
          align-items: stretch;
          button {
            margin-bottom: 12px;
            width: 100%;
          }
          button:first-child {
            margin-right: 0;
          }
        }
        button {
          flex: 1;
          box-shadow: none;
          background-color: $newDarkerPurple;
          &:hover {
            background-color: t(lm-color-pop);
          }
          color: white;
          display: flex;
          justify-content: flex-start;
          padding: 6px !important;
          .MuiButton-label {
            box-sizing: border-box;
            text-transform: none;
          }
          .MuiButton-startIcon {
            padding: 4px;
            background-color: white;
            border-radius: 2px;
            box-sizing: border-box;
            margin-left: 0 !important;
            svg {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}
