@import "../../../styles/_themes.scss";
@import "../../../styles/_screens.scss";
.guest-selector {
  position: relative;
  width: 100%;
  .done-input {
    @include themed() {
      background-color: t(app-background-color);
    }
  }

  .tiny-measures {
    position: absolute;
    top: 20px;
    font-size: 16px;
    max-width: 100%;
    overflow: hidden;
    max-height: 1px;
    left: 0;
  }
  .text-search {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div.chips {
      margin-top: 8px;
      margin-bottom: 3px;
      margin-right: 10px;
    }
    span.LMTooltip-children > svg.chip-icon {
      width: 18px;
      height: 18px;
      // padding-left: 11px;
    }
    div.MuiFormControl-fullWidth {
      width: unset;
      min-width: 200px;
      margin: 0;
      flex-grow: 1;
      background: none;
    }
    .flex-grow {
      flex-grow: 1;
      div.MuiFormControl-fullWidth {
        width: 100%;
        min-width: 200px;
        margin: 0;
        flex-grow: 1;
        background: none;
      }
      &.error-tooltip {
      }
    }

    .MuiOutlinedInput-adornedStart {
      padding-left: 0;
    }
    .MuiInputAdornment-positionStart {
      margin-right: 0 !important;
    }
    button {
      position: absolute;
      right: -20px;
      top: 23px;
      animation: pop 0.3s linear 1;
      width: 40px;
      height: 40px;
      @include themed() {
        background-color: t(color-robineggblue);
        color: t(color-whitesmoke);
      }
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .MuiChip-root {
    max-width: 450px;
    @include mobileScreenOnly() {
      max-width: 280px;
    }
    @include themed() {
      border: 1px solid t(color-robineggblue);
      color: $color-robineggblue;
      background-color: transparent;
    }
    .MuiChip-label {
      padding-left: 9px;
      padding-right: 9px;
    }
    &:not(.unknown-invitee),
    &:not(.doner-invitee) {
      .MuiChip-label {
        padding-left: 11px;
      }
    }

    .MuiChip-deleteIcon {
      width: 16px;
      height: 16px;
      fill: $color-robineggblue;
    }
    .MuiChip-icon,
    .MuiChip-deleteIcon {
      padding: 0 4px !important;
    }
    &.unknown-invitee .MuiChip-icon {
      margin-right: -9px;
    }
    &.unknown-invitee,
    &.doner-invitee {
      // Chip displaying with light color
      background-color: white;
      color: black;
      border: 1px solid #888888;
      svg.MuiChip-icon {
        width: 16px;
        height: 16px;
        color: #343434;
      }
      .MuiChip-deleteIcon {
        fill: #343434;
      }
    }
    &.optional {
      font-style: italic;
    }
    &.forbidden {
      border-style: dashed;
      .MuiChip-icon {
        margin-right: -11px;
        .chip-icon {
          @include themed() {
            color: $color-robineggblue;
          }
        }
      }
    }
    &.doner-invitee {
      @include themed() {
        border-width: 1px;
        border-color: t(color-robineggblue);
        color: $color-robineggblue;
      }
      svg.MuiChip-icon {
        color: $color-robineggblue;
        position: relative;
        // top: -2px;
        left: 2px;
      }
    }
    &.unknown-invitee {
      @include themed() {
        border: 1px solid #343434;
        color: #343434;
        .chip-actions {
          button {
            color: black;
          }
        }
      }

      svg.MuiChip-icon {
        color: #343434;
        position: relative;
        // top: -2px;
        left: 2px;
      }
    }
  }
}

.selector-options.guest-selector {
  padding: 6px 0 0 0;
  margin-top: 10px;
  box-sizing: border-box;
  max-height: 350px;
  z-index: 2000;
  border-radius: 5px;
  overflow: auto;
  @include themed() {
    border-radius: t(lm-border-radius-normal);
    background-color: t(app-background-color);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding: t(lm-space-small) t(lm-space-small);
  }
  @include themed() {
    .connect-item-contact {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      cursor: pointer;
      align-items: center;
      padding: 8px;
      &:hover {
        background-color: t(lm-background-item-hover);
      }
      .content {
        margin-left: t("lm-space-small");
        font-size: t("lm-font-size-medium");
        font-weight: t(lm-font-weight-semibold);
        .desc {
          margin-top: t(lm-space-xs);
          font-size: t("lm-font-size-small");
          color: t("lm-font-color-secondary");
          font-weight: t(lm-font-weight-normal);
          line-height: 1.3em;
        }
      }
      // margin: -10px!important;
    }
    .close {
      margin-top: 5px;
      display: flex;
      justify-content: flex-end;
      font-size: t(lm-font-size-normal);
      color: t(lm-color-pop);
      & > div {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
.popper {
  z-index: 3000;
}
.upfront {
  z-index: 3000;
}
.small-loading-icon {
  width: 16px !important;
  height: 16px !important;
}
