@import "../components/styles/_themes.scss";
.create-event {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  header {
    @include themed() {
      background-color: t(app-background-color);
    }
    // position: sticky;
    // top: 0;
    // z-index: 1000;

    > div {
      // box-sizing: border-box;
      // max-width: $app-max-width;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 30px 0; //66px;
      & > a > svg,
      & > svg {
        height: 40px;
        width: 282px;
      }
    }
  }
  .sticky-border {
    height: 3px;
    width: 100%;
    position: sticky;
    top: 0;
    padding: 0;
    flex-grow: 0;
    z-index: 2000;
    @include themed() {
      background-color: t(pop-color);
    }
  }
  section {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    // @include themed() {
    //   background-color: t(app-background-color);
    // }

    .page-content {
      flex: 1 1;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      & > div {
        top: 0;
        bottom: 0;
        display: flex;

        & > * {
          flex: 1 1;
          @include themed() {
            background-color: t(app-background-color);
          }
        }
      }
    }
  }
  #location-label {
    z-index: 3;
  }
  
}
.display-block > .lds-roller{
    display: block!important;
  }
