@import "../../../styles/themes";
@import "../../../styles/screens";
.proposal-content {
  display: flex;
  // justify-content: center;
  flex-direction: column;
  position: sticky;
  top: 108px;
  max-height: calc(100vh - 108px);
  @include mobileScreenOnly() {
    flex-direction: column-reverse;
    position: relative;
    top: 0;
    // align-self: stretch;
    align-self: baseline;
    max-height: unset;
  }
  .proposal-mobile-title {
    display: none;
    text-align: left;
    @include themed() {
      color: t("lm-font-color-primary");
      font-size: t("lm-font-size-xlarge");
      font-weight: t("lm-font-weight-bold");
      padding: t("lm-space-medium") 0 t("lm-space-small") 0;
      margin: 0 t("lm-space-medium");
    }
    @include mobileScreenOnly() {
      display: block;
    }
  }
  .proposal-data {
    max-width: 40%;
    min-width: 400px;
    position: relative;
    @include themed() {
      padding: t("lm-space-xs") t("lm-space-medium") t("lm-space-medium")
        t("lm-space-medium");
      background: t("lm-color-background");
      box-shadow: t("lm-shadow-medium");
      margin: t("lm-space-xs");
      border-radius: t("lm-space-xs");
    }
    @include smScreenAndSmaller() {
      max-width: unset;
      min-width: unset;

      @include themed() {
        background: none;
        box-shadow: none;
        // padding: 0;
      }
    }
  }

  &.mobile {
    flex-direction: column;
    margin-top: 20px;
    align-self: flex-start;
    width: 66%;
    .proposal-data {
      max-width: unset;
      min-width: 0;
      @include themed() {
        background: none;
        box-shadow: none;
      }
    }
    .loader {
      padding: 0 20px;
      height: 4px;
      overflow: hidden;
    }
    @include mobileScreenOnly() {
      // width: 100%;
      flex-direction: column-reverse;
    }
  }
  @include smScreenAndSmaller() {
    .back-btn {
      display: flex;
      align-self: flex-start;
      min-width: unset;
      margin: 10px 0 0 20px !important;
    }
  }
}
