.event-announce {
  .event-status {
    font-size: 16px;
    padding: 10px 0;
    max-width: 600px;
    margin: auto;
  }
}
.event-view-success {
  
  .PromoRegister{
    margin-right: 14px!important;
  }
}
.event-success-title {
  font-size: 22px;
  font-weight: 600;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 12px;
  flex: 0 !important;
  padding: 0 12px;
  /*&:before{
    content: "✨";
    position: absolute;
    left: 50%;
    top: 0px;
    font-size: 22px;
  }*/
}
.event-view {
  .recap-details-invitees {
    span.tip {
      width: 100%;
      display: block;
    }
  }
}
