@import "../../styles/themes";
.profile-graph {
  @include themed() {
    text-align: left;
    margin-top: t(lm-space-normal);
    @include lgScreenAndSmaller() {
      margin-top: t(lm-space-medium);
    }
    .title {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: t(lm-font-weight-semibold);
      color: t(lm-font-color-secondary);
      letter-spacing: 1px;
      margin-bottom: t("lm-space-medium");
      @include lgScreenAndSmaller() {
        margin-bottom: t("lm-space-small");
      }
      svg {
        margin-right: t(lm-space-xs);
      }
    }
    .subtitle {
      margin-bottom: t("lm-space-medium");
    }
    .graph-title {
      font-weight: t(lm-font-weight-bold);
      color: t(lm-font-color-primary);
      margin-bottom: t("lm-space-medium");
    }
    .chart {
      width: 80%;
      min-height: 400px;
    }
    .recharts-legend-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    b {
      font-weight: t(lm-font-weight-bold);
    }
    .graph-container {
      border: 1px solid t("lm-background-color-secondary-info");
      padding: 15px 20px 0px 15px;
    }
  }
}
.profile-promote {
  position: relative;
  @include themed() {
    margin-top: t(lm-space-medium);
  }
  .promote-msg {
    @include themed() {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: t("lm-color-promote-blurred");
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: t("lm-font-size-large");
      font-weight: t("lm-font-weight-bold");
      border: 1px solid t("lm-border-main-color");
      > span {
        width: 70%;
        color: t(lm-font-color-primary);
      }
    }
  }
  .blurred {
    svg {
      filter: blur(6px) grayscale(0.5);
      opacity: 0.4;
    }
  }
}
