@import "../../../../styles/_themes.scss";
@import "../../../../styles/_screens.scss";

.conference-selector {
  button {
    flex-basis: 0;
    width: 0;
  }
  .MuiToggleButtonGroup-root {
    @include smScreenAndSmaller() {
      flex-direction: column;
    }
  }
}
