@import "../../../styles/_themes.scss";

.popper {
  z-index: 3000;
  @include themed() {
    .template-selector {
      padding: 6px 0;
      margin-top: 10px;
      box-sizing: border-box;
      max-height: 350px;
      z-index: 2000;
      overflow: auto;
      // padding: 20px 10px;

      background-color: t(app-background-color);
      -webkit-box-shadow: t(shadow);
      -moz-box-shadow: t(shadow);
      box-shadow: t(shadow);

      .proposal-selector-item {
        cursor: pointer;
        padding: t("lm-space-small") t("lm-space-medium");
        font-size: t("lm-font-size-medium");
        > div {
          margin-top: 2px;
          line-height: 1em;
        }
        &.selected,
        &:hover {
          background-color: t(color-outgoing-message-background);
        }
        .conference,
        span.proposal-more {
          color: t("lm-font-color-secondary");
          font-size: t("lm-font-size-small");
          // padding-right: t("lm-space-medium");
        }
        .proposal-location {
          font-size: t(lm-font-size-small);
          color: t(lm-font-color-secondary);
        }
      }
    }
  }
}
.done-input{
  @include themed() {
    span.LMTooltip-children {
      svg.normal{
        color:t('lm-border-color-slot-shadow')
      }
      svg.toggled{
        color: t('lm-color-pop');
      }
    }
  }
}
.small-loader{
  width: 20px!important;
  height: 18px!important;
}