@import "../../styles/_themes.scss";

// This allows better reusability
// eg. in Working hours Dialog
@mixin section-content {
    text-align: left;
    padding: 18px 28px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // @include themed() {
    //     background-color: t(color-white);
    // }
    a {
        @include themed() {
            color: t(color-main-font-color);
        }
    }
    .section:not(:first-child) {
        border-top: 1px solid;
        padding-top: 20px;
        margin-top: 10px;
        @include themed() {
            border-color: t(border-light-color);
        }
    }
    .section {
        .title {
            font-weight: 500;
            font-size: 20px;
            padding-bottom: 10px;
        }
        .subtitle {
            @include themed() {
                color: t(color-font-inactive);
            }
            font-size: 14px;
        }
    }
    .item {
        padding: 20px;
        @include mobileScreenOnly {
            padding: 10px 0;
        }
        width: 100%;
        display: flex;
        box-sizing: border-box;
    }
    .link {
        transition: 150ms;
        cursor: pointer;
        &:hover {
            @include themed() {
                background-color: t(item-hover-background-color);
            }
        }
        svg {
            margin-left: 10px;
        }
        a {
            display: flex;
            align-items: center;
        }
    }
}

.settings-page-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    // margin-top: 40px;
    .settings-page {
        flex: 1;
        display: flex;
        overflow-x: hidden;
        // @include themed() {
        //     background-color: t(app-background-color);
        // }
        > div {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            max-width: $app-max-width;
            margin: 0 auto;
            flex: 1;
        }
        .sections-list {
            @include mobileScreenOnly {
                display: none;
            }
            width: 320px;
            height: 100%;
            flex-shrink: 0;
            @include themed() {
                // border-right: 1px solid t(pop-color);
                //background-color: t(color-light-pop-opacity);
            }
            display: flex;
            flex-direction: column;
            .section {
                &.active {
                    @include themed() {
                        background-color: t(item-hover-background-color);
                    }
                }
                @include themed() {
                    color: t(color-main-font-color);
                }
                text-align: left;
                padding: 14px 20px;
                transition: 150ms;
                cursor: pointer;
                .title {
                    font-weight: 500;
                    font-size: 18px;
                }
                .subtitle {
                    @include themed() {
                        color: t(color-font-inactive);
                    }
                    font-size: 14px;
                }
                &:hover {
                    @include themed() {
                        background-color: t(item-hover-background-color);
                    }
                }
            }
            .version {
                height: 100%;
                display: flex;
                align-items: flex-start;
                padding: 14px 20px;
                font-size: 13px;
                font-weight: 300;
                @include themed() {
                    color: t(lm-font-color-secondary);
                }
            }
        }
        .section-content {
            @include section-content;
            @include lgScreenAndSmaller {
                padding: 18px 20px;
            }
        }
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 66px;
        text-align: left;
        @include lgScreenAndSmaller {
            padding: 10px 20px;
        }

        @include mobileScreenOnly {
            @include themed() {
                border-bottom: 1px solid t(border-light-color);
            }
        }

        > .section-selector {
            display: none;
            @include mobileScreenOnly {
                display: flex;
            }
            align-items: center;
            .selected {
                .title {
                    font-weight: 500;
                    font-size: 18px;
                }
                .subtitle {
                    @include themed() {
                        color: t(color-font-inactive);
                    }
                    font-size: 14px;
                }
            }
            svg {
                margin-left: 10px;
            }
        }
        > .title {
            @include mobileScreenOnly {
                display: none;
            }
            max-width: $app-max-width;
            margin: 0 auto;
            width: 100%;
            text-align: left;
            @include themed() {
                font-size: t(home-title-size);
                line-height: 82px;
                font-weight: t(lm-font-weight-bold);
                color: t(color-licorice);
            }
            @include lgScreenAndSmaller {
                font-weight: 500;
                font-size: 4.5vw;
                line-height: 1em;
            }
        }
    }
}
.section-selector-menu {
    .MuiMenuItem-root {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        white-space: unset;
        .title {
            font-weight: 500;
            font-size: 18px;
        }
        .subtitle {
            @include themed() {
                color: t(color-font-inactive);
            }
            font-size: 14px;
        }
    }
}
