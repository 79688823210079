@import "../../styles/themes";
@import "../../styles/screens";

.bookinglink-error {
  font-style: normal;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  margin: auto;
  .mobile-only {
    display: none;
  }

  span {
    display: block;
    color: #454545;
    &.oups {
      font-weight: 700;
    }
    &.strong {
      font-weight: 700;
      font-size: 26px;
    }
    &.details {
      font-weight: 500;
      font-size: 16px;
      line-height: 38px;
    }
  }
}
.booking-slot-picker-container {
  @include themed() {
    display: flex;
    flex: 1;
    .mobile-only {
      display: none;
    }
    @include lgScreenAndSmaller {
      justify-content: center;
    }
    .picker-container {
      width: 100%;
      max-width: 377px;
      @include lgScreenAndSmaller {
        margin-left: 8px;
        max-width: 500px;
      }
      .tz-autocomplete {
        input {
          font-size: 12px;
          margin-left: 4px;
        }
      }
      .LMTooltip-children {
        margin-left: 4px;
      }
    }
    .slots-container {
      margin-left: 30px;
      @include lgScreenAndSmaller {
        margin-left: 8px;
      }
      .slots-selector {
        h1 {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 18px;
          margin-left: 8px;
          margin-top: 14px;
        }
        .slots-list {
          margin-top: 22px;
          min-width: 170px;
          max-height: 505px;
          overflow-y: auto;
          overflow-x: hidden;
        }
        .slot-picker-item {
          border: 1px solid #dadada;
          border-radius: 4px;
          margin: 0 8px 8px 8px;
          padding: 12px;
          min-width: 170px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.2s;
          font-weight: t(lm-font-weight-normal);
          &:hover {
            border: 1px solid #523fda;
            background: #f5f8ff;
            color: #523fda;
          }
          @include mdScreenAndSmaller() {
            margin: 0 0 12px 0;
          }
          &.confirm {
            background: #523fda;
            border: 2px solid #523fda;
            border-radius: 4px;
            color: #ffffff;
            padding: 4px;
            padding-left: 8px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: t(lm-font-weight-semibold);
            .slot-confirmation {
              color: #3d2daa;
              background: #ffffff;
              border-radius: 4px;
              padding: 8px;
              margin-left: 12px;
              box-shadow: -1px 1px 4px #0000006e;
            }
          }
        }
      }
    }
  }
  @include mdScreenAndSmaller() {
    @include themed() {
      .mobile-only {
        display: inherit;
      }
    }
  }
}

.booking-picker {
  @include themed() {
    @include mdScreenAndSmaller() {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .picker-container {
      position: relative;
      .waiter {
        position: absolute;
        top: 50%;
        left: 50%;
        // pointer-events: none;
        // background: #ffffff9d;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        transform: translateX(-50%);
        background: #ffffff;
        padding: 8px 24px 8px 24px;
        border: 1px solid #dadada;
        box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.15);
        border-radius: 24px;
        color: #3d2daa;
        .MuiCircularProgress-root {
          width: 20px !important;
          height: 20px !important;
        }
      }
      .error-fb {
        position: absolute;
        top: 110px;
        bottom: 20px;
        left: 20px;
        right: 20px;
        background: #ffffff;
        padding: 8px 24px 8px 24px;
        border: 1px solid #dadada;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .error-msg {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #d95f5f;
          margin-bottom: 8px;
        }
        .error-help {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #5b5b5b;
          a {
            text-decoration: underline;
          }
        }
      }
    }
    .tz-autocomplete .tz-label {
      margin-right: 8px !important;
      .title.inline {
        font-size: 12px !important;
      }
    }
    .picker-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 38px;
      margin-top: 0;
    }
    .done-picker {
      border: 1px solid #dadada;
      border-radius: 8px;
      margin: 16px 0;
      @include mdScreenAndSmaller() {
        border: 0;
      }
      .calendar-base {
        position: relative;
        @include mdScreenAndSmaller() {
          padding: 0;
        }
        .year-value {
          font-size: 16px;
        }
        .dates {
          .week {
            margin-bottom: 8px;
            @include lgScreenAndSmaller() {
              margin-bottom: 4px;
            }
            .today {
              &::after {
                width: 7px;
                height: 7px;
                background-color: t(lm-color-pop);
                border-radius: 10px;
                content: "";
                position: absolute;
                z-index: 2;
                bottom: 2px;
              }
              &.selected {
                background-color: green;
                color: white;
                border-radius: 50%;
                &::after {
                  background-color: white;
                }
              }
            }
          }
        }
      }
      .donepicker-date {
        border: none;
        border: 2px solid transparent;
        transition: none;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: t(lm-font-color-primary);
        font-size: 14px;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        font-weight: t(lm-font-weight-semibold);
        &.has-events {
          background: #f5f8ff;
          font-weight: 700;
          color: #7060df;
          &.selected {
            background: #523fda;
            color: white;
          }
        }
        &.unselectable {
          color: t(lm-font-color-secondary);
          font-weight: t(lm-font-weight-light);
        }

        &::before {
          border: none;
        }
        &::after {
          border: none;
        }
        &.hovered {
          svg {
            display: none;
          }
        }
        &.today.selected {
          background: #523fda !important;
        }
        &:hover {
          box-sizing: border-box;
          border: 2px solid t(lm-color-pop);
        }
        &.next-month {
          opacity: 0;
        }
        &.dayname {
          pointer-events: none;
        }
        span.day {
          display: none;
        }
        &:not(.has-events) {
          pointer-events: none;
        }
      }
      &.loading {
        pointer-events: none;
        opacity: 0.5;
      }
      &.invalid {
        .donepicker-date {
          opacity: 0.5;
        }
      }
    }
    .tz-autocomplete {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      justify-content: space-between;
      .MuiOutlinedInput-root {
        padding: 0;
      }
      .tz-label {
        margin-right: 25px;
        .title.inline {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #5b5b5b;
          svg {
            color: #523fda;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
.user-informations {
  @include themed() {
    color: t(lm-font-color-primary);
  }

  max-width: 480px;
  @include mdScreenAndSmaller() {
    max-width: unset;
  }
  & > * {
    margin-bottom: 18px;
  }
  .back.desktop-only {
    display: flex;
    align-items: center;
  }

  button.grey {
    border: 1.5px solid #dadada;
    border-radius: 4px;
    color: #7060df;
  }
  .button-back-label {
    font-size: 20px;
    line-height: 20px;
    color: #523fda;
    margin-left: 16px;
  }
  .MuiTextField-root.done-input .MuiInputLabel-root {
    background-color: white;
    padding-right: 2px;
  }
  .lm-recaptcha {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 304px;
    height: 78px;
  }
  h1.link-form-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 38px;
    margin-top: 0;
  }
  .confirmed-slot {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    color: #5b5b5b;
    text-align: left;
    strong {
      font-weight: 600;
      font-size: 16px;
      @include themed() {
        color: t(lm-font-color-primary);
      }
    }
    line-height: 17px;
    padding: 0px;
    .selected-date {
      margin-top: 2px;
      padding-left: 8px;
    }
  }
  .confirmation .confirmed-slot {
    justify-content: center;
  }
}
@include mdScreenAndSmaller() {
  .mobile-only {
    display: inherit;
  }
  .desktop-only {
    display: none !important;
  }
  .booking-slot-picker-container {
    @include themed() {
      .slots-container.mobile-slots-hidden {
        display: none;
      }
      .slots-container.mobile-slots-showing {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: white;
        z-index: 3;
        margin: 0;
        padding: 24px;
        .slots-list {
          max-height: unset;
          .slot-picker-item.confirm {
            justify-content: center;
            & > * {
              flex: 1;
              flex-shrink: 0;
              display: flex;
              justify-content: center;
            }
          }
        }
        h1 {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 20px;
          line-height: 38px;
          margin-left: 0;
          margin-top: 0;
          button.grey {
            border: 1.5px solid #dadada;
            border-radius: 4px;
            color: #7060df;
            margin-right: 16px;
          }
        }
        h2 {
          font-size: 20px;
        }
      }
    }
  }
}
