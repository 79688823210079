@import "../components/styles/_themes.scss";
header.app-header {
  position: relative;
 
  
}

.dashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  header > div {
    //max-width: $app-max-width;
    margin: 0 auto;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 45px 10px 45px;
    @include themed(){
      border-bottom: 1px solid  t('lm-border-main-color');
    }
    @include lgScreenAndSmaller {
      padding: 10px 20px 10px 20px;
    }

    & > div > a > svg {
      height: 40px;
      width: 170px;
      margin-right: 12px;
      @include mobileScreenOnly {
        width: 120px;
        height: 28px;
        margin-right: 0;
      }
    }
    & > a.inactive {
      pointer-events: none;
    }
  }
  section {
    flex: 1 1;
    display: flex;
    .full-height {
      height: 100%;
    }
    .page-content {
      flex: 1 1;
      position: relative;
      width: 100%;
      // & > div {
      //   position: absolute;
      //   // @include mobileScreenOnly {
      //   //   position: relative;
      //   // }
      //   top: 0;
      //   bottom: 0;
      //   left: 0;
      //   right: 0;
      //   //
      //   display: flex;
      //   // position: absolute;
      //   & > * {
      //     flex: 1 1;
      //   }
      // }
      // &.mobile {
      //   position: relative;
      // }
    }
  }
}
