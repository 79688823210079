@import "../../../styles/themes";

.slots-dlg-title {
  @include themed() {
    .double {
      margin-top: -0.6em;
      margin-bottom: -0.6em;
      > div {
        &:first-child {
          font-weight: t(lm-font-weight-semibold);
          font-size: t(lm-font-size-xlarge);
          margin-bottom: t(lm-space-small);
        }
        &:nth-child(2) {
          font-weight: t(lm-font-weight-normal);
          color: t(lm-font-color-secondary);
          font-size: t(lm-font-size-medium);
        }
      }
    }
  }
}
.slots-dlg-actions {
  @include themed() {
    &.MuiDialogActions-root {
      button {
        margin-left: t(lm-space-small);
      }
      .warning {
        margin-right: t(lm-space-small);
        font-weight: t(lm-font-weight-semibold);
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
          content: url(../../../../assets/not_available.svg);
          margin-right: t(lm-space-xs);
          position: relative;
          top: 2px;
        }
      }
    }
  }
}

.MuiDialog-paperFullWidth {
  .slots-proposal-day {
    min-height: 400px;
  }
}
.slots-proposal-day {
  .slots-proposal-day-slots {
    @include themed() {
      margin-top: t(lm-space-small);
      margin-bottom: t(lm-space-medium);
      .slots-proposal-day-date {
        margin-bottom: t(lm-space-small);
        font-weight: t(lm-font-weight-semibold);
        .timezone {
          color: t(lm-font-color-secondary);
          font-size: t(lm-font-size-small);
        }
      }
    }

    .proposal-day-date {
      margin-bottom: 4px;
      margin-top: 10px;
      // align-self: flex-end;
      font-size: 13px;
      // height: 16px;
    }
    .day-slots {
      display: flex;
      flex-wrap: wrap;

      .proposal-items {
        // flex: 0 0 23%;
        // font-size: 14px;
        // padding: 8px 8px;
        // min-width: 160px;
        // flex: 0 0 23%;
        width: 200px;
        height: 60px;
        @include smScreenAndSmaller() {
          flex: 0 0 100%;
        }
        .MuiCheckbox-root,
        .MuiRadio-root {
          padding: 0;
          svg {
            @include themed() {
              fill: t(lm-font-color-secondary);
            }
          }
        }
        &:not(:last-child) {
          @include themed() {
            margin-right: t(lm-space-small);
            @include mobileScreenOnly {
              margin-right: 0;
            }
          }
        }
        .Mui-disabled {
          svg {
            fill: rgba(0, 0, 0, 0.38) !important;
          }
        }
        .slot {
          $stroke: 2px;
          margin: 0;
          position: relative;
          // transition: 0.1s;
          // &:hover {
          //   background-color: rgba(0, 0, 0, 0.03);
          // }

          @include themed() {
            margin: 0;
            width: 100%;
            border-radius: t(lm-border-radius-small);
            box-sizing: border-box;
            border: $stroke solid #00000027;
            margin-bottom: t(lm-space-small);
            user-select: none;
            font-size: t(lm-font-size-medium);
            @include mobileScreenOnly {
              font-size: t(lm-font-size-normal);
            }
          }
          > .LMTooltip-children {
            width: 100%;
          }
          .MuiFormControlLabel-root {
            box-sizing: border-box;
            width: 100%;
            justify-content: center;
          }
          .forced-slot {
            display: flex;
            align-items: center;
            position: absolute;

            @include themed() {
              color: t(lm-color-forced-slot);
              right: t(lm-space-accent);
              top: t(lm-space-accent);
              background-color: t(lm-color-background);
            }
          }
          box-sizing: border-box;
          display: flex;
          justify-content: center;

          .MuiRadio-root.invisible {
            display: none;
            // & + span {
            //   padding-left: 10px;
            // }
          }
          .MuiFormControlLabel-root {
            @include themed() {
              padding: t(lm-space-small);
            }
          }
          .MuiFormControlLabel-label {
            flex: 1;
            .slot-label {
              display: flex;
              align-items: center;
              & > :first-child {
                flex: 1;
              }
              @include themed() {
                margin-left: t(lm-space-xs);
                font-weight: t(lm-font-weight-semibold);
              }
              .LMTooltip-children {
                flex: 1;
                justify-content: flex-end;
                &:hover {
                  .inner-mark {
                    // background-color: #0000000a;
                    @include themed() {
                      // border: 1px solid t(lm-color-pop);
                      // background-color: #0000000a;
                      // color: white;
                    }
                  }
                }
                .mark {
                  //   position: absolute;
                  //   top: -10px;
                  //   right: -10px;
                  margin-left: 5px;
                  text-align: center;
                  display: flex;
                  @include themed() {
                    color: t(lm-color-pop);
                    font-weight: t(lm-font-weight-semibold);
                  }
                  font-size: 14px;
                  .inner-mark {
                    // width: 30px;
                    // height: 30px;
                    // @include themed(){
                    //   background-color: t('color-robineggblue');
                    // }
                    // border-radius: 50%;
                    // font-family: Arial, Helvetica, sans-serif;
                    border: 1px solid transparent;
                    padding: 1px 3px 1px 0;
                    border-radius: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                      width: 18px;
                      height: 18px;
                    }
                  }
                }
              }
            }
          }
          .MuiFormControlLabel-root {
            margin: 0;
          }
          &:hover {
            &:not(.disabled) {
              @include themed() {
                border: $stroke solid t(lm-font-color-slot);
                cursor: pointer;
              }
            }
          }
          &.checked {
            @include themed() {
              border: $stroke solid t(lm-font-color-slot);
              color: t(lm-font-color-slot);
              .label {
                font-weight: t(lm-font-weight-semibold);
              }
              .MuiCheckbox-root,
              .MuiRadio-root {
                svg {
                  fill: t(lm-color-pop);
                }
              }
            }
          }

          .answers {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
            margin-left: 10px;
            font-size: 12px;
            height: 1em;

            .nb-ok {
              border-radius: 50px;
              border: 1px solid #00943b;
              background-color: white;
              display: flex;
              align-items: center;
              padding: 2px 10px;
              &.high {
                border: 2px solid #00943b;
                // background-color: #00943b22;
              }
              &.medium {
                border: 2px solid orange;
              }
              &.low {
                border: 2px solid red;
              }
              svg {
                fill: #00943b;
                margin-right: 3px;
                margin-left: -3px;
                width: 16px;
                height: 16px;
              }
            }
          }
          &.disabled {
            .answers {
              // .Avatar {
              //   @include themed() {
              //     color: t(lm-font-color-secondary);
              //     background-color: rgba(0, 0, 0, 0.2) !important;
              //   }
              // }
            }
          }
          .date {
            text-transform: uppercase;
            font-weight: 500;
          }

          &.not-remaining {
            @include themed() {
              opacity: 0.8;
              border: $stroke solid #00000008;
              .nb-ok {
                border-width: 1px !important;
              }
              .slot-label {
                font-weight: t(lm-font-weight-normal);
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
  .message {
    padding: 20px 0;
    // /* line-height: 50px; */
    // width: 75%;
    // margin: auto;
    display: block;
  }
  .heatmap-container {
    max-height: 62vh;

    .shadowing {
      .eventTooltippopup {
        // display: none;
      }
      .shadow-text {
        display: none;
      }
    }
  }
}
.dialog-with-close-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  h2 {
    display: inline-block;
    flex-grow: 1;
  }
}
.like-p {
  padding: 10px 10px;
  .notInRemaining {
    margin-bottom: 0.5em;
    @include themed() {
      font-weight: t(lm-font-weight-semibold);
    }
  }
}
.answered {
  display: flex;
  align-items: center;
  &.strong {
    @include themed() {
      font-weight: t(lm-font-weight-semibold);
    }
  }
  svg {
    &.ok {
      fill: #00943b;
    }
    &.forced {
      @include themed() {
        fill: t(lm-color-forced-slot);
      }
    }
    &.ko {
      fill: #d95f5f;
    }
    margin-right: 3px;
    margin-left: -4px;
    width: 16px;
    height: 16px;

    &.waiting {
      width: 13px;
      height: 13px;
      padding-left: 2px;
      padding-bottom: 2px;
    }
  }
}
.slots-fb-loading {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
