@import "./styles/_constants.scss";
@import "./styles/_themes.scss";

.promote-extension {
  width: 100%;
  height: 100vh;
  background-color: #f9f9fa;
  color: #343434;
  display: flex;
  // go on top of Userpilot elements
  z-index: 92147483638;
  position: relative;
  a,
  a:visited {
    color: #343434;
  }
  .content {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 20px 0;
    @include mobileScreenOnly() {
      position: unset;
      transform: none;
      width: 100%;
      margin: 0 20px;
    }
    & > .title {
      margin: 0 26px 12px 25px;
      font-size: 30px;
      line-height: 1.5;
      @include mobileScreenOnly() {
        font-size: 18px;
        padding-top: 20px;
      }
    }
    & > .message {
      margin: 12px 70px 43px 70px;
      font-size: 20px;
      @include mobileScreenOnly() {
        margin: 2px 2px 10px 2px;
        font-size: 16px;
      }
    }
    & > .promote {
      max-width: 500px;
      margin: auto;
      padding: 35px 40px;
      border-radius: 5px;
      box-shadow: 0 8px 14px 0 rgba(224, 229, 238, 0.5);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      > .bigtitle {
        font-size: 30px;
        margin-bottom: 1.4em;
        max-width: 400px;
        @include mobileScreenOnly() {
          font-size: 30px;
          margin-bottom: 0.8em;
          font-size: 24px;
        }
      }
      > .section {
        &:not(:last-child) {
          margin-bottom: 3em;
          @include mobileScreenOnly() {
            margin-bottom: 2em;
          }
        }
        display: flex;
        flex-direction: column;
        .title {
          max-width: 460px;
          font-weight: 500;
          margin-bottom: 26px;
          @include mobileScreenOnly() {
            margin-bottom: 10px;
          }
        }
        .ctas {
          display: flex;
          align-items: center;
          margin: 0 auto;
          margin-top: -10px;
          @include mobileScreenOnly() {
            flex-direction: column;
          }
        }
        .cta-install {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          border-radius: 5px;
          span {
            max-width: 130px;
            display: block;
            text-align: left;
            margin-left: 10px;
            font-size: 14px;
            @include mobileScreenOnly() {
              max-width: unset;
            }
          }
          & > svg {
            width: 44px;
            height: 44px;
            flex-shrink: 0;
            @include mobileScreenOnly() {
              width: 30px;
              height: 30px;
            }
          }
          text-decoration: underline;
          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }
        }
        .cta-appstore {
          align-self: center;
          width: 126px;
          height: 42px;
        }
      }
    }
    & > .skip {
      display: flex;
      margin: 0 auto;
      margin-top: 28px;
      align-items: center;
      justify-content: flex-end;
      max-width: 540px;
      font-weight: 500;
      button {
        font-size: 16px;
        margin: 0;
        padding: 0;
        border: none;
        background: inherit;
        display: flex;
        align-items: center;
        border-radius: 50px;
        border: 2px solid transparent;
        padding: 8px 16px;
        &:hover {
          border: 2px solid #7060df;
          cursor: pointer;
        }
        svg {
          &:nth-child(2) {
            margin-left: -18px;
            margin-right: -8px;
          }
        }
      }
    }
    & > .welcome {
      max-width: 800px;
      padding: 80px 20px;
      .welcome-title {
        & > svg {
          min-width: 256px;
          min-height: 256px;
          margin-bottom: 20px;
        }
        .title {
          margin: 0 26px 12px 25px;
          font-size: 24px;
          line-height: 1.5;
          font-weight: 500;
          @include mobileScreenOnly() {
            font-size: 18px;
            padding-top: 20px;
          }
        }
      }
      .welcome-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        flex-wrap: wrap;
        margin-top: -10px;
        button {
          text-transform: none;
          font-weight: 500;
          margin: 0 5px;
          margin-top: 10px;
          svg {
            margin-left: 5px;
            padding-left: 5px;
            margin-right: 0px;
            padding-right: 0px;
          }
          &:nth-child(2) {
            border-color: transparent;
          }
        }
      }
      .welcome-infos {
        margin-top: 20px;
        .title {
          margin-bottom: 20px;
        }
        .users {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          gap: 20px;
          svg {
            filter: grayscale(100%);
            opacity: 0.8;
          }
        }
      }
    }
  }
}
.slide-in-bottom {
  animation: slide-in-bottom 0.3s ease-in both; //cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-7-10 15:4:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
$vava: 150px;
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY($vava);
    transform: translateY($vava);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
