@import "../../../styles/themes";
@import "../../../styles/_constants.scss";

.duration-padding {
  margin-bottom: 26px !important;
  label {
    font-size: 0.65rem;
    text-align: left;
    margin-left: 10px;
    position: absolute;
    top: -7px;
    z-index: 99;
    background-color: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(248, 248, 249, 1) 50%,
      rgba(251, 251, 252, 0) 90%
    );
    padding: 1px 1px 1px 4px;
    color: $color-robineggblue;
  }
  @include themed() {
    div.done-input {
      button:first-child {
        border-top-left-radius: t(lm-border-radius-input);
        border-bottom-left-radius: t(lm-border-radius-input);
      }
      button:last-child {
        border-top-right-radius: t(lm-border-radius-input);
        border-bottom-right-radius: t(lm-border-radius-input);
      }
    }
  }
  &.duration-cmp {
    .MuiToggleButtonGroup-root .MuiToggleButton-root {
      // height: 70px;
    }
    div.done-input > button {
      justify-content: start;
      &.MuiToggleButton-sizeLarge {
        padding: 10px;
      }
      .MuiToggleButton-label > .MuiInputBase-root > input[name="customduration"] {
        text-align: right; // test
        margin-right: 3px;
        &::placeholder {
          @include themed() {
            color: t(lm-font-color-primary);
            font-weight: t(lm-font-weight-semibold);
          }
        }
      }
      justify-content: center; // test
      @include mobileScreenOnly {
        justify-content: center;
        .MuiToggleButton-label > .MuiInputBase-root > input[name="customduration"] {
          text-align: center;
        }
        &.MuiToggleButton-sizeLarge {
          padding: 10px;
          // height: 60px;
        }
      }
      .MuiToggleButton-label {
        width: 100%;
        text-align: left;
        text-align: center; // test
        @include mobileScreenOnly {
          text-align: center;
        }
        // color: $color-robineggblue;
        // input {
        //   color: $color-robineggblue;
        // }
        text-transform: none;
        font-size: 12px;
        @include mobileScreenOnly {
          font-size: 12px;
        }
        line-height: 20px;
        font-weight: normal;
        div.strong {
          @include mobileScreenOnly {
            font-size: 12px;
          }
          font-size: 16px;
          color: #1b1b1b;
          font-weight: 500;
        }
      }
      &.Mui-selected {
        div.strong,
        input {
          color: $color-robineggblue;
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    label {
      @include themed() {
        color: t(lm-font-color-secondary);
      }
    }
    div.done-input > :not(button.Mui-selected) {
      @include themed() {
        opacity: 0.5;
        background-color: t(lm-background-item-hover);
        .strong, .MuiToggleButton-label {
          color: t(lm-font-color-secondary);
          font-weight: t(lm-font-weight-normal);
        }
      }
    }
  }
}
