@import "../../../../styles/themes";
@import "../../../../styles/screens";

.calendar-selector-202212.done-calendar-selector {
  text-align: left;
  @include themed() {
    label {
      color: #5B5B5B!important;
      margin-left: 0;
      font-size: 0.85rem !important;
      display: flex;
      align-items: center;
      > span {
        display: inline-block;
      }
      pointer-events: all;
      background-color: transparent!important;
      svg {
        width: 22px;
        height: 22px;
        margin-left: 4px;
      }
      transform: translate(0, -6px) scale(0.75);
    }
    .selected-calendar {
      display: flex;
      align-items: center;
    }
    &.calendar-selector-simple {
      width: auto;
    }
    .metadata {
      color: #5B5B5B;
      font-size: t(lm-font-size-xsmall);
      justify-content: space-between;
      display: flex;
      align-items: flex-start;
      .account {
        margin-right: 2em;
      }
    }
    .selector {
      display: flex;
      margin: 14px 0px 3px 0px;
      padding: 4px 10px;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      font-size: t(lm-font-size-normal);
      border-radius: t(lm-border-radius-normal);
      border: 1px solid #DADADA;
      &:hover {
        @include themed() {
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}