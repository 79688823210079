@import "../../../styles/themes";

.who-are-you-dlg {
  padding: 42px;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  @include mobileScreenOnly {
    padding: 20px;
  }
  .textual {
    text-align: left;
    position: sticky;
    h4 {
      margin: 0;
      padding: 0;
      font-size: 36px;
    }
    p {
      margin: 0;
      padding: 0;
      margin-bottom: 36px;
      @include themed() {
        margin-top: t(lm-space-xs);
        line-height: 1.4em;
      }
    }
  }
  .items {
    overflow-y: auto;
    height: 100%;
    padding: 0;
    .who-are-you-item {
      .done-button {
        &.small {
          padding: 4px 24px;
        }
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      @include mobileScreenOnly {
        flex-direction: column;
      }

      .invitee-infos {
        @include mobileScreenOnly {
          text-align: center;
        }
        flex-grow: 1;
        // padding: 0px 5px;
        & div:first-child {
          font-weight: 500;
        }
        & div:nth-child(2) {
          @include themed() {
            color: t(color-taupegrey);
            font-size: t(secondary-font-size);
          }
        }
      }
      .already-vote {
        text-align: center;
        cursor: pointer;
        svg {
          @include themed() {
            color: t(color-mountainmeedow);
            font-size: 2rem;
          }
        }
      }
    }
  }
  .expandContainer {
    justify-content: space-between;
    @include themed() {
      margin-top: t(lm-space-medium);
      padding: t(lm-space-xs);
      color: t(lm-font-color-secondary);
      cursor: pointer;
    }
    display: flex;
    align-items: center;
    svg {
      transform: rotate(0deg);
      transition: transform 0.3s;
      margin-right: 5px;
    }
    &.expanded {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .expandableContainer {
    @include themed() {
    padding-top: t(lm-space-small);
    }
  }
  a {
    display: block;
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    margin-top: 36px;
    @include themed() {
      color: t(color-robineggblue);
    }
  }
}
